import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceWithId } from "../../../constants/types";
import { fetchServices, updateServices } from "./thunk";

interface ServicesState {
  services: ServiceWithId[];
  loading: boolean;
  error: string | null;
}

const initialState: ServicesState = {
  services: [],
  loading: false,
  error: null,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchServices.fulfilled,
        (state, action: PayloadAction<ServiceWithId[]>) => {
          state.loading = false;
          state.services = action.payload;
        },
      )
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch services";
        console.error("servicesSlice - fetchServices - Rejected:", state.error);
      });

    builder
      .addCase(updateServices.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateServices.fulfilled,
        (state, action: PayloadAction<ServiceWithId[]>) => {
          state.loading = false;
          state.services = action.payload;
        },
      )
      .addCase(updateServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update services";
        console.error(
          "servicesSlice - updateServices - Rejected:",
          state.error,
        );
      });
  },
});

export default servicesSlice.reducer;
