import styled from "styled-components";
import colors from "../../constants/colors";

export const Section = styled("section")({
  backgroundColor: colors.WHITE,
  borderRadius: "12px",
  padding: "1.5rem",
  marginBottom: "1.5rem",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
  width: "100%",
});

export const SectionTitle = styled("h2")({
  color: colors.BLACK,
  fontSize: "1.5rem",
  fontWeight: 600,
  marginBottom: "1.5rem",
  display: "flex",
  alignItems: "center",
  gap: "0.75rem",
});

export const SectionSubtitle = styled("h3")({
  color: colors.GREY_7,
  fontSize: "0.9rem",
  fontWeight: 400,
  marginBottom: "1rem",
  lineHeight: "1.4",
});

export const MetricContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: "1rem",
  marginBottom: "1.5rem",
});

export const MetricCard = styled("div")({
  backgroundColor: colors.WHITE,
  padding: "1.5rem",
  borderRadius: "12px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  border: `1px solid ${colors.PURPLE_LIGHTER}`,
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",

  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 12px rgba(116, 105, 182, 0.15)",
  },
});

export const MetricLabel = styled("div")({
  color: colors.GREY_7,
  fontSize: "0.9rem",
  fontWeight: 500,
});

export const MetricValue = styled("div")({
  fontSize: "24px",
  fontWeight: "bold",
  margin: "8px 0",
  color: colors.BLACK,
});

export const ListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
});

export const ListItem = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.75rem",
  backgroundColor: colors.GREY_1,
  borderRadius: "6px",
  fontSize: "1rem",
});

export const ListItemName = styled("span")({
  color: colors.BLACK,
  fontWeight: 500,
  flex: 1,
});

export const ListItemValue = styled("span")({
  color: colors.PURPLE,
  fontWeight: 600,
});

// Weekly and Daily Trends Styles
export const WeeklyTrendContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(60px, 1fr))",
  gap: "1rem",
  alignItems: "flex-end",
  padding: "1rem 0",
  textAlign: "center",
});

export const WeeklyTrendBar = styled("div")<{ height: number }>(
  ({ height }) => ({
    backgroundColor: colors.PURPLE,
    height: `${height}%`,
    minHeight: "4px",
    maxHeight: "150px",
    width: "100%",
    borderRadius: "4px 4px 0 0",
    transition: "height 0.3s ease",
  }),
);

export const WeeklyTrendLabel = styled("div")({
  color: colors.GREY_7,
  fontSize: "0.8rem",
  marginTop: "0.5rem",
  fontWeight: 500,
});

export const TrendValue = styled("div")({
  color: colors.PURPLE,
  fontSize: "0.9rem",
  fontWeight: 600,
  marginTop: "0.25rem",
});

// Cancellation Rate Styles
export const CancellationRateBar = styled("div")<{ width: number }>(
  ({ width }) => ({
    backgroundColor: colors.PURPLE_LIGHTER,
    height: "8px",
    width: `${width}%`,
    borderRadius: "4px",
    marginBottom: "0.25rem",
  }),
);

export const CancellationRateLabel = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  fontSize: "0.8rem",
  color: colors.GREY_7,
});

export const CancellationRateValue = styled("span")({
  color: colors.BLACK,
  fontWeight: "bold",
  marginRight: "8px",
});

// Lead Time Styles
export const LeadTimeContainer = styled("div")({
  marginTop: "1rem",
});

export const LeadTimeBar = styled("div")<{ width: number }>(({ width }) => ({
  backgroundColor: colors.PURPLE_LIGHTER,
  height: "8px",
  width: `${width}%`,
  borderRadius: "4px",
  marginBottom: "0.25rem",
}));

export const LeadTimeLabel = styled("div")({
  color: colors.BLACK,
  fontSize: "0.9rem",
  fontWeight: 500,
});

export const LeadTimeValue = styled("div")({
  color: colors.GREY_7,
  fontSize: "0.8rem",
});

// Chart Styles
export const ChartContainer = styled("div")({
  minHeight: "400px",
  height: "auto",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  marginBottom: "2rem",
});

// Comparison Styles
export const ComparisonContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
  gap: "1rem",
  marginBottom: "1rem",
});

export const ComparisonCard = styled("div")({
  backgroundColor: colors.WHITE,
  padding: "1.5rem",
  borderRadius: "12px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  border: `1px solid ${colors.PURPLE_LIGHTER}`,
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",

  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 12px rgba(116, 105, 182, 0.15)",
  },
});

export const ComparisonLabel = styled("div")({
  color: colors.GREY_7,
  fontSize: "1.2rem",
  fontWeight: 500,
  letterSpacing: "0.2px",
});

export const ComparisonValue = styled("div")({
  color: colors.BLACK,
  fontSize: "2.25rem",
  fontWeight: 700,
  letterSpacing: "-0.5px",
});

export const ComparisonDiff = styled("div")<{ $positive: boolean }>(
  ({ $positive }) => ({
    color: $positive ? colors.GREEN : colors.RED,
    fontSize: "0.9rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    padding: "4px 8px",
    backgroundColor: $positive
      ? "rgba(116, 105, 182, 0.1)"
      : "rgba(255, 59, 48, 0.1)",
    borderRadius: "4px",
    alignSelf: "flex-start",

    "& span": {
      color: colors.GREY_7,
      fontSize: "0.8rem",
      fontWeight: 400,
      marginRight: "0.25rem",
    },
  }),
);
