import React, { ReactNode, useMemo } from "react";
import { getBreakLabel, breakOptions, computeCombinedDays } from "../../utils";
import { AvailabilityType, CombinedDay, Slot } from "../../constants/types";
import {
  Container,
  InfoText,
  Subtitle,
  Title,
  FormWrapper,
} from "../../screens/styles";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLSelect from "../KLSelect";
import KLButton from "../KLButton";
import KLDayAvailability from "../KLDayAvailability";

export interface KLAvailabilityFormProps {
  title?: string;
  subtitle?: ReactNode;
  infoText?: string;
  creatingSlots?: boolean;
  updatingSlots?: boolean;
  availabilityType?: AvailabilityType | null;
  breakMinutesBetweenSlots: string | null;
  setBreakMinutesBetweenSlots: (value: string | null) => void;
  slotsByDay: { [compositeKey: string]: Slot[] };
  futureDateDayMap: { [day: string]: string };
  currentDateDayMap?: { [day: string]: string };
  getSlotOptions: (
    dayLabel: string,
    date: string,
    slotIndex: number,
  ) => {
    startOptions: { label: string; value: string }[];
    endOptions: { label: string; value: string }[];
  };
  handleDayClick: (dayLabel: string, date: string) => void;
  isDayExpanded: (dayLabel: string, date: string) => boolean;
  handleAddSlot: (dayLabel: string, date: string) => void;
  handleDeleteSlot: (dayLabel: string, date: string, index: number) => void;
  handleUpdateSlot: (
    dayLabel: string,
    date: string,
    index: number,
    updatedSlot: Slot,
  ) => void;
  isFormValid: boolean;
  submitTitle: string;
  submitType?: "submit" | "button" | "reset";
  onSubmit: () => void;
  hideBreakMinutes?: boolean;
  term?: string;
  pluralTerm?: string;
}

const KLAvailabilityForm: React.FC<KLAvailabilityFormProps> = ({
  title,
  subtitle,
  infoText,
  creatingSlots,
  updatingSlots,
  availabilityType,
  breakMinutesBetweenSlots,
  setBreakMinutesBetweenSlots,
  slotsByDay,
  futureDateDayMap,
  currentDateDayMap,
  getSlotOptions,
  handleDayClick,
  isDayExpanded,
  handleAddSlot,
  handleDeleteSlot,
  handleUpdateSlot,
  isFormValid,
  submitTitle,
  submitType,
  onSubmit,
  hideBreakMinutes,
  term,
  pluralTerm,
}) => {
  const combinedDays: CombinedDay[] = useMemo(() => {
    return computeCombinedDays(
      futureDateDayMap,
      currentDateDayMap,
      availabilityType,
    );
  }, [futureDateDayMap, currentDateDayMap, availabilityType]);

  const getCompositeKey = (dayLabel: string, date: string) => {
    return availabilityType === AvailabilityType.WeeklyRecurring
      ? dayLabel
      : `${dayLabel}|${date}`;
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {infoText && <InfoText>{infoText}</InfoText>}

      {combinedDays.map((day, idx) => {
        // For recurring, date is empty.
        const key = getCompositeKey(day.label, day.date);
        return (
          <KLDayAvailability
            key={`${day.label}-${day.date}-${idx}`}
            dayLabel={day.label}
            date={day.date}
            slots={slotsByDay[key] || []}
            onAddSlot={() => handleAddSlot(day.label, day.date)}
            onDeleteSlot={(index) =>
              handleDeleteSlot(day.label, day.date, index)
            }
            onUpdateSlot={(index, updatedSlot) =>
              handleUpdateSlot(day.label, day.date, index, updatedSlot)
            }
            isExpanded={isDayExpanded(day.label, day.date)}
            onToggle={() => handleDayClick(day.label, day.date)}
            highlight={day.highlightMissing}
            getSlotOptions={(dayLabel, slotIndex) =>
              getSlotOptions(day.label, day.date, slotIndex)
            }
          />
        );
      })}

      {!hideBreakMinutes && (
        <>
          <Title>כמה זמן הפסקה בין {pluralTerm}?</Title>
          <Subtitle>
            כל אחד צריך גם קצת להירגע. כמה זמן לפנות בין {term} ל{term}?
          </Subtitle>

          <KLCollapsibleSection
            title={`הפסקה בין ${pluralTerm}`}
            subtitle={
              breakMinutesBetweenSlots
                ? getBreakLabel(breakMinutesBetweenSlots)
                : undefined
            }
            isExpanded
            onToggle={() => {}}
          >
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <KLSelect
                value={breakMinutesBetweenSlots || ""}
                onChange={(e) => setBreakMinutesBetweenSlots(e.target.value)}
                placeholder={`הפסקה בין ${pluralTerm}`}
                required
                options={breakOptions.map((option) => ({
                  label: option.label,
                  value: option.value.toString(),
                }))}
                disabled={false}
              />
            </div>
          </KLCollapsibleSection>
        </>
      )}

      <FormWrapper>
        <KLButton
          title={submitTitle}
          type={submitType || "button"}
          onClick={onSubmit}
          disabled={!isFormValid || creatingSlots || updatingSlots}
          loading={creatingSlots || updatingSlots}
          noMarginTop
        />
      </FormWrapper>
    </Container>
  );
};

export default KLAvailabilityForm;
