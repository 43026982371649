import styled from "styled-components";
import colors from "../../constants/colors";
import MSearchIcon from "@mui/icons-material/Search";

export const SearchWrapper = styled("div")({
  marginBlock: "1rem",
});

export const SearchContainer = styled("div")({
  position: "relative",
  width: "100%",
});

export const SearchInput = styled("input")({
  width: "100%",
  padding: "0.75rem",
  paddingLeft: "2rem",
  border: `1px solid ${colors.GREY_3}`,
  borderRadius: "8px",
  fontSize: "1rem",
  boxSizing: "border-box",
});

export const StyledSearchIcon = styled(MSearchIcon)({
  position: "absolute",
  top: "50%",
  left: "0.5rem",
  transform: "translateY(-50%)",
  color: colors.GREY_7,
  pointerEvents: "none",
  width: "16px",
  height: "16px",
});
