import { createAsyncThunk } from "@reduxjs/toolkit";
import { insightService } from "../../../services/insightService";
import {
  BusinessInsights,
  AvailableInsightsPeriod,
} from "../../../constants/types";

export const fetchMonthlyInsights = createAsyncThunk<
  BusinessInsights,
  [string, number, number],
  { rejectValue: string }
>(
  "insights/fetchMonthlyInsights",
  async ([businessId, year, month], { rejectWithValue }) => {
    try {
      const insights = await insightService.fetchMonthlyInsights(
        businessId,
        year,
        month,
      );
      return insights;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchAvailableInsightsPeriods = createAsyncThunk<
  AvailableInsightsPeriod[],
  string,
  { rejectValue: string }
>(
  "insights/fetchAvailableInsightsPeriods",
  async (businessId, { rejectWithValue }) => {
    try {
      const periods =
        await insightService.fetchAvailableInsightsPeriods(businessId);
      return periods;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
