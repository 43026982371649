import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { StorageKeys } from "../../constants/localStorage";
import { AllRouteSteps, FlowType } from "../../constants/routes";
import useLocalStorage from "../../hooks/useLocalStorage";

interface StepContextProps {
  completedSteps: Record<string, Set<AllRouteSteps>>;
  markStepAsCompleted: (sequence: FlowType, step: AllRouteSteps) => void;
  isStepCompleted: (sequence: FlowType, step: AllRouteSteps) => boolean;
  resetSteps: (sequence: FlowType) => void;
  isRedirecting: boolean;
  onRedirect: (value: boolean) => void;
}

const StepContext = createContext<StepContextProps | undefined>(undefined);

export const useStep = (): StepContextProps => {
  const context = useContext(StepContext);
  if (!context) {
    throw new Error("useStep must be used within a StepProvider");
  }
  return context;
};

interface StepProviderProps {
  children: ReactNode;
}

export const StepProvider: React.FC<StepProviderProps> = ({ children }) => {
  const { storedValue: customerStoredSteps, setValue: setCustomerStoredSteps } =
    useLocalStorage<string[]>(StorageKeys.CustomerCompletedSteps, []);
  const { storedValue: businessStoredSteps, setValue: setBusinessStoredSteps } =
    useLocalStorage<string[]>(StorageKeys.BusinessCompletedSteps, []);
  const {
    storedValue: availabilityStoredSteps,
    setValue: setAvailabilityStoredSteps,
  } = useLocalStorage<string[]>(StorageKeys.AvailabilityCompletedSteps, []);

  const completedSteps: Record<FlowType, Set<AllRouteSteps>> = {
    [FlowType.Customer]: new Set<AllRouteSteps>(
      customerStoredSteps as AllRouteSteps[],
    ),
    [FlowType.Business]: new Set<AllRouteSteps>(
      businessStoredSteps as AllRouteSteps[],
    ),
    [FlowType.Availability]: new Set<AllRouteSteps>(
      availabilityStoredSteps as AllRouteSteps[],
    ),
  };

  const [completedStepsState, setCompletedStepsState] =
    useState<Record<FlowType, Set<AllRouteSteps>>>(completedSteps);

  const markStepAsCompleted = useCallback(
    (sequence: FlowType, step: AllRouteSteps) => {
      setCompletedStepsState((prev) => {
        if (prev[sequence].has(step)) {
          return prev;
        }

        const updated = { ...prev };
        const newSet = new Set(updated[sequence]);
        newSet.add(step);
        updated[sequence] = newSet;

        switch (sequence) {
          case FlowType.Customer:
            setCustomerStoredSteps(Array.from(newSet));
            break;
          case FlowType.Business:
            setBusinessStoredSteps(Array.from(newSet));
            break;
          case FlowType.Availability:
            setAvailabilityStoredSteps(Array.from(newSet));
            break;
          default:
            break;
        }

        return updated;
      });
    },
    [
      setCustomerStoredSteps,
      setBusinessStoredSteps,
      setAvailabilityStoredSteps,
    ],
  );

  const isStepCompleted = useCallback(
    (sequence: FlowType, step: AllRouteSteps): boolean => {
      return completedStepsState[sequence]?.has(step) || false;
    },
    [completedStepsState],
  );

  const resetSteps = (sequence: FlowType) => {
    setCompletedStepsState((prev) => {
      const updated = { ...prev };
      updated[sequence].clear();

      switch (sequence) {
        case FlowType.Customer:
          setCustomerStoredSteps([]);
          break;
        case FlowType.Business:
          setBusinessStoredSteps([]);
          break;
        case FlowType.Availability:
          setAvailabilityStoredSteps([]);
          break;
        default:
          break;
      }

      return updated;
    });
  };

  const { storedValue: isRedirecting, setValue: setIsRedirectingStorage } =
    useLocalStorage<boolean>(StorageKeys.IsRedirecting, false);

  const onRedirect = (value: boolean) => {
    setIsRedirectingStorage(value);
  };

  return (
    <StepContext.Provider
      value={{
        completedSteps: completedStepsState,
        markStepAsCompleted,
        isStepCompleted,
        resetSteps,
        isRedirecting,
        onRedirect,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
