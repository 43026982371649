import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Slot } from "../../../constants/types";
import {
  createSlots,
  bookSlot,
  fetchSlots,
  cancelSlot,
  changeSlot,
} from "./thunk";

interface SlotsState {
  availableSlots: Slot[];
  unavailableSlots: Slot[];
  fetchingSlots: boolean;
  creatingSlots: boolean;
  createSlotsSucceeded: boolean;
  bookingSlot: boolean;
  bookSlotSucceeded: boolean;
  cancellingSlot: boolean;
  cancelSlotSucceeded: boolean;
  changingSlot: boolean;
  changeSlotSucceeded: boolean;
  error: string | null;
}

const initialState: SlotsState = {
  availableSlots: [],
  unavailableSlots: [],
  fetchingSlots: false,
  creatingSlots: false,
  createSlotsSucceeded: false,
  bookingSlot: false,
  bookSlotSucceeded: false,
  cancellingSlot: false,
  cancelSlotSucceeded: false,
  changingSlot: false,
  changeSlotSucceeded: false,
  error: null,
};

const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    addAvailableSlot: (state, action: PayloadAction<Slot>) => {
      state.availableSlots.push(action.payload);
    },
    resetChangeSlotSucceeded: (state) => {
      state.changeSlotSucceeded = false;
    },
    resetBookSlotSucceeded: (state) => {
      state.bookSlotSucceeded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlots.pending, (state) => {
        state.fetchingSlots = true;
        state.error = null;
      })
      .addCase(fetchSlots.fulfilled, (state, action) => {
        state.availableSlots = action.payload.availableSlots;
        state.unavailableSlots = action.payload.unavailableSlots;
        state.fetchingSlots = false;
      })
      .addCase(fetchSlots.rejected, (state, action) => {
        state.error = action.payload as string;
        state.fetchingSlots = false;
      })
      .addCase(createSlots.pending, (state) => {
        state.creatingSlots = true;
        state.createSlotsSucceeded = false;
        state.error = null;
      })
      .addCase(createSlots.fulfilled, (state) => {
        state.creatingSlots = false;
        state.createSlotsSucceeded = true;
      })
      .addCase(createSlots.rejected, (state, action) => {
        state.error = action.payload as string;
        state.creatingSlots = false;
        state.createSlotsSucceeded = false;
      })
      .addCase(bookSlot.pending, (state) => {
        state.bookingSlot = true;
        state.bookSlotSucceeded = false;
        state.error = null;
      })
      .addCase(bookSlot.fulfilled, (state) => {
        state.bookingSlot = false;
        state.bookSlotSucceeded = true;
      })
      .addCase(bookSlot.rejected, (state, action) => {
        state.error = action.payload as string;
        state.bookingSlot = false;
        state.bookSlotSucceeded = false;
      })
      .addCase(cancelSlot.pending, (state) => {
        state.cancellingSlot = true;
        state.cancelSlotSucceeded = false;
        state.error = null;
      })
      .addCase(cancelSlot.fulfilled, (state) => {
        state.cancellingSlot = false;
        state.cancelSlotSucceeded = true;
      })
      .addCase(cancelSlot.rejected, (state, action) => {
        state.error = action.payload as string;
        state.cancellingSlot = false;
        state.cancelSlotSucceeded = false;
      })
      .addCase(changeSlot.pending, (state) => {
        state.changingSlot = true;
        state.changeSlotSucceeded = false;
        state.error = null;
      })
      .addCase(changeSlot.fulfilled, (state) => {
        state.changingSlot = false;
        state.changeSlotSucceeded = true;
      })
      .addCase(changeSlot.rejected, (state, action) => {
        state.error = action.payload as string;
        state.changingSlot = false;
        state.changeSlotSucceeded = false;
      });
  },
});

export const {
  addAvailableSlot,
  resetChangeSlotSucceeded,
  resetBookSlotSucceeded,
} = slotsSlice.actions;

export default slotsSlice.reducer;
