import React, { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import KLCollapsibleSection from "../KLCollapsibleSection";
import { MonthsContainer, MonthButton, NoDataMessage } from "./styles";

interface KLDashboardInsightsProps {
  businessId: string;
}

const KLDashboardInsights: React.FC<KLDashboardInsightsProps> = ({
  businessId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const { availablePeriods, loadingPeriods } = useSelector(
    (state: RootState) => state.insights,
  );

  const availableMonths = useMemo(() => {
    return availablePeriods
      .flatMap((period) =>
        period.months.map((month) => ({
          year: period.year,
          month,
          label: DateTime.fromObject({ year: period.year, month })
            .setLocale("he")
            .toFormat("MMMM yyyy"),
        })),
      )
      .sort((a, b) => {
        // Sort by most recent first
        if (a.year !== b.year) return b.year - a.year;
        return b.month - a.month;
      });
  }, [availablePeriods]);

  const handleMonthClick = useCallback(
    (year: number, month: number) => {
      navigate(`/insights/${businessId}/${year}/${month}`);
    },
    [businessId, dispatch, navigate],
  );

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <KLCollapsibleSection
      title="הנתונים החודשיים שלי"
      isExpanded={isExpanded}
      onToggle={handleToggle}
      secondary
    >
      {loadingPeriods ? (
        <NoDataMessage>טוען תקופות זמינות...</NoDataMessage>
      ) : availableMonths.length > 0 ? (
        <MonthsContainer>
          {availableMonths.map(({ year, month, label }) => (
            <MonthButton
              key={`${year}-${month}`}
              onClick={() => handleMonthClick(year, month)}
            >
              {label}
            </MonthButton>
          ))}
        </MonthsContainer>
      ) : (
        <NoDataMessage>
          אין עדיין נתונים זמינים לניתוח. הנתונים יהיו זמינים לאחר שיתקבלו
          הזמנות.
        </NoDataMessage>
      )}
    </KLCollapsibleSection>
  );
};

export default KLDashboardInsights;
