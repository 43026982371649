import apiClient from "../api/apiClient";
import { VerifyTokenResponse } from "../constants/types";

class TokensService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async verifyToken(token: string): Promise<VerifyTokenResponse> {
    try {
      const response = await apiClient.get<VerifyTokenResponse>(
        `${this.baseApiUrl}/api/verify-token`,
        {
          params: { token },
        },
      );

      const data = response.data;

      if (data.valid && data.payload) {
        return data;
      } else {
        throw new Error(data.message || "Invalid token");
      }
    } catch (error) {
      console.error("Error verifying token:", error);
      throw new Error((error as Error).message || "Failed to verify token");
    }
  }
}

export const tokensService = new TokensService();
