import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CustomerWithLatestBooking } from "../../constants/types";
import { CustomerContentWrapper, CustomerInfo, BookingInfo } from "./styles";
import { extractHoursMinutesFromIsoString } from "../../utils";
import { BusinessCategoryGender } from "../../constants/customers/categoryConfigMap";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLButton from "../../components/KLButton";
import KLCreateNewSlotModal from "../KLCreateNewSlotModal";
import { RootState } from "../../store";

interface KLCustomerItemProps {
  customer: CustomerWithLatestBooking;
  term: string;
  pluralTerm: string;
  gender: BusinessCategoryGender;
}

const KLCustomerItem: React.FC<KLCustomerItemProps> = ({
  customer,
  term,
  pluralTerm,
  gender,
}) => {
  const {
    name,
    phone,
    latestBookingStartTime,
    latestBookingEndTime,
    serviceName,
  } = customer;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggle = () => setIsExpanded((prev) => !prev);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const businessId = useSelector(
    (state: RootState) => state.businesses.businessId,
  );

  const { text: subtitle, jsx: bookingJsx } = useMemo(() => {
    const formattedStartTime = latestBookingStartTime
      ? extractHoursMinutesFromIsoString(latestBookingStartTime)
      : null;
    const formattedEndTime = latestBookingEndTime
      ? extractHoursMinutesFromIsoString(latestBookingEndTime)
      : null;
    const bookingDate = latestBookingStartTime
      ? new Date(latestBookingStartTime).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
      : "";

    const isSet = formattedStartTime && formattedEndTime;
    const now = new Date();
    const isFutureBooking = latestBookingStartTime
      ? new Date(latestBookingStartTime) > now
      : false;
    const genderStr = isFutureBooking
      ? gender === BusinessCategoryGender.Male
        ? "הבא"
        : "הבאה"
      : gender === BusinessCategoryGender.Male
        ? "האחרון"
        : "האחרונה";

    if (isSet) {
      return {
        text: `ה${term} ${genderStr}: ${bookingDate} | ${formattedStartTime} - ${formattedEndTime}`,
        jsx: (
          <>
            <strong>{`ה${term} ${genderStr}:`}</strong> {bookingDate} |{" "}
            {formattedStartTime} - {formattedEndTime}
          </>
        ),
      };
    } else {
      return {
        text: `לא נקבעו עדיין ${pluralTerm}`,
        jsx: <>לא נקבעו עדיין {pluralTerm}</>,
      };
    }
  }, [latestBookingStartTime, latestBookingEndTime, term, pluralTerm, gender]);

  return (
    <>
      <KLCollapsibleSection
        title={name}
        subtitle={subtitle}
        isExpanded={isExpanded}
        onToggle={handleToggle}
      >
        <CustomerContentWrapper>
          <CustomerInfo>
            {name} | {phone}
          </CustomerInfo>
          <BookingInfo>{bookingJsx}</BookingInfo>
          {serviceName && (
            <BookingInfo>
              <strong>שירות אחרון שנקבע:</strong> {serviceName}
            </BookingInfo>
          )}
          <KLButton title={`קבע ${term}`} onClick={handleOpenModal} />
        </CustomerContentWrapper>
      </KLCollapsibleSection>
      {isModalOpen && businessId && (
        <KLCreateNewSlotModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          customerName={name}
          customerPhone={phone}
          term={term}
          businessId={businessId}
        />
      )}
    </>
  );
};

export default KLCustomerItem;
