import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import KLButton from "../KLButton";
import KLTextInput from "../KLTextInput";
import { AppDispatch, RootState } from "../../store";
import { addNewCustomer } from "../../store/duckers/customers/thunk";
import {
  CloseButton,
  FieldWrapper,
  FormWrapper,
  ModalBox,
  ModalHeader,
  Title,
} from "../../screens/styles";
import { UserType } from "../../constants/types";

interface KLAddNewCustomerModalProps {
  open: boolean;
  handleClose: () => void;
  businessId: string;
}

const KLAddNewCustomerModal: React.FC<KLAddNewCustomerModalProps> = ({
  open,
  handleClose,
  businessId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [isNameValid, setIsNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const addingCustomer = useSelector(
    (state: RootState) => state.customers.addingCustomer,
  );
  const addCustomerSucceeded = useSelector(
    (state: RootState) => state.customers.addCustomerSucceeded,
  );
  const allCustomers = useSelector(
    (state: RootState) => state.customers.allCustomers,
  );

  const phoneExists = allCustomers.some(
    (customer) =>
      customer.phone.trim() === customerPhone.trim() &&
      customerPhone.trim() !== "",
  );

  const isFormValid =
    customerName.trim() !== "" &&
    customerPhone.trim() !== "" &&
    isNameValid &&
    isPhoneValid &&
    !phoneExists;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      dispatch(
        addNewCustomer({
          businessId,
          phone: customerPhone,
          name: customerName,
          userType: UserType.Business,
        }),
      );
    }
  };

  useEffect(() => {
    if (addCustomerSucceeded) {
      setCustomerName("");
      setCustomerPhone("");
      handleClose();
    }
  }, [addCustomerSucceeded, handleClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-new-customer-modal-title"
      aria-describedby="add-new-customer-modal-description"
    >
      <ModalBox>
        <ModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <FormWrapper onSubmit={handleSubmit}>
          <Title>הוסף לקוח חדש</Title>
          <FieldWrapper>
            <KLTextInput
              type="text"
              placeholder="שם לקוח"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              disabled={addingCustomer}
              onValidationChange={(isValid) => setIsNameValid(isValid)}
            />
          </FieldWrapper>
          <FieldWrapper>
            <KLTextInput
              type="tel"
              placeholder="טלפון"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
              disabled={addingCustomer}
              onValidationChange={(isValid) => setIsPhoneValid(isValid)}
            />
            {phoneExists && (
              <p
                style={{ color: "red", fontSize: "14px", marginTop: "0.5rem" }}
              >
                טלפון כבר קיים.
              </p>
            )}
          </FieldWrapper>
          <FieldWrapper>
            <KLButton
              title="הולכים על זה"
              type="submit"
              loading={addingCustomer}
              disabled={addingCustomer || !isFormValid}
            />
          </FieldWrapper>
        </FormWrapper>
      </ModalBox>
    </Modal>
  );
};

export default KLAddNewCustomerModal;
