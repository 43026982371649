import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { setCustomerName } from "../../store/duckers/customers/slice";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  IconContainer,
  InfoText,
  TextIconContainer,
  Title,
} from "../styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import KLPleaseWait from "../../components/KLPleaseWait";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKeys } from "../../constants/localStorage";
import {
  BusinessCategory,
  BusinessCategoryGender,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";
import { EmojiPeople } from "@mui/icons-material";
import { addNewCustomer } from "../../store/duckers/customers/thunk";
import { CustomerRouteStep, FlowType } from "../../constants/routes";
import { UserType } from "../../constants/types";

const CustomerInfoScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId } = useParams<{ businessId: string }>();

  const { storedValue: storedName, setValue: setStoredName } =
    useLocalStorage<string>(StorageKeys.CustomerName, "");

  const { storedValue: storedPhone } = useLocalStorage<string>(
    StorageKeys.CustomerPhone,
    "",
  );

  const [name, setName] = useState<string>(storedName);

  const {
    fetchingCustomerSlots,
    customerSlots,
    business,
    addingCustomer,
    addCustomerSucceeded,
  } = useSelector((state: RootState) => state.customers);

  const { pluralTerm, gender } = useMemo(() => {
    return getCategoryConfigTerms(business?.category as BusinessCategory);
  }, [business?.category]);

  const isFormValid = useMemo(() => {
    return name.trim() !== "";
  }, [name]);

  const handleConfirm = () => {
    if (isFormValid && businessId) {
      setStoredName(name);
      dispatch(setCustomerName(name));
      dispatch(
        addNewCustomer({
          businessId,
          phone: storedPhone,
          name,
          userType: UserType.Customer,
        }),
      );
    }
  };

  useEffect(() => {
    if (addCustomerSucceeded && businessId) {
      markStepAsCompleted(FlowType.Customer, CustomerRouteStep.Info);
      if (customerSlots.length > 0) {
        navigate(`/${businessId}/existingSlots`);
      } else {
        navigate(`/${businessId}/services`);
      }
    }
  }, [
    addCustomerSucceeded,
    businessId,
    customerSlots.length,
    markStepAsCompleted,
    navigate,
  ]);

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={1} />
      {fetchingCustomerSlots ? (
        <KLPleaseWait
          text={`בודקים ${pluralTerm} ${gender === BusinessCategoryGender.Male ? "קיימים" : "קיימות"}`}
        />
      ) : (
        <>
          <TextIconContainer>
            <IconContainer>
              <EmojiPeople />
            </IconContainer>
            <Title>היי, נעים מאוד!</Title>
          </TextIconContainer>
          <InfoText>נשמח להכיר ולדעת איך קוראים לכם</InfoText>
          <FormWrapper
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם ומשפחה"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={addingCustomer}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLButton
                title="ממשיכים"
                disabled={!isFormValid || addingCustomer}
                loading={addingCustomer}
              />
            </FieldWrapper>
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default CustomerInfoScreen;
