import axios from "axios";
import { StorageKey } from "../constants/localStorage";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

const publicEndpointPatterns: RegExp[] = [
  /^\/api\/auth\/generate-otp$/,
  /^\/api\/auth\/verify-otp$/,
  /^\/api\/verify-token$/,
  /^\/api\/[^/]+$/,
  /^\/api\/short-links\/[^/]+$/,
];

apiClient.interceptors.request.use(
  (config) => {
    const isPublic = publicEndpointPatterns.some((pattern) =>
      pattern.test(config.url || ""),
    );

    if (!isPublic) {
      const storageKey = config.headers["x-token-type"] as StorageKey;
      const tokenString = localStorage.getItem(storageKey);
      let token = null;

      if (tokenString) {
        try {
          token = JSON.parse(tokenString);
        } catch (error) {
          console.error("Error parsing token from localStorage:", error);
        }
      }

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY as string;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.warn("Token expired or invalid");
    }
    return Promise.reject(error);
  },
);

export default apiClient;
