import React from "react";
import { Service } from "../../constants/types";
import KLListManager from "../KLListManager";
import KLServiceItem from "../KLServiceItem";

interface KLServicesListProps {
  services: Service[];
  isPriceValid: boolean[];
  expandedServices: boolean[];
  onAddService: () => void;
  onDeleteService: (index: number) => void;
  onServiceChange: (
    index: number,
    field: keyof Service,
    value: Service[keyof Service],
  ) => void;
  onPriceValidation?: (index: number, isValid: boolean) => void;
  toggleServiceExpansion: (index: number) => void;
  durationOptions: { label: string; value: string }[];
  cancellationOptions: { label: string; value: string }[];
}

const KLServicesList: React.FC<KLServicesListProps> = ({
  services,
  isPriceValid,
  expandedServices,
  onAddService,
  onDeleteService,
  onServiceChange,
  onPriceValidation,
  toggleServiceExpansion,
  durationOptions,
  cancellationOptions,
}) => {
  return (
    <KLListManager
      items={services}
      pluralTerm="שירותים"
      onAdd={onAddService}
      noItemsText="אין שירותים זמינים."
      renderItem={(service, index) => (
        <KLServiceItem
          key={index}
          service={service}
          index={index}
          isExpanded={expandedServices[index]}
          onToggle={toggleServiceExpansion}
          onDelete={onDeleteService}
          onServiceChange={onServiceChange}
          onPriceValidation={onPriceValidation}
          durationOptions={durationOptions}
          cancellationOptions={cancellationOptions}
        />
      )}
    />
  );
};

export default KLServicesList;
