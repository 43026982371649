import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Business,
  Customer,
  CustomerSlot,
  CustomerWithLatestBooking,
  UserType,
} from "../../../constants/types";
import { businessService } from "../../../services/businessService";
import { customerService } from "../../../services/customerService";

export const fetchBusiness = createAsyncThunk<
  Business,
  string,
  { rejectValue: string }
>("business/fetchBusiness", async (businessId, { rejectWithValue }) => {
  try {
    return await businessService.fetchBusiness(businessId);
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});

export const checkCustomerSlots = createAsyncThunk<
  { customerSlots: CustomerSlot[] },
  { businessId: string; phone: string },
  { rejectValue: string }
>(
  "customers/checkCustomerSlots",
  async ({ businessId, phone }, { rejectWithValue }) => {
    try {
      const response = await customerService.checkCustomerSlots(
        businessId,
        phone,
      );
      return { customerSlots: response };
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const addNewCustomer = createAsyncThunk<
  { customerId: string; userType: UserType },
  {
    businessId: string;
    phone: string;
    name: string;
    userType: UserType;
  },
  { rejectValue: string }
>(
  "customers/addNewCustomer",
  async ({ businessId, phone, name, userType }, { rejectWithValue }) => {
    try {
      const customerId = await customerService.addNewCustomer(
        businessId,
        phone,
        name,
        userType,
      );
      return { customerId, userType };
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchCustomerDetails = createAsyncThunk<
  { customer: Customer | null },
  { businessId: string; phone: string },
  { rejectValue: string }
>(
  "customers/fetchCustomerDetails",
  async ({ businessId, phone }, { rejectWithValue }) => {
    try {
      const customer = await customerService.getCustomerDetails(
        businessId,
        phone,
      );
      return { customer };
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchAllCustomers = createAsyncThunk<
  { customers: CustomerWithLatestBooking[] },
  string,
  { rejectValue: string }
>("customers/fetchAllCustomers", async (businessId, { rejectWithValue }) => {
  try {
    const customers = await customerService.getAllCustomers(businessId);
    return { customers };
  } catch (error) {
    const errorMessage = (error as Error).message;
    return rejectWithValue(errorMessage);
  }
});
