import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppDispatch, RootState } from "../../store";
import KLPleaseWait from "../../components/KLPleaseWait";
import { Container, Title } from "../styles";
import colors from "../../constants/colors";
import KLButton from "../../components/KLButton";
import { FlowType } from "../../constants/routes";
import {
  CancellationRateBar,
  CancellationRateLabel,
  CancellationRateValue,
  ChartContainer,
  ComparisonCard,
  ComparisonContainer,
  ComparisonDiff,
  ComparisonLabel,
  ComparisonValue,
  LeadTimeBar,
  LeadTimeContainer,
  LeadTimeLabel,
  LeadTimeValue,
  ListContainer,
  ListItem,
  ListItemName,
  ListItemValue,
  MetricCard,
  MetricLabel,
  MetricValue,
  Section,
  SectionSubtitle,
  SectionTitle,
  TrendValue,
  WeeklyTrendBar,
  WeeklyTrendContainer,
  WeeklyTrendLabel,
} from "./styles";
import { DateTime } from "luxon";
import { fetchMonthlyInsights } from "../../store/duckers/insights/thunk";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
);

const DAYS_OF_WEEK = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];

const InsightsScreen: React.FC = () => {
  const { businessId, year, month } = useParams<{
    businessId: string;
    year: string;
    month: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    loading,
    error,
    bookingVolume,
    servicePerformance,
    customerBehavior,
    currentPeriod,
  } = useSelector((state: RootState) => state.insights);

  useEffect(() => {
    if (!businessId || !year || !month) return;

    const shouldFetchData =
      // If we don't have current period data
      !currentPeriod.businessId ||
      !currentPeriod.year ||
      !currentPeriod.month ||
      // Or if we're looking at a different period
      currentPeriod.businessId !== businessId ||
      currentPeriod.year !== Number(year) ||
      currentPeriod.month !== Number(month);

    if (shouldFetchData) {
      dispatch(fetchMonthlyInsights([businessId, Number(year), Number(month)]));
    }
  }, [dispatch, businessId, year, month, currentPeriod]);

  const monthLabel = DateTime.fromObject({
    year: Number(year),
    month: Number(month),
  })
    .setLocale("he")
    .toFormat("MMMM yyyy");

  if (loading) {
    return <KLPleaseWait text="בודקים מה היה לכם החודש" />;
  }

  if (error) {
    return <div>שגיאה בטעינת התובנות: {error}</div>;
  }

  // Find max values for scaling
  const maxWeeklyBookings = Math.max(
    ...bookingVolume.weeklyTrends.map((w) => w.bookingCount),
  );
  const maxDailyBookings = Math.max(
    ...bookingVolume.dailyTrends.map((d) => d.bookingCount),
  );
  const maxCancellationRate = Math.max(
    ...servicePerformance.cancellationRates.map((s) => s.cancellationRate),
  );

  // Prepare data for service distribution pie chart
  const totalBookingsForServices = servicePerformance.topServices.reduce(
    (sum, service) => sum + service.bookingCount,
    0,
  );
  const pieChartColors = [
    colors.PURPLE,
    colors.PURPLE_LIGHT,
    colors.PURPLE_LIGHTER,
    colors.PURPLE_LIGHTEST,
    colors.GREY_3,
  ];
  const serviceDistributionData: ChartData<"pie"> = {
    labels: servicePerformance.topServices.map((s) => s.serviceName),
    datasets: [
      {
        data: servicePerformance.topServices.map((s) => s.bookingCount),
        backgroundColor: pieChartColors,
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <Title>הנתונים החודשיים שלי - {monthLabel}</Title>

      {/* Booking Volume Section with Comparisons */}
      <Section>
        <SectionTitle>
          <TrendingUpIcon />
          סיכום חודשי
        </SectionTitle>
        <ComparisonContainer>
          <ComparisonCard>
            <ComparisonLabel>סה"כ הזמנות</ComparisonLabel>
            <ComparisonValue>{bookingVolume.totalBookings}</ComparisonValue>
            <ComparisonDiff $positive={bookingVolume.monthOverMonthGrowth > 0}>
              {bookingVolume.monthOverMonthGrowth > 0 ? "↑" : "↓"}{" "}
              {Math.abs(bookingVolume.monthOverMonthGrowth).toFixed(1)}%
              <span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>

          <ComparisonCard>
            <ComparisonLabel>סה"כ ביטולים</ComparisonLabel>
            <ComparisonValue>
              {servicePerformance.cancellationRates.reduce(
                (total, service) => total + service.cancelledBookings,
                0,
              )}
            </ComparisonValue>
            <ComparisonDiff
              $positive={
                servicePerformance.cancellationGrowth.monthOverMonthGrowth <= 0
              }
            >
              {servicePerformance.cancellationGrowth.monthOverMonthGrowth > 0
                ? "↑"
                : "↓"}{" "}
              {Math.abs(
                servicePerformance.cancellationGrowth.monthOverMonthGrowth,
              ).toFixed(1)}
              %<span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>

          <ComparisonCard>
            <ComparisonLabel>לקוחות חוזרים</ComparisonLabel>
            <ComparisonValue>
              {customerBehavior.customerSegmentation.repeatCustomers.count}
            </ComparisonValue>
            <ComparisonDiff
              $positive={
                customerBehavior.customerGrowth.repeatCustomers
                  .monthOverMonthGrowth > 0
              }
            >
              {customerBehavior.customerGrowth.repeatCustomers
                .monthOverMonthGrowth > 0
                ? "↑"
                : "↓"}{" "}
              {Math.abs(
                customerBehavior.customerGrowth.repeatCustomers
                  .monthOverMonthGrowth,
              ).toFixed(1)}
              %<span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>

          <ComparisonCard>
            <ComparisonLabel>לקוחות חדשים</ComparisonLabel>
            <ComparisonValue>
              {customerBehavior.customerSegmentation.newCustomers.count}
            </ComparisonValue>
            <ComparisonDiff
              $positive={
                customerBehavior.customerGrowth.newCustomers
                  .monthOverMonthGrowth > 0
              }
            >
              {customerBehavior.customerGrowth.newCustomers
                .monthOverMonthGrowth > 0
                ? "↑"
                : "↓"}{" "}
              {Math.abs(
                customerBehavior.customerGrowth.newCustomers
                  .monthOverMonthGrowth,
              ).toFixed(1)}
              %<span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>

          <ComparisonCard>
            <ComparisonLabel>זמן הזמנה ממוצע</ComparisonLabel>
            <ComparisonValue>
              {(customerBehavior.bookingLeadTime.averageLeadTime / 24).toFixed(
                1,
              )}{" "}
              ימים
            </ComparisonValue>
            <ComparisonDiff
              $positive={
                customerBehavior.bookingMetrics.averageLeadTime
                  .monthOverMonthGrowth < 0
              }
            >
              {customerBehavior.bookingMetrics.averageLeadTime
                .monthOverMonthGrowth > 0
                ? "↑"
                : "↓"}{" "}
              {Math.abs(
                customerBehavior.bookingMetrics.averageLeadTime
                  .monthOverMonthGrowth,
              ).toFixed(1)}
              %<span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>

          <ComparisonCard>
            <ComparisonLabel>הזמנות לשבוע בממוצע</ComparisonLabel>
            <ComparisonValue>
              {customerBehavior.bookingMetrics.averageBookingsPerWeek.current.toFixed(
                1,
              )}
            </ComparisonValue>
            <ComparisonDiff
              $positive={
                customerBehavior.bookingMetrics.averageBookingsPerWeek
                  .monthOverMonthGrowth > 0
              }
            >
              {customerBehavior.bookingMetrics.averageBookingsPerWeek
                .monthOverMonthGrowth > 0
                ? "↑"
                : "↓"}{" "}
              {Math.abs(
                customerBehavior.bookingMetrics.averageBookingsPerWeek
                  .monthOverMonthGrowth,
              ).toFixed(1)}
              %<span>מהחודש הקודם</span>
            </ComparisonDiff>
          </ComparisonCard>
        </ComparisonContainer>
      </Section>

      {/* Service Distribution Section */}
      <Section>
        <SectionTitle>
          <AssignmentIcon />
          התפלגות שירותים
        </SectionTitle>
        <ChartContainer>
          <Pie
            data={serviceDistributionData}
            options={{
              plugins: {
                legend: {
                  position: "bottom" as const,
                  rtl: true,
                  maxWidth: 800,
                  labels: {
                    padding: 20,
                    boxWidth: 15,
                    font: {
                      size: 12,
                    },
                    generateLabels: (chart) => {
                      const data = chart.data.datasets[0].data as number[];
                      const total = data.reduce((sum, value) => sum + value, 0);

                      return (
                        chart.data.labels?.map((label, index) => ({
                          text: `${label} (${((data[index] / total) * 100).toFixed(1)}%)`,
                          fillStyle: pieChartColors[index],
                          strokeStyle: pieChartColors[index],
                          lineWidth: 0,
                          hidden: false,
                          index: index,
                          textAlign: "right" as const,
                        })) || []
                      );
                    },
                  },
                  align: "start",
                  fullSize: true,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw as number;
                      const percentage = (
                        (value / totalBookingsForServices) *
                        100
                      ).toFixed(1);
                      return `${context.label}: ${value} הזמנות (${percentage}%)`;
                    },
                  },
                },
              },
              layout: {
                padding: {
                  bottom: 20,
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </ChartContainer>
      </Section>

      {/* Weekly Trends Section */}
      <Section>
        <SectionTitle>
          <CalendarTodayIcon />
          מגמות שבועיות
        </SectionTitle>
        <SectionSubtitle>
          התפלגות ההזמנות לפי שבועות בחודש, המאפשרת לזהות תקופות עמוסות ושקטות
        </SectionSubtitle>
        <WeeklyTrendContainer>
          {bookingVolume.weeklyTrends.map((week) => (
            <div key={week.weekNumber}>
              <WeeklyTrendBar
                height={(week.bookingCount / maxWeeklyBookings) * 100}
              />
              <WeeklyTrendLabel>שבוע {week.weekNumber}</WeeklyTrendLabel>
              <TrendValue>{week.bookingCount}</TrendValue>
            </div>
          ))}
        </WeeklyTrendContainer>
      </Section>

      {/* Daily Trends Section */}
      <Section>
        <SectionTitle>
          <CalendarTodayIcon />
          מגמות יומיות
        </SectionTitle>
        <SectionSubtitle>
          התפלגות ההזמנות לפי ימי השבוע, המאפשרת לזהות את הימים העמוסים והשקטים
        </SectionSubtitle>
        <WeeklyTrendContainer>
          {bookingVolume.dailyTrends.map((day) => (
            <div key={day.dayOfWeek}>
              <WeeklyTrendBar
                height={(day.bookingCount / maxDailyBookings) * 100}
              />
              <WeeklyTrendLabel>{DAYS_OF_WEEK[day.dayOfWeek]}</WeeklyTrendLabel>
              <TrendValue>{day.bookingCount}</TrendValue>
            </div>
          ))}
        </WeeklyTrendContainer>
      </Section>

      {/* Service Performance Section */}
      <Section>
        <SectionTitle>
          <AssignmentIcon />
          ביצועי שירותים
        </SectionTitle>
        <SectionSubtitle>השירותים המובילים</SectionSubtitle>
        <ListContainer>
          {servicePerformance.topServices.map((service) => (
            <ListItem key={service.serviceId}>
              <ListItemName>{service.serviceName}</ListItemName>
              <ListItemValue>{service.bookingCount} הזמנות</ListItemValue>
            </ListItem>
          ))}
        </ListContainer>
      </Section>

      {/* Cancellation Rates Section */}
      <Section>
        <SectionTitle>
          <CancelIcon />
          שיעורי ביטולים
        </SectionTitle>
        <ListContainer>
          {servicePerformance.cancellationRates.map((service) => (
            <ListItem key={service.serviceId}>
              <ListItemName>{service.serviceName}</ListItemName>
              <div>
                <CancellationRateBar
                  width={(service.cancellationRate / maxCancellationRate) * 100}
                />
                <CancellationRateLabel>
                  <CancellationRateValue>
                    {service.cancellationRate.toFixed(1)}%
                  </CancellationRateValue>
                  ({service.cancelledBookings} מתוך {service.totalBookings})
                </CancellationRateLabel>
              </div>
            </ListItem>
          ))}
        </ListContainer>
      </Section>

      {/* Lead Time Distribution Section */}
      <Section>
        <SectionTitle>
          <QueryBuilderIcon />
          זמן הזמנה מראש
        </SectionTitle>
        <MetricCard>
          <MetricLabel>זמן ממוצע להזמנה מראש</MetricLabel>
          <MetricValue>
            {(customerBehavior.bookingLeadTime.averageLeadTime / 24).toFixed(1)}{" "}
            ימים
          </MetricValue>
        </MetricCard>
        <LeadTimeContainer>
          {[...customerBehavior.bookingLeadTime.leadTimeDistribution]
            .sort((a, b) => {
              const order: Record<string, number> = {
                "0-24 hours": 1,
                "25-72 hours": 2,
                "73-168 hours": 3,
                "168+ hours": 4,
              };
              return (order[a.range] || 0) - (order[b.range] || 0);
            })
            .map((dist) => {
              let rangeText = dist.range
                .replace("25-72 hours", "1-3 ימים")
                .replace("0-24 hours", "0-24 שעות")
                .replace("73-168 hours", "3-7 ימים")
                .replace("168+ hours", "יותר מ-7 ימים");

              return (
                <ListItem key={dist.range}>
                  <LeadTimeLabel>{rangeText}</LeadTimeLabel>
                  <div>
                    <LeadTimeBar width={dist.percentage} />
                    <LeadTimeValue>
                      {dist.percentage.toFixed(1)}% ({dist.count} הזמנות)
                    </LeadTimeValue>
                  </div>
                </ListItem>
              );
            })}
        </LeadTimeContainer>
      </Section>

      {/* Customer Behavior Section */}
      <Section>
        <SectionTitle>
          <PeopleIcon />
          התנהגות לקוחות
        </SectionTitle>
        <SectionSubtitle>הלקוחות המובילים</SectionSubtitle>
        <ListContainer>
          {customerBehavior.topCustomers.map((customer) => (
            <ListItem key={customer.customerId}>
              <ListItemName>{customer.customerName}</ListItemName>
              <ListItemValue>{customer.bookingCount} הזמנות</ListItemValue>
            </ListItem>
          ))}
        </ListContainer>
      </Section>

      <KLButton
        title="בחזרה לעסק שלי"
        onClick={() => navigate(`/${FlowType.Business}/dashboard`)}
        secondary
      />
    </Container>
  );
};

export default InsightsScreen;
