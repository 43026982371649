import styled from "styled-components";
import colors from "../constants/colors";
import { IconButton, SxProps, Theme } from "@mui/material";

export const Container = styled("div")({
  backgroundColor: colors.BACKGROUND,
  borderRadius: "15px",
  padding: "0 1rem 1rem 1rem",
  color: colors.BLACK,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
  direction: "rtl",
});

export const SmallText = styled("div")({
  fontSize: "13px",
});

export const ErrorMessage = styled("div")({
  color: colors.RED,
  fontSize: "15px",
  fontWeight: 600,
});

export const TextIconContainer = styled("div")({
  display: "flex",
});

export const IconContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "0.5rem",
});

export const Title = styled("div")({
  fontSize: "24px",
  fontWeight: "bold",
  color: colors.BLACK,
  direction: "rtl",
});

export const Subtitle = styled("div")<{
  $noMarginBottom?: boolean;
}>(({ $noMarginBottom }) => ({
  fontSize: "20px",
  fontWeight: 700,
  color: colors.GREY_7,
  maxWidth: "300px",
  textAlign: "center",
  marginBottom: $noMarginBottom ? "0" : "1rem",
}));

export const InfoText = styled("p")({
  fontSize: "16px",
  color: colors.GREY_6,
  marginBottom: "1.5rem",
  maxWidth: "300px",
});

export const ContentWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
});

export const Content = styled("p")({
  fontSize: "18px",
  marginBottom: "1rem",
  color: colors.GREY_6,
  maxWidth: "250px",
});

export const ButtonWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const FormWrapper = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
});

export const FieldWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
});

export const PleaseWaitContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const chipSx = (selected: boolean): SxProps<Theme> => ({
  backgroundColor: selected ? colors.PURPLE_LIGHT : colors.GREY_2,
  color: selected ? colors.WHITE : colors.BLACK,
  "&:hover": {
    backgroundColor: selected ? colors.PURPLE_LIGHT : colors.GREY_2,
  },
});

export const ChipWrapper = styled("div")({
  width: "auto",
});

export const ChipServiceDescription = styled("div")({
  fontSize: "16px",
  fontWeight: 700,
});

export const LinkWrapper = styled("div")({
  backgroundColor: colors.GREY_1,
  padding: "0.6rem",
  borderRadius: "4px",
  fontFamily: "monospace",
  wordBreak: "break-all",
  color: colors.BLACK,
});

export const LinkButton = styled("a")({
  fontSize: "13px",
  color: colors.PURPLE_LIGHT,
  cursor: "pointer",
  textDecoration: "underline",
  display: "inline-block",
  lineHeight: "10px",
});

export const Disclaimer = styled("div")({
  width: "100%",
  textAlign: "right",
  fontSize: "12px",
  marginTop: "2rem",
});

export const StyledLinkContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export const CopyButton = styled("button")<{ $copied: boolean }>(
  ({ $copied }) => ({
    backgroundColor: $copied ? colors.PURPLE_LIGHTEST : colors.PURPLE,
    color: colors.WHITE,
    border: "none",
    borderRadius: "4px",
    padding: "0.5rem 0.75rem",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    cursor: $copied ? "not-allowed" : "pointer",
    transition: "background-color 0.3s ease",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
    },
  }),
);

export const MessageText = styled("div")({
  color: colors.RED,
  fontSize: "15px",
  fontWeight: 600,
  marginTop: "8px",
});

export const ModalBox = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  height: "100vh",
  overflow: "auto",
  backgroundColor: colors.WHITE,
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  padding: "1rem",
  outline: "none",
  direction: "rtl",
});

export const ModalHeader = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: "0.5rem",
});

export const CloseButton = styled(IconButton)({
  color: colors.GREY_7,
});

export const WarningIcon = styled("span")({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "0.5rem",
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  backgroundColor: colors.RED,
  color: colors.WHITE,
  fontSize: "12px",
  fontWeight: "bold",
});
