import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, matchPath, useParams } from "react-router-dom";
import KLFooter from "../../components/KLFooter";
import KLHeader from "../../components/KLHeader";
import { RootState } from "../../store";
import { Wrapper, Container } from "./styles";

const LayoutWrapper: React.FC = () => {
  const location = useLocation();
  const { businessId: paramBusinessId } = useParams<{ businessId: string }>();

  const storeBusinessId = useSelector(
    (state: RootState) => state.businesses.businessId,
  );

  const businessId = paramBusinessId || storeBusinessId;

  const hideHeader = matchPath("/:businessId", location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Wrapper>
      <Container>
        {!hideHeader && <KLHeader businessId={businessId} />}
        <Outlet />
        <KLFooter />
      </Container>
    </Wrapper>
  );
};

export default LayoutWrapper;
