import React, { useState } from "react";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KLScheduleSlot from "../KLScheduleSlot";
import { ServiceWithId, UserType } from "../../constants/types";
import { CloseButton, ModalBox, ModalHeader } from "../../screens/styles";
import KLServiceSelection from "../KLServiceSelection";

interface KLCreateNewSlotModalProps {
  open: boolean;
  handleClose: () => void;
  customerName: string;
  customerPhone: string;
  businessId: string;
  term?: string;
}

const KLCreateNewSlotModal: React.FC<KLCreateNewSlotModalProps> = ({
  open,
  handleClose,
  customerName,
  customerPhone,
  businessId,
  term,
}) => {
  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    null,
  );

  const handleServiceConfirm = (service: ServiceWithId) => {
    setSelectedService(service);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="create-slot-modal-title"
      aria-describedby="create-slot-modal-description"
    >
      <ModalBox>
        <ModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        {!selectedService ? (
          <KLServiceSelection
            businessId={businessId}
            userType={UserType.Business}
            onConfirm={handleServiceConfirm}
            skipFetchServices
          />
        ) : (
          <KLScheduleSlot
            businessId={businessId}
            customerName={customerName}
            customerPhone={customerPhone}
            service={selectedService}
            term={term}
            onClose={handleClose}
          />
        )}
      </ModalBox>
    </Modal>
  );
};

export default KLCreateNewSlotModal;
