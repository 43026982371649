import styled from "styled-components";
import colors from "../../constants/colors";

export const CustomerContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const CustomerInfo = styled("span")({
  fontSize: "1rem",
  fontWeight: 600,
  color: colors.BLACK,
});

export const BookingInfo = styled("span")({
  fontSize: "0.875rem",
  fontWeight: 500,
  color: colors.GREY_7,
});
