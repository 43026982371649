import styled from "styled-components";
import colors from "../../constants/colors";

export const SlotItem = styled("div")<{
  $isLast?: boolean;
}>(({ $isLast }) => ({
  borderBottom: $isLast ? "none" : `1px solid ${colors.GREY_3}`,
  paddingBlock: "0.2rem",
  paddingBottom: $isLast ? "none" : "0.5rem",
  backgroundColor: colors.WHITE,
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));

export const SlotContentWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const SlotInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  alignItems: "flex-start",
});

export const SlotButtons = styled("div")({
  display: "flex",
  gap: "0.5rem",
});

export const CustomerNamePhone = styled("span")({
  fontSize: "1rem",
  fontWeight: 600,
  color: colors.BLACK,
});

export const ServicePriceDescription = styled("span")({
  fontSize: "0.875rem",
  fontWeight: 500,
  color: colors.GREY_7,
});

export const SlotWhen = styled(ServicePriceDescription)({});
