import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../styles";
import { RootState, AppDispatch } from "../../store";
import KLPleaseWait from "../../components/KLPleaseWait";
import KLDashboardServices from "../../components/KLDashboardServices";
import {
  fetchAvailability,
  fetchBookedSlots,
  fetchBusinessIdByPhone,
} from "../../store/duckers/businesses/thunk";
import { fetchServices } from "../../store/duckers/services/thunk";
import { AvailabilityType, UserType } from "../../constants/types";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { verifyToken } from "../../store/duckers/tokens/thunk";
import KLDashboardAvailability from "../../components/KLDashboardAvailability";
import {
  BusinessCategory,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";
import {
  fetchAllCustomers,
  fetchBusiness,
} from "../../store/duckers/customers/thunk";
import KLDashboardUpcomingSlots from "../../components/KLDashboardUpcomingSlots";
import KLDashboardLink from "../../components/KLDashboardLink";
import KLDashboardCustomers from "../../components/KLDashboardCustomers";
import KLDashboardInsights from "../../components/KLDashboardInsights";
import { fetchAvailableInsightsPeriods } from "../../store/duckers/insights/thunk";
import { FlowType } from "../../constants/routes";

const DashboardScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { storedValue: storedToken } = useLocalStorage<StorageKey | null>(
    StorageKeys.VerifiedBusinessOtpToken,
    null,
  );

  const {
    businessId,
    fetchingBusinessId,
    fetchingBookedSlots,
    fetchingAvailability,
    errorBusinessId,
  } = useSelector((state: RootState) => state.businesses);
  const { business, fetchingBusiness, fetchingAllCustomers } = useSelector(
    (state: RootState) => state.customers,
  );
  const fetchingServices = useSelector(
    (state: RootState) => state.services.loading,
  );
  const { loadingPeriods: fetchingAvailableInsightsPeriods } = useSelector(
    (state: RootState) => state.insights,
  );
  const { isTokenVerified, userData: tokenPayload, error: tokenError } = useSelector(
    (state: RootState) => state.tokens,
  );

  const { term, pluralTerm, gender } = useMemo(() => {
    return getCategoryConfigTerms(business?.category as BusinessCategory);
  }, [business?.category]);

  useEffect(() => {
    if (tokenError) {
      localStorage.removeItem(StorageKeys.VerifiedBusinessOtpToken);
      navigate(`/${FlowType.Business}/phone`);
    }
  }, [tokenError, navigate]);

  useEffect(() => {
    if (!businessId && storedToken) {
      dispatch(verifyToken(storedToken));
    }
  }, []);

  useEffect(() => {
    if (!businessId && isTokenVerified && tokenPayload?.phoneNumber) {
      dispatch(fetchBusinessIdByPhone(tokenPayload.phoneNumber));
    }
  }, [businessId, dispatch, isTokenVerified, tokenPayload]);

  useEffect(() => {
    if (!businessId && !fetchingBusinessId && errorBusinessId) {
      navigate("/not-authorized");
    }
  }, [businessId, fetchingBusinessId, errorBusinessId, navigate]);

  useEffect(() => {
    if (businessId) {
      dispatch(fetchBusiness(businessId));
      dispatch(fetchBookedSlots(businessId));
      dispatch(fetchAllCustomers(businessId));
      dispatch(
        fetchAvailability({
          businessId,
          availabilityType: business?.availability as AvailabilityType,
        }),
      );
      dispatch(fetchServices([businessId, UserType.Business]));
      dispatch(fetchAvailableInsightsPeriods(businessId));
    }
  }, [businessId, dispatch]);

  if (
    fetchingBusinessId ||
    fetchingServices ||
    fetchingBusiness ||
    fetchingAllCustomers ||
    fetchingBookedSlots ||
    fetchingAvailability ||
    fetchingAvailableInsightsPeriods
  ) {
    return <KLPleaseWait text="טוענים את העסק שלכם" />;
  }

  if (!businessId) {
    return null;
  }

  return (
    <Container>
      <KLDashboardUpcomingSlots
        businessId={businessId}
        term={term}
        pluralTerm={pluralTerm}
        gender={gender}
      />
      <KLDashboardCustomers
        businessId={businessId}
        term={term}
        pluralTerm={pluralTerm}
        gender={gender}
      />
      <KLDashboardLink businessId={businessId} />
      <KLDashboardAvailability
        businessId={businessId}
        availabilityType={business?.availability as AvailabilityType}
        breakMinutesBetweenSlots={business?.breakMinutesBetweenSlots}
        term={term}
        pluralTerm={pluralTerm}
      />
      <KLDashboardServices businessId={businessId} />
      <KLDashboardInsights businessId={businessId} />
    </Container>
  );
};

export default DashboardScreen;
