import React, { useMemo, useState } from "react";
import KLCollapsibleSection from "../KLCollapsibleSection";
import {
  CopyButton,
  LinkWrapper,
  MessageText,
  StyledLinkContainer,
} from "../../screens/styles";

interface KLDashboardLinkProps {
  businessId: string;
}

const KLDashboardLink: React.FC<KLDashboardLinkProps> = ({ businessId }) => {
  const link = useMemo(() => `https://kavanu.li/${businessId}`, [businessId]);
  const [isLinkSectionExpanded, setIsLinkSectionExpanded] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copyError, setCopyError] = useState<string | null>(null);

  const handleToggleLinkSection = () => {
    setIsLinkSectionExpanded(!isLinkSectionExpanded);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setCopyError(null);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy the link: ", error);
      setCopyError("העתקת הקישור נכשלה. נא נסה שוב.");
    }
  };

  return (
    <KLCollapsibleSection
      title="קישור לשלוח ללקוחות"
      isExpanded={isLinkSectionExpanded}
      onToggle={handleToggleLinkSection}
      secondary
    >
      <StyledLinkContainer>
        <CopyButton
          $copied={copied}
          onClick={handleCopy}
          disabled={copied}
          aria-label={copied ? "קישור הועתק!" : "העתק קישור"}
        >
          {copied ? "הועתק!" : "העתק"}
        </CopyButton>
        <LinkWrapper>{link}</LinkWrapper>
      </StyledLinkContainer>
      {copyError && <MessageText>{copyError}</MessageText>}
    </KLCollapsibleSection>
  );
};

export default KLDashboardLink;
