import apiClient from "../api/apiClient";
import { StorageKeys } from "../constants/localStorage";

class CategoriesService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async fetchCategories(): Promise<string[]> {
    try {
      const response = await apiClient.get<string[]>(
        `${this.baseApiUrl}/api/categories`,
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      const categories = response.data;

      if (!Array.isArray(categories)) {
        throw new Error("Invalid categories response structure");
      }

      return categories;
    } catch (e) {
      throw new Error("Failed to fetch categories");
    }
  }
}

export const categoriesService = new CategoriesService();
