import apiClient from "../api/apiClient";
import { StorageKeys } from "../constants/localStorage";
import { AvailabilityType, Slot, UserType } from "../constants/types";

class SlotsService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async createSlots(
    businessId: string,
    availabilityType: AvailabilityType,
    slots: any[],
    breakMinutesBetweenSlots: string,
  ): Promise<void> {
    try {
      await apiClient.post(
        `${this.baseApiUrl}/api/${businessId}/slots`,
        {
          availabilityType,
          slots,
          breakMinutesBetweenSlots,
        },
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );
    } catch (e) {
      throw new Error("Failed to create slots");
    }
  }

  async fetchSlots(
    businessId: string,
    duration: number,
    userType: UserType,
    serviceId?: number,
  ): Promise<{ availableSlots: Slot[]; unavailableSlots: Slot[] }> {
    try {
      const response = await apiClient.get<{
        availableSlots: Slot[];
        unavailableSlots: Slot[];
      }>(`${this.baseApiUrl}/api/${businessId}/slots`, {
        headers: {
          "x-token-type":
            userType === UserType.Customer
              ? StorageKeys.VerifiedCustomerOtpToken
              : StorageKeys.VerifiedBusinessOtpToken,
        },
        params: {
          duration,
          ...(serviceId !== undefined && { serviceId }),
        },
      });

      const { availableSlots, unavailableSlots } = response.data;

      if (!availableSlots || !unavailableSlots) {
        throw new Error("Invalid response structure");
      }

      return { availableSlots, unavailableSlots };
    } catch (e) {
      throw new Error("Failed to fetch slots");
    }
  }

  async bookSlot(
    businessId: string,
    customerName: string,
    customerPhone: string,
    serviceId: number,
    startTime: string,
    endTime: string,
    tokenType: string,
    userType: string,
  ): Promise<string> {
    try {
      const response = await apiClient.post(
        `${this.baseApiUrl}/api/${businessId}/slots/book`,
        {
          customerName,
          customerPhone,
          serviceId,
          startTime,
          endTime,
          userType,
        },
        {
          headers: {
            "x-token-type": tokenType,
          },
        },
      );
      return response.data.eventId;
    } catch (e) {
      throw new Error("Failed to book slot");
    }
  }

  async changeSlot(
    businessId: string,
    slotId: string,
    startTime: string,
    endTime: string,
  ): Promise<{ id: string; start: string; end: string }> {
    try {
      const response = await apiClient.put<{
        id: string;
        start: string;
        end: string;
      }>(
        `${this.baseApiUrl}/api/${businessId}/slots/${slotId}`,
        {
          startTime,
          endTime,
        },
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );
      return response.data;
    } catch (e: any) {
      console.error("Error changing slot:", e);
      throw new Error(e.response?.data?.message || "Failed to change slot");
    }
  }

  async cancelSlot(
    businessId: string,
    slotId: string,
    skipNotification?: boolean,
  ): Promise<void> {
    try {
      const params = skipNotification
        ? { skipNotification: skipNotification.toString() }
        : {};

      await apiClient.delete(
        `${this.baseApiUrl}/api/${businessId}/slots/${slotId}`,
        {
          headers: {
            "x-token-type": skipNotification
              ? StorageKeys.VerifiedBusinessOtpToken
              : StorageKeys.VerifiedCustomerOtpToken,
          },
          params,
        },
      );
    } catch (e) {
      console.error("Error canceling slot:", e);
      throw new Error("Failed to cancel slot");
    }
  }
}

export const slotsService = new SlotsService();
