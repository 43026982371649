import React from "react";
import { Service } from "../../constants/types";
import KLSelect from "../KLSelect";
import KLTextInput from "../KLTextInput";
import {
  ServicesFieldWrapper,
  ServicesFieldWrapperHorizontal,
  ServicesSingleField,
  CheckboxWrapper,
  CheckboxLabel,
} from "./styles";

interface KLServiceFormProps {
  service: Service;
  index: number;
  onServiceChange: (
    index: number,
    field: keyof Service,
    value: Service[keyof Service],
  ) => void;
  onPriceValidation?: (index: number, isValid: boolean) => void;
  durationOptions: { label: string; value: string }[];
  cancellationOptions: { label: string; value: string }[];
}

const KLServiceForm: React.FC<KLServiceFormProps> = ({
  service,
  index,
  onServiceChange,
  onPriceValidation,
  durationOptions,
  cancellationOptions,
}) => {
  const handleInputChange = <K extends keyof Service>(
    field: K,
    value: Service[K],
  ) => {
    onServiceChange(index, field, value);
    if (field === "price" && onPriceValidation) {
      onPriceValidation(index, service.showWithoutPrice || !!value);
    }
  };

  return (
    <>
      <ServicesFieldWrapper>
        <KLTextInput
          type="text"
          placeholder="מהו השירות אותו תרצו להציע?"
          value={service.description}
          onChange={(e) => handleInputChange("description", e.target.value)}
          required
        />
      </ServicesFieldWrapper>
      <ServicesFieldWrapperHorizontal>
        <ServicesSingleField>
          <KLTextInput
            type="number"
            placeholder="כמה עולה? (ש״ח)"
            value={service.price}
            onChange={(e) => handleInputChange("price", e.target.value)}
            disabled={service.showWithoutPrice}
            onValidationChange={(isValid) =>
              onPriceValidation && onPriceValidation(index, isValid)
            }
            required={!service.showWithoutPrice}
          />
        </ServicesSingleField>
        <ServicesSingleField>
          <CheckboxWrapper>
            <CheckboxLabel>
              <input
                type="checkbox"
                checked={service.showWithoutPrice}
                onChange={(e) =>
                  handleInputChange("showWithoutPrice", e.target.checked)
                }
              />
              הצג שירות זה ללא מחיר
            </CheckboxLabel>
          </CheckboxWrapper>
        </ServicesSingleField>
      </ServicesFieldWrapperHorizontal>
      <ServicesFieldWrapperHorizontal>
        <ServicesSingleField>
          <KLSelect
            value={
              service.duration !== undefined ? service.duration.toString() : ""
            }
            onChange={(e) =>
              handleInputChange(
                "duration",
                e.target.value ? parseInt(e.target.value, 10) : undefined,
              )
            }
            placeholder="כמה זמן לוקח?"
            required
            options={durationOptions}
          />
        </ServicesSingleField>
        <ServicesSingleField>
          <KLSelect
            value={
              service.cancellationPolicy !== undefined
                ? service.cancellationPolicy.toString()
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                "cancellationPolicy",
                e.target.value ? parseInt(e.target.value, 10) : undefined,
              )
            }
            placeholder="עד מתי ביטול?"
            required
            options={cancellationOptions}
          />
        </ServicesSingleField>
      </ServicesFieldWrapperHorizontal>
    </>
  );
};

export default KLServiceForm;
