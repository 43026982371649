import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useStep } from "../../context/StepContext";
import { createSlots } from "../../store/duckers/slots/thunk";
import { AppDispatch, RootState } from "../../store";
import {
  FlowType,
  AvailabilityRouteStep,
  BusinessRouteStep,
} from "../../constants/routes";
import {
  BusinessCategory,
  getCategoryConfigTerms,
} from "../../constants/customers/categoryConfigMap";
import { AvailabilityType } from "../../constants/types";
import KLBusinessOnboardingProgress from "../../components/KLBusinessOnboardingProgress";
import KLAvailabilityForm from "../../components/KLAvailabilityForm";
import useAvailability from "../../hooks/useAvailability";
import { dayMapping } from "../../utils";

interface SlotsSetupScreenProps {
  isOnboarding?: boolean;
}

const SlotsSetupScreen: React.FC<SlotsSetupScreenProps> = ({
  isOnboarding,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { markStepAsCompleted } = useStep();
  const location = useLocation();
  const navigate = useNavigate();

  const { creatingSlots, createSlotsSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const businessId = searchParams.get("businessId") || "";
  const category = searchParams.get("category") || "";
  const availableSlotsString = searchParams.get("availableSlots");

  const initialAvailableSlots = useMemo(() => {
    if (!availableSlotsString) return undefined;
    try {
      const parsed = JSON.parse(decodeURIComponent(availableSlotsString));
      if (parsed.slots && Array.isArray(parsed.slots)) {
        parsed.slots = parsed.slots.map((slot: any) => ({
          ...slot,
          day: dayMapping[slot.day] || slot.day,
        }));
      }
      return parsed;
    } catch {
      return undefined;
    }
  }, [availableSlotsString]);

  const {
    availabilityType,
    breakMinutesBetweenSlots,
    setBreakMinutesBetweenSlots,
    slotsByDay,
    futureDateDayMap,
    currentDateDayMap,
    getSlotOptions,
    handleDayClick,
    isDayExpanded,
    handleAddSlot,
    handleDeleteSlot,
    handleUpdateSlot,
    isFormValid,
    buildSlotsPayload,
  } = useAvailability({
    initialAvailableSlots,
  });

  const { term, pluralTerm } = useMemo(() => {
    return getCategoryConfigTerms(category as BusinessCategory);
  }, [category]);

  useEffect(() => {
    if (createSlotsSucceeded) {
      if (isOnboarding) {
        markStepAsCompleted(FlowType.Business, BusinessRouteStep.SlotsSetup);
        navigate(`/${FlowType.Business}/success?businessId=${businessId}`);
      } else {
        markStepAsCompleted(FlowType.Availability, AvailabilityRouteStep.Slots);
        navigate(
          `/${FlowType.Availability}/success?businessId=${businessId}&availabilityType=${availabilityType}`,
        );
      }
    }
  }, [
    createSlotsSucceeded,
    businessId,
    isOnboarding,
    availabilityType,
    markStepAsCompleted,
    navigate,
  ]);

  const handleSubmit = () => {
    if (!isFormValid) {
      alert("Please fill in all required fields correctly.");
      return;
    }
    if (!businessId || !availabilityType || !breakMinutesBetweenSlots) {
      return;
    }

    const convertedSlots = buildSlotsPayload();

    dispatch(
      createSlots({
        businessId,
        availabilityType,
        slots: convertedSlots,
        breakMinutesBetweenSlots,
      }),
    );
  };

  return (
    <>
      {isOnboarding && <KLBusinessOnboardingProgress currentStep={4} />}
      <KLAvailabilityForm
        title="מתי תרצו לקבל לקוחות?"
        subtitle={
          availabilityType === AvailabilityType.Weekly
            ? "אלו הימים והשעות שזיהינו שבהם אתם פנויים בשבוע הקרוב"
            : availabilityType === AvailabilityType.BiWeekly
              ? "אלו הימים והשעות שזיהינו שבהם אתם פנויים בשבועיים הקרובים"
              : availabilityType === AvailabilityType.WeeklyRecurring
                ? "אלו הימים והשעות שזיהינו שבהם אתם פנויים כל שבוע"
                : undefined
        }
        infoText="אפשר להוסיף, למחוק ולשנות!"
        creatingSlots={creatingSlots}
        availabilityType={availabilityType}
        breakMinutesBetweenSlots={breakMinutesBetweenSlots}
        setBreakMinutesBetweenSlots={setBreakMinutesBetweenSlots}
        slotsByDay={slotsByDay}
        futureDateDayMap={futureDateDayMap}
        currentDateDayMap={currentDateDayMap}
        getSlotOptions={getSlotOptions}
        handleDayClick={handleDayClick}
        isDayExpanded={isDayExpanded}
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleUpdateSlot={handleUpdateSlot}
        isFormValid={isFormValid}
        submitTitle="סיימנו"
        onSubmit={handleSubmit}
        term={term}
        pluralTerm={pluralTerm}
      />
    </>
  );
};

export default SlotsSetupScreen;
