import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AvailabilityDetail,
  CustomerSlot,
  KLSlot,
  Service,
  ServiceWithId,
  UserType,
} from "../../../constants/types";
import {
  fetchAvailability,
  fetchBusinessIdByPhone,
  fetchBookedSlots,
  updateAvailability,
} from "./thunk";
import { bookSlot, changeSlot } from "../slots/thunk";

interface BusinessState {
  businessId: string | null;
  fetchingBusinessId: boolean;
  fetchBusinessIdSucceeded: boolean;
  errorBusinessId: string | null;
  ownerName: string;
  businessName: string;
  phone: string;
  email: string;
  category: string | null;
  services: Service[];
  availability: AvailabilityDetail[];
  fetchingAvailability: boolean;
  errorAvailability: string | null;
  updatingAvailability: boolean;
  updateAvailabilitySucceeded: boolean;
  errorUpdateAvailability: string | null;
  bookedSlots: KLSlot[];
  fetchingBookedSlots: boolean;
  fetchedBookedSlotsSucceeded: boolean;
  errorFetchedBookedSlots: string | null;
}

const initialState: BusinessState = {
  businessId: null,
  fetchingBusinessId: false,
  fetchBusinessIdSucceeded: false,
  errorBusinessId: null,
  ownerName: "",
  businessName: "",
  phone: "",
  email: "",
  category: null,
  services: [],
  availability: [],
  fetchingAvailability: false,
  errorAvailability: null,
  updatingAvailability: false,
  updateAvailabilitySucceeded: false,
  errorUpdateAvailability: null,
  bookedSlots: [],
  fetchingBookedSlots: false,
  fetchedBookedSlotsSucceeded: false,
  errorFetchedBookedSlots: null,
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessName: (state, action: PayloadAction<string>) => {
      state.businessName = action.payload;
    },
    setOwnerName: (state, action: PayloadAction<string>) => {
      state.ownerName = action.payload;
    },
    setBusinessPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setBusinessEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setBusinessCategory: (state, action: PayloadAction<string | null>) => {
      state.category = action.payload;
    },
    setBusinessServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    },
    resetUpdateAvailabilitySucceeded: (state) => {
      state.updateAvailabilitySucceeded = false;
    },
    updateBookedSlot: (
      state,
      action: PayloadAction<{ id: string; start: string; end: string }>,
    ) => {
      const { id, start, end } = action.payload;
      const index = state.bookedSlots.findIndex((slot) => slot.id === id);
      if (index !== -1) {
        state.bookedSlots[index].start = start;
        state.bookedSlots[index].end = end;
      } else {
        console.warn(
          `Attempted to update a non-existent booked slot with id: ${id}`,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailability.pending, (state) => {
        state.fetchingAvailability = true;
        state.errorAvailability = null;
      })
      .addCase(
        fetchAvailability.fulfilled,
        (state, action: PayloadAction<AvailabilityDetail[]>) => {
          state.fetchingAvailability = false;
          state.availability = action.payload;
        },
      )
      .addCase(fetchAvailability.rejected, (state, action) => {
        state.fetchingAvailability = false;
        state.errorAvailability =
          action.payload || "Failed to fetch availability";
      });
    builder
      .addCase(fetchBusinessIdByPhone.pending, (state) => {
        state.fetchingBusinessId = true;
        state.errorBusinessId = null;
        state.businessId = null;
        state.fetchBusinessIdSucceeded = false;
      })
      .addCase(
        fetchBusinessIdByPhone.fulfilled,
        (state, action: PayloadAction<string | null>) => {
          state.fetchingBusinessId = false;
          state.businessId = action.payload;
          state.fetchBusinessIdSucceeded = true;
        },
      )
      .addCase(fetchBusinessIdByPhone.rejected, (state, action) => {
        state.fetchingBusinessId = false;
        state.errorBusinessId =
          action.payload || "Failed to fetch business ID by phone";
        state.businessId = null;
        state.fetchBusinessIdSucceeded = false;
      });
    builder
      .addCase(updateAvailability.pending, (state) => {
        state.updatingAvailability = true;
        state.errorUpdateAvailability = null;
      })
      .addCase(
        updateAvailability.fulfilled,
        (state, action: PayloadAction<AvailabilityDetail[]>) => {
          state.updatingAvailability = false;
          state.updateAvailabilitySucceeded = true;
          state.availability = action.payload;
        },
      )
      .addCase(updateAvailability.rejected, (state, action) => {
        state.updatingAvailability = false;
        state.errorUpdateAvailability =
          action.payload || "Failed to update availability";
      });
    builder
      .addCase(fetchBookedSlots.pending, (state) => {
        state.fetchingBookedSlots = true;
        state.errorFetchedBookedSlots = null;
      })
      .addCase(
        fetchBookedSlots.fulfilled,
        (state, action: PayloadAction<KLSlot[]>) => {
          state.fetchingBookedSlots = false;
          state.bookedSlots = action.payload;
        },
      )
      .addCase(fetchBookedSlots.rejected, (state, action) => {
        state.fetchingBookedSlots = false;
        state.errorFetchedBookedSlots =
          action.payload || "Failed to fetch booked slots";
      });
    builder.addCase(
      bookSlot.fulfilled,
      (
        state,
        action: PayloadAction<
          { customerId?: string; latestBooking: CustomerSlot },
          string,
          {
            arg: {
              customerId?: string;
              businessId: string;
              customerName: string;
              customerPhone: string;
              service: ServiceWithId;
              startTime: string;
              endTime: string;
              userType: UserType;
            };
          }
        >,
      ) => {
        const { latestBooking } = action.payload;
        const klSlot = {
          id: latestBooking.id,
          name: action.meta.arg.customerName,
          phone: action.meta.arg.customerPhone,
          service: latestBooking.service,
          price: latestBooking.price,
          cancellationPolicy: latestBooking.cancellationPolicy,
          start: latestBooking.start,
          end: latestBooking.end,
        };
        state.bookedSlots.push(klSlot);
      },
    );
    builder.addCase(
      changeSlot.fulfilled,
      (
        state,
        action: PayloadAction<
          {
            updatedSlot: { id: string; start: string; end: string };
            customerPhone?: string;
          },
          string,
          { arg: { customerPhone?: string } }
        >,
      ) => {
        if (!action.meta.arg.customerPhone) return;
        const { updatedSlot } = action.payload;
        const slotIndex = state.bookedSlots.findIndex(
          (slot) => slot.id === updatedSlot.id,
        );
        if (slotIndex !== -1) {
          state.bookedSlots[slotIndex].start = updatedSlot.start;
          state.bookedSlots[slotIndex].end = updatedSlot.end;
        }
      },
    );
  },
});

export const {
  setBusinessName,
  setOwnerName,
  setBusinessPhone,
  setBusinessEmail,
  setBusinessCategory,
  setBusinessServices,
  resetUpdateAvailabilitySucceeded,
  updateBookedSlot,
} = businessSlice.actions;

export default businessSlice.reducer;
