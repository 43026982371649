import styled from "styled-components";
import colors from "../../constants/colors";

export const SelectionWrapper = styled("div")({
  marginBottom: "2rem",
});

export const MonthSelection = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
});

export const DaySelection = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "0.5rem",
});

export const TimeSlots = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "0.5rem",
});

export const ConfirmationSection = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const SlotSummary = styled("div")({
  backgroundColor: colors.PURPLE_LIGHTER,
  borderRadius: "10px",
  padding: "1rem",
  maxWidth: "320px",
  color: colors.BLACK,
  fontSize: "16px",
  border: `0.5px solid ${colors.PURPLE}`,
  marginBottom: "1.5rem",
  fontFamily: "'Roboto', sans-serif",
  lineHeight: "1.5",
});
