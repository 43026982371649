import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import Chip from "@mui/material/Chip";
import ListIcon from "@mui/icons-material/List";
import { useDispatch, useSelector } from "react-redux";
import KLButton from "../../components/KLButton";
import KLPleaseWait from "../../components/KLPleaseWait";
import { fetchServices } from "../../store/duckers/services/thunk";
import { setCustomerService } from "../../store/duckers/customers/slice";
import { AppDispatch, RootState } from "../../store";
import {
  ChipServiceDescription,
  chipSx,
  ChipWrapper,
  Container,
  IconContainer,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../../screens/styles";
import { ServiceWithId, UserType } from "../../constants/types";
import { cancellationOptions, getDurationLabel } from "../../utils";
import { ServicesList } from "./styles";

interface KLServiceSelectionProps {
  businessId: string;
  userType: UserType;
  initialSelectedService?: ServiceWithId | null;
  skipFetchServices?: boolean;
  onConfirm: (service: ServiceWithId) => void;
}

const getCancellationLabel = (cancellationPolicy: number | undefined) => {
  if (cancellationPolicy === undefined) return null;
  const option = cancellationOptions.find(
    (opt) => opt.value === cancellationPolicy,
  );
  return option ? `ביטול ${option.label}` : null;
};

const KLServiceSelection: React.FC<KLServiceSelectionProps> = ({
  businessId,
  userType,
  initialSelectedService = null,
  skipFetchServices,
  onConfirm,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, services } = useSelector(
    (state: RootState) => state.services,
  );
  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    initialSelectedService,
  );

  useEffect(() => {
    if (!businessId || skipFetchServices) {
      return;
    }
    dispatch(fetchServices([businessId, userType]));
  }, [businessId, dispatch, userType]);

  const handleServiceSelect = (service: ServiceWithId) => {
    setSelectedService((prevSelected) =>
      isEqual(prevSelected, service) ? null : service,
    );
  };

  const handleConfirm = () => {
    if (!selectedService) return;
    // Optionally update Redux with the selected service.
    dispatch(setCustomerService(selectedService));
    onConfirm(selectedService);
  };

  if (loading) {
    return <KLPleaseWait text="בודקים אילו שירותים יש לעסק להציע" />;
  }

  return (
    <Container>
      {services.length === 0 ? (
        <>
          <Title>אין דרך קלה להגיד את זה אבל...</Title>
          <Subtitle>משהו לא עובד :(</Subtitle>
          <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
        </>
      ) : (
        <>
          <TextIconContainer>
            <IconContainer>
              <ListIcon fontSize="large" />
            </IconContainer>
            <Title>מה בא לכם לקבוע?</Title>
          </TextIconContainer>
          <InfoText>כאן בוחרים את סוג השירות שרוצים לקבל מהעסק</InfoText>
          <ServicesList>
            {services.map((service) => (
              <ChipWrapper key={service.id}>
                <Chip
                  sx={chipSx(isEqual(selectedService, service))}
                  label={
                    <>
                      <ChipServiceDescription>
                        {service.description}
                      </ChipServiceDescription>
                      {!service.showWithoutPrice && (
                        <span>{service.price} ש״ח | </span>
                      )}
                      {service.duration && getDurationLabel(service.duration)}
                      {service.cancellationPolicy && (
                        <>
                          {" | "}
                          {getCancellationLabel(service.cancellationPolicy)}
                        </>
                      )}
                    </>
                  }
                  color={
                    isEqual(selectedService, service) ? "primary" : "default"
                  }
                  onClick={() => handleServiceSelect(service)}
                  style={{ padding: "1.25rem", width: "100%" }}
                />
              </ChipWrapper>
            ))}
          </ServicesList>
          <KLButton
            title="ממשיכים"
            onClick={handleConfirm}
            disabled={!selectedService}
          />
        </>
      )}
    </Container>
  );
};

export default KLServiceSelection;
