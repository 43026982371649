import { createSlice } from "@reduxjs/toolkit";
import { sendOtpRequest, verifyOtpRequest } from "./thunk";

interface OtpState {
  sendingOtpRequest: boolean;
  sendOtpRequestSucceeded: boolean;
  verifyingOtpRequest: boolean;
  verifyOtpRequestSucceeded: boolean;
  error: string | null;
  token: string | null;
}

const initialState: OtpState = {
  sendingOtpRequest: false,
  sendOtpRequestSucceeded: false,
  verifyingOtpRequest: false,
  verifyOtpRequestSucceeded: false,
  error: null,
  token: null,
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendOtpRequest.pending, (state) => {
        state.sendingOtpRequest = true;
        state.sendOtpRequestSucceeded = false;
        state.error = null;
      })
      .addCase(sendOtpRequest.fulfilled, (state) => {
        state.sendingOtpRequest = false;
        state.sendOtpRequestSucceeded = true;
      })
      .addCase(sendOtpRequest.rejected, (state, action) => {
        state.sendingOtpRequest = false;
        state.sendOtpRequestSucceeded = false;
        state.error = action.payload as string;
      })
      .addCase(verifyOtpRequest.pending, (state) => {
        state.verifyingOtpRequest = true;
        state.verifyOtpRequestSucceeded = false;
        state.error = null;
      })
      .addCase(verifyOtpRequest.fulfilled, (state, action) => {
        state.verifyingOtpRequest = false;
        state.verifyOtpRequestSucceeded = true;
        state.token = action.payload || null;
      })
      .addCase(verifyOtpRequest.rejected, (state, action) => {
        state.verifyingOtpRequest = false;
        state.verifyOtpRequestSucceeded = false;
        state.error = action.payload as string;
        state.token = null;
      });
  },
});

export default otpSlice.reducer;
