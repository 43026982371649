export const StorageKeys = {
  BusinessId: "businessId",
  SelectedService: "selectedService",
  CurrentStep: "currentStep",
  MaxStepReached: "maxStepReached",
  IsRedirecting: "isRedirecting",
  CustomerName: "customerName",
  CustomerPhone: "customerPhone",
  BusinessPhone: "businessPhone",
  CompletedSteps: "completedSteps",
  CustomerCompletedSteps: "customerCompletedSteps",
  BusinessCompletedSteps: "businessCompletedSteps",
  AvailabilityCompletedSteps: "availabilityCompletedSteps",
  VerifiedBusinessOtpToken: "verifiedBusinessOtpToken",
  VerifiedCustomerOtpToken: "verifiedCustomerOtpToken",
} as const;

export type StorageKey = (typeof StorageKeys)[keyof typeof StorageKeys];
