import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainButtons, MainTitle } from "./styles";
import KLButton from "../../components/KLButton";
import KLCalendarGearIcon from "../../components/icons/KLCalendarConfirmedIcon";
import KLBusinessIcon from "../../components/icons/KLBusinessIcon";
import {
  ButtonWrapper,
  Container,
  Content,
  ContentWrapper,
  Subtitle,
} from "../styles";
import KLReminderIcon from "../../components/icons/KLReminderIcon";
import { useStep } from "../../context/StepContext";
import { BusinessRouteStep, FlowType } from "../../constants/routes";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { verifyToken } from "../../store/duckers/tokens/thunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchBusinessIdByPhone } from "../../store/duckers/businesses/thunk";

const MainScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { resetSteps } = useStep();
  const [handleStartOnboardingClicked, setHandleStartOnboardingClicked] =
    useState<boolean>(false);
  const [handleSignInClicked, setHandleSignInClicked] =
    useState<boolean>(false);

  const { storedValue: storedToken } = useLocalStorage<StorageKey | null>(
    StorageKeys.VerifiedBusinessOtpToken,
    null,
  );

  const {
    verifyingToken,
    userData: tokenPayload,
    isTokenVerified,
    error: tokenError,
  } = useSelector((state: RootState) => state.tokens);

  const {
    businessId: fetchedBusinessId,
    fetchingBusinessId,
    fetchBusinessIdSucceeded,
  } = useSelector((state: RootState) => state.businesses);

  const handleToken = () => {
    if (storedToken) {
      dispatch(verifyToken(storedToken));
    } else {
      navigate(`/${FlowType.Business}/phone`);
    }
  };

  const handleStartOnboarding = () => {
    setHandleStartOnboardingClicked(true);
    handleToken();
  };

  const handleSignIn = () => {
    setHandleSignInClicked(true);
    handleToken();
  };

  useEffect(() => {
    if (!handleStartOnboardingClicked && !handleSignInClicked) {
      return;
    }
    if (isTokenVerified && tokenPayload?.phoneNumber) {
      markStepAsCompleted(FlowType.Business, BusinessRouteStep.VerifyOtp);
      if (handleStartOnboardingClicked) {
        navigate(`/${FlowType.Business}/info`);
      } else if (handleSignInClicked) {
        dispatch(fetchBusinessIdByPhone(tokenPayload.phoneNumber));
      }
    } else if (tokenError) {
      navigate(`/${FlowType.Business}/phone`);
    }
  }, [
    isTokenVerified,
    tokenError,
    handleStartOnboardingClicked,
    tokenPayload,
    navigate,
    handleSignInClicked,
  ]);

  useEffect(() => {
    if (fetchBusinessIdSucceeded && fetchedBusinessId) {
      markStepAsCompleted(FlowType.Business, BusinessRouteStep.VerifyOtp);
      navigate(`/${FlowType.Business}/dashboard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBusinessIdSucceeded]);

  useEffect(() => {
    resetSteps(FlowType.Business);
  }, []);

  const loading = useMemo<boolean>(
    () => verifyingToken || fetchingBusinessId,
    [verifyingToken, fetchingBusinessId],
  );

  return (
    <Container>
      <MainTitle>קבענו לי!</MainTitle>
      <Subtitle>
        תנו לנו לדאוג לפגישות,
        <br /> ואתם תדאגו לעסק!
      </Subtitle>
      <ContentWrapper>
        <KLCalendarGearIcon />
        <Content>
          אנו מנהלים את לוח הזמנים של העסק שלכם באופן אוטומטי בעזרת חיבור ליומן
          שלכם.
        </Content>
      </ContentWrapper>
      <ContentWrapper>
        <KLReminderIcon />
        <Content>שליחת תזכורת לפני? גם זה עלינו!</Content>
      </ContentWrapper>
      <ContentWrapper>
        <KLBusinessIcon />
        <Content>
          כך אתם יכולים להתמקד במה שחשוב באמת - <br />
          העסק שלכם!
        </Content>
      </ContentWrapper>
      <MainButtons>
        <ButtonWrapper>
          <KLButton
            title="הרשמה"
            onClick={handleStartOnboarding}
            loading={loading}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <KLButton
            title="כניסה"
            onClick={handleSignIn}
            loading={loading}
            secondary
            noMarginTop
          />
        </ButtonWrapper>
      </MainButtons>
    </Container>
  );
};

export default MainScreen;
