import React from "react";
import {
  AppName,
  BusinessImage,
  BusinessInfo,
  BusinessName,
  HeaderContainer,
  Logo,
  LogoLink,
} from "./styles";
import logo from "../../assets/logo.png";
import { categoryConfigMap } from "../../constants/customers/categoryConfigMap";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useBusinessImage from "../../hooks/useBusinessImage";

interface KLHeaderProps {
  businessId?: string | null;
}

const KLHeader: React.FC<KLHeaderProps> = ({ businessId }) => {
  const { business } = useSelector((state: RootState) => state.customers);
  const categoryConfig = business?.category
    ? categoryConfigMap[business.category as keyof typeof categoryConfigMap]
    : undefined;

  const imageSrc = useBusinessImage({ businessId, categoryConfig });

  return (
    <HeaderContainer>
      {businessId ? (
        <BusinessInfo>
          <BusinessImage src={imageSrc} alt="Business" />
          <BusinessName>{business?.businessName}</BusinessName>
        </BusinessInfo>
      ) : (
        <LogoLink to="/">
          <Logo src={logo} alt="Company Logo" />
          <AppName>kavanu.li</AppName>
        </LogoLink>
      )}
    </HeaderContainer>
  );
};

export default KLHeader;
