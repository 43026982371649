import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KLSlot, UserType } from "../../constants/types";
import { AppDispatch, RootState } from "../../store";
import KLPleaseWait from "../KLPleaseWait";
import KLAvailableSlotSelect from "../KLAvailableSlotSelect";
import { changeSlot, fetchSlots } from "../../store/duckers/slots/thunk";
import { DateTime } from "luxon";
import { resetChangeSlotSucceeded } from "../../store/duckers/slots/slice";

interface KLChangeUpcomingSlotProps {
  businessId: string;
  slot: KLSlot;
  term?: string;
  onClose: () => void;
}

const KLChangeUpcomingSlot: React.FC<KLChangeUpcomingSlotProps> = ({
  businessId,
  slot,
  term,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const { changingSlot, changeSlotSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );

  useEffect(() => {
    if (changeSlotSucceeded) {
      dispatch(resetChangeSlotSucceeded());
      onClose();
    }
  }, [changeSlotSucceeded, onClose]);

  const handleSelect = (selectedTime: {
    month: string;
    day: string;
    dayLabel: string;
    time: string;
    displayTime: string;
  }) => {
    const { time } = selectedTime;
    if (businessId) {
      const [newStartTime, newEndTime] = time.split(" - ");
      dispatch(
        changeSlot({
          businessId,
          slotId: slot.id,
          startTime: newStartTime,
          endTime: newEndTime,
          customerPhone: slot.phone,
        }),
      );
    }
  };

  const handleError = (errorMessage: string) => {
    console.error("Error changing slot:", errorMessage);
  };
  const duration = useMemo((): number => {
    const startDT = DateTime.fromISO(slot.start, { zone: "Asia/Jerusalem" });
    const endDT = DateTime.fromISO(slot.end, { zone: "Asia/Jerusalem" });

    if (!startDT.isValid || !endDT.isValid) {
      console.error("Invalid date format provided to duration calculation.");
      return 0;
    }

    return endDT.diff(startDT, "minutes").minutes;
  }, [slot.start, slot.end]);

  useEffect(() => {
    if (businessId && duration) {
      dispatch(
        fetchSlots({ businessId, duration, userType: UserType.Business }),
      );
    }
  }, [businessId, duration, dispatch]);

  return (
    <div>
      {changingSlot && <KLPleaseWait text={`מעדכנים ${term}...`} />}
      {!changingSlot && (
        <KLAvailableSlotSelect
          onSelect={handleSelect}
          onError={handleError}
          title={`עדכון מועד ${term}`}
          subtitle={`לאיזה חודש להזיז את ה${term}?`}
          confirmationButtonTitle="הולכים על זה"
        />
      )}
    </div>
  );
};

export default KLChangeUpcomingSlot;
