import {
  AvailabilityDetail,
  AvailabilityType,
  Business,
  BusinessIdResponse,
  KLSlot,
} from "../constants/types";
import apiClient from "../api/apiClient";
import { StorageKeys } from "../constants/localStorage";
import { DateTime } from "luxon";

class BusinessService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async fetchBusiness(businessId: string): Promise<Business> {
    try {
      const response = await apiClient.get<Business>(
        `${this.baseApiUrl}/api/${businessId}`,
      );
      const business = response.data;
      if (!business || typeof business !== "object") {
        throw new Error("Invalid business response structure");
      }
      return business;
    } catch (e) {
      throw new Error("Failed to fetch business");
    }
  }

  async fetchAvailability(
    businessId: string,
    availabilityType: AvailabilityType,
  ): Promise<AvailabilityDetail[]> {
    try {
      const response = await apiClient.get<AvailabilityDetail[]>(
        `${this.baseApiUrl}/api/${businessId}/availability`,
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );
      const availability = response.data;
      if (!Array.isArray(availability)) {
        throw new Error("Invalid availability response structure");
      }
      if (
        availabilityType === AvailabilityType.Weekly ||
        availabilityType === AvailabilityType.BiWeekly
      ) {
        const todayISO = DateTime.now().setZone("Asia/Jerusalem").toISODate()!;
        return availability.filter(
          (slot) => slot.date && slot.date >= todayISO,
        );
      } else {
        return availability;
      }
    } catch (error: any) {
      const message =
        error.response?.data?.error || "Failed to fetch availability";
      throw new Error(message);
    }
  }

  async updateAvailability(
    businessId: string,
    updatedAvailabilitySlots: AvailabilityDetail[],
  ): Promise<AvailabilityDetail[]> {
    try {
      const response = await apiClient.put<AvailabilityDetail[]>(
        `${this.baseApiUrl}/api/${businessId}/availability`,
        updatedAvailabilitySlots,
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );
      const updatedAvailability = response.data;
      if (!Array.isArray(updatedAvailability)) {
        throw new Error("Invalid updated availability response structure");
      }
      return updatedAvailability;
    } catch (error: any) {
      const message =
        error.response?.data?.error || "Failed to update availability";
      console.error("Service Update Availability Error:", message);
      throw new Error(message);
    }
  }

  async getBusinessIdByPhone(phone: string): Promise<string | null> {
    try {
      const response = await apiClient.get<BusinessIdResponse>(
        `${this.baseApiUrl}/api/by-phone/${encodeURIComponent(phone)}`,
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      const data = response.data;

      if (data && "businessId" in data) {
        if (typeof data.businessId === "string") {
          return data.businessId;
        }

        if (data.businessId === null) {
          return null;
        }
      }
      throw new Error("Invalid response structure");
    } catch (error: any) {
      console.error("Error in getBusinessIdByPhone service:", error);

      if (error.response) {
        const status = error.response.status;
        const message =
          error.response.data?.error || "Failed to fetch business ID by phone";

        if (status === 400) {
          throw new Error("Invalid phone number format");
        }

        throw new Error(message);
      } else if (error.request) {
        throw new Error("No response from server");
      } else {
        throw new Error(
          error.message || "Failed to fetch business ID by phone",
        );
      }
    }
  }

  async fetchBookedSlots(businessId: string): Promise<KLSlot[]> {
    const now = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);

    const timeMin = now.toISOString();
    const timeMax = threeMonthsLater.toISOString();

    try {
      const response = await apiClient.get<KLSlot[]>(
        `${this.baseApiUrl}/api/${businessId}/booked-slots`,
        {
          headers: {
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
          params: {
            timeMin,
            timeMax,
          },
        },
      );

      const bookedSlots = response.data;

      if (!Array.isArray(bookedSlots)) {
        throw new Error("Invalid booked slots response structure");
      }

      return bookedSlots;
    } catch (error: any) {
      const message =
        error.response?.data?.error || "Failed to fetch booked slots";
      console.error("Service Fetch Booked Slots Error:", message);
      throw new Error(message);
    }
  }
}

export const businessService = new BusinessService();
