import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLCustomerItem from "../KLCustomerItem";
import KLAddNewCustomerModal from "../KLAddNewCustomerModal";
import { BusinessCategoryGender } from "../../constants/customers/categoryConfigMap";
import {
  SearchWrapper,
  SearchContainer,
  SearchInput,
  StyledSearchIcon,
} from "./styles";
import KLButton from "../KLButton";

interface KLDashboardCustomersProps {
  businessId: string;
  term: string;
  pluralTerm: string;
  gender: BusinessCategoryGender;
  searchPlaceholder?: string;
}

const KLDashboardCustomers: React.FC<KLDashboardCustomersProps> = ({
  businessId,
  term,
  pluralTerm,
  gender,
  searchPlaceholder,
}) => {
  const { allCustomers } = useSelector((state: RootState) => state.customers);
  const [isCustomersSectionExpanded, setIsCustomersSectionExpanded] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const enableSearch = useMemo(
    (): boolean => allCustomers.length > 1,
    [allCustomers],
  );

  const handleCustomersSectionToggle = () => {
    setIsCustomersSectionExpanded((prev) => !prev);
  };

  const handleOpenAddModal = () => setIsAddModalOpen(true);
  const handleCloseAddModal = () => setIsAddModalOpen(false);

  const filteredCustomers = enableSearch
    ? allCustomers.filter((customer) =>
        customer.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : allCustomers;

  return (
    <>
      <KLCollapsibleSection
        title="הלקוחות שלי"
        isExpanded={isCustomersSectionExpanded}
        onToggle={handleCustomersSectionToggle}
        count={allCustomers.length}
        secondary
      >
        <KLButton
          title="הוסיפו לקוח חדש"
          onClick={handleOpenAddModal}
          noMarginTop
        />
        {enableSearch && (
          <SearchWrapper>
            <SearchContainer>
              <StyledSearchIcon />
              <SearchInput
                type="text"
                placeholder={searchPlaceholder || "חפשו לקוח קיים"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchContainer>
          </SearchWrapper>
        )}
        {filteredCustomers.length === 0 ? (
          <p>אין כרגע לקוחות להצגה.</p>
        ) : (
          filteredCustomers.map((customer) => (
            <KLCustomerItem
              key={customer.id}
              customer={customer}
              term={term}
              pluralTerm={pluralTerm}
              gender={gender}
            />
          ))
        )}
      </KLCollapsibleSection>
      <KLAddNewCustomerModal
        open={isAddModalOpen}
        handleClose={handleCloseAddModal}
        businessId={businessId}
      />
    </>
  );
};

export default KLDashboardCustomers;
