import { createAsyncThunk } from "@reduxjs/toolkit";
import { otpService } from "../../../services/otpService";
import { UserType } from "../../../constants/types";

interface SendOtpArgs {
  phoneNumber: string;
  userType: UserType;
}

interface VerifyOtpArgs extends SendOtpArgs {
  otp: string;
}

export const sendOtpRequest = createAsyncThunk<
  void,
  SendOtpArgs,
  { rejectValue: string }
>(
  "otp/sendOtpRequest",
  async ({ phoneNumber, userType }, { rejectWithValue }) => {
    try {
      await otpService.sendOtp(phoneNumber, userType);
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const verifyOtpRequest = createAsyncThunk<
  string | undefined,
  VerifyOtpArgs,
  { rejectValue: string }
>(
  "otp/verifyOtpRequest",
  async ({ phoneNumber, userType, otp }, { rejectWithValue }) => {
    try {
      const token = await otpService.verifyOtp(phoneNumber, userType, otp);
      return token;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
