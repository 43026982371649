import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import KLPleaseWait from "../KLPleaseWait";
import KLAvailableSlotSelect from "../KLAvailableSlotSelect";
import { bookSlot, fetchSlots } from "../../store/duckers/slots/thunk";
import { ServiceWithId, UserType } from "../../constants/types";
import { resetBookSlotSucceeded } from "../../store/duckers/slots/slice";

interface KLScheduleSlotProps {
  businessId: string;
  customerName: string;
  customerPhone: string;
  service: ServiceWithId;
  term?: string;
  onClose: () => void;
}

const KLScheduleSlot: React.FC<KLScheduleSlotProps> = ({
  businessId,
  customerName,
  customerPhone,
  service,
  term,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { bookingSlot, bookSlotSucceeded } = useSelector(
    (state: RootState) => state.slots,
  );

  useEffect(() => {
    if (bookSlotSucceeded) {
      dispatch(resetBookSlotSucceeded());
      onClose();
    }
  }, [bookSlotSucceeded, onClose, dispatch]);

  const handleSelect = (selectedTime: {
    month: string;
    day: string;
    dayLabel: string;
    time: string;
    displayTime: string;
  }) => {
    const { time } = selectedTime;
    if (businessId) {
      const [newStartTime, newEndTime] = time.split(" - ");
      dispatch(
        bookSlot({
          businessId,
          customerName,
          customerPhone,
          startTime: newStartTime,
          endTime: newEndTime,
          service,
          userType: UserType.Business,
        }),
      );
    }
  };

  const handleError = (errorMessage: string) => {
    console.error("Error creating slot:", errorMessage);
  };

  useEffect(() => {
    if (businessId) {
      dispatch(
        fetchSlots({
          businessId,
          duration: service.duration as number,
          serviceId: service.id,
          userType: UserType.Business,
        }),
      );
    }
  }, [businessId, service.duration, dispatch]);

  return (
    <div>
      {bookingSlot && <KLPleaseWait text={`קובעים ${term}...`} />}
      {!bookingSlot && (
        <KLAvailableSlotSelect
          onSelect={handleSelect}
          onError={handleError}
          title={`בחר מועד ${term}`}
          subtitle={`לאיזה חודש לקבוע את ה${term}?`}
          confirmationButtonTitle="הולכים על זה"
          serviceDescription={service.description}
          customerName={customerName}
          isBusinessNew
        />
      )}
    </div>
  );
};

export default KLScheduleSlot;
