import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import KLHeader from "../../components/KLHeader";
import KLButton from "../../components/KLButton";
import { ButtonWrapper, Container, InfoText, Subtitle, Title } from "../styles";
import { StorageKeys } from "../../constants/localStorage";
import useLocalStorage from "../../hooks/useLocalStorage";

const NotAuthorizedScreen: React.FC = () => {
  const navigate = useNavigate();

  const { storedValue: businessId } = useLocalStorage<string | null>(
    StorageKeys.BusinessId,
    null,
  );

  const handleGoHome = () => {
    if (businessId) {
      navigate(`/${businessId}`);
    } else {
      navigate("/");
    }
  };

  const buttonTitle = useMemo(
    (): string => (businessId ? "חזרה לדף העסק" : "חזרה לדף הבית"),
    [businessId],
  );

  return (
    <Container>
      <KLHeader />
      <Title>שלום! אפשר לעזור?</Title>
      <Subtitle>לא בטוח שלכאן התכוונתם להגיע...</Subtitle>
      <InfoText>אולי כדאי שניקח צעד אחורה?</InfoText>
      <ButtonWrapper>
        <KLButton title={buttonTitle} onClick={handleGoHome} />
      </ButtonWrapper>
    </Container>
  );
};

export default NotAuthorizedScreen;
