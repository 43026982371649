import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  IconContainer,
  InfoText,
  TextIconContainer,
  Title,
} from "../styles";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import { EmojiPeople } from "@mui/icons-material";
import { sendOtpRequest } from "../../store/duckers/otp/thunk";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { AuthenticationScreenProps, UserType } from "../../constants/types";
import { validatePhone } from "../../utils";

const PhoneInputScreen: React.FC<AuthenticationScreenProps> = ({
  userType,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId } = useParams<{ businessId: string }>();

  const storageKey = useMemo(
    (): StorageKey =>
      userType === UserType.Customer
        ? StorageKeys.CustomerPhone
        : StorageKeys.BusinessPhone,
    [userType],
  );

  const { storedValue: storedPhone, setValue: setStoredPhone } =
    useLocalStorage<string>(storageKey, "");

  const [phone, setPhone] = useState<string>(storedPhone);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);

  const { sendingOtpRequest, sendOtpRequestSucceeded } = useSelector(
    (state: RootState) => state.otp,
  );

  const isFormValid = useMemo(() => {
    return phone.trim() !== "" && isPhoneValid;
  }, [phone, isPhoneValid]);

  const handleConfirm = async () => {
    if (!isFormValid) {
      return;
    }
    setStoredPhone(phone);
    dispatch(sendOtpRequest({ phoneNumber: phone, userType }));
  };

  useEffect(() => {
    if (phone) {
      setIsPhoneValid(validatePhone(phone));
    }
  }, [phone]);

  useEffect(() => {
    if (sendOtpRequestSucceeded) {
      if (userType === UserType.Customer && businessId) {
        navigate(`/${businessId}/verify-otp`);
      } else if (userType === UserType.Business) {
        navigate(`/business/verify-otp`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendOtpRequestSucceeded]);

  return (
    <Container>
      <TextIconContainer>
        <IconContainer>
          <EmojiPeople />
        </IconContainer>
        <Title>עם מי יש לנו הכבוד?</Title>
      </TextIconContainer>
      <InfoText>
        נשמח לקבל את הטלפון שלכם כדי לשלוח לכם קוד אימות בהודעת סמס.
      </InfoText>
      <FormWrapper
        onSubmit={(e) => {
          e.preventDefault();
          handleConfirm();
        }}
      >
        <FieldWrapper>
          <KLTextInput
            type="tel"
            placeholder="טלפון"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            onValidationChange={setIsPhoneValid}
            disabled={sendingOtpRequest}
          />
        </FieldWrapper>
        <FieldWrapper>
          <KLButton
            title="קבלו קוד אימות"
            disabled={!isFormValid || sendingOtpRequest}
            loading={sendingOtpRequest}
          />
        </FieldWrapper>
      </FormWrapper>
    </Container>
  );
};

export default PhoneInputScreen;
