import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KLBusinessOnboardingProgress from "../../components/KLBusinessOnboardingProgress";
import { Container, InfoText, LinkWrapper, Subtitle, Title } from "../styles";
import KLButton from "../../components/KLButton";
import { AvailabilityType } from "../../constants/types";
import { StorageKeys, StorageKey } from "../../constants/localStorage";
import { clearLocalStorage } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  BusinessCategory,
  CategoryConfig,
  categoryConfigMap,
} from "../../constants/customers/categoryConfigMap";
import { FlowType } from "../../constants/routes";

interface OwnerSuccessScreenProps {
  isOnboarding?: boolean;
}

const OwnerSuccessScreen: React.FC<OwnerSuccessScreenProps> = ({
  isOnboarding,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const { category } = useSelector((state: RootState) => state.businesses);
  const searchParams = new URLSearchParams(location.search);
  const businessId = searchParams.get("businessId");
  const availabilityType = searchParams.get(
    "availabilityType",
  ) as AvailabilityType;

  const link = `https://kavanu.li/${businessId}`;

  const categoryConfig: CategoryConfig | undefined = category
    ? categoryConfigMap[category as BusinessCategory]
    : undefined;

  const term = categoryConfig?.term || "תור";
  const pluralTerm = categoryConfig?.pluralTerm || `${term}ים`;

  const handleCopyLink = (): void => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleGoHome = (): void => {
    navigate(`/${FlowType.Business}/dashboard`);
  };

  useEffect(() => {
    const keysToClear: StorageKey[] = [
      StorageKeys.BusinessId,
      StorageKeys.SelectedService,
      StorageKeys.CurrentStep,
      StorageKeys.MaxStepReached,
      StorageKeys.IsRedirecting,
      StorageKeys.CustomerName,
      StorageKeys.CustomerPhone,
    ];
    clearLocalStorage(keysToClear);
  }, []);

  const getAvailabilityText = (
    availabilityType: AvailabilityType | null,
  ): string => {
    switch (availabilityType) {
      case AvailabilityType.Weekly:
        return "לשבוע הקרוב";
      case AvailabilityType.BiWeekly:
        return "לשבועיים הקרובים";
      default:
        return "";
    }
  };

  return (
    <Container>
      {isOnboarding && <KLBusinessOnboardingProgress currentStep={5} />}
      <Title>יש לנו את זה!</Title>
      {isOnboarding ? (
        <Subtitle>ההרשמה של העסק שלכם הסתיימה בהצלחה!</Subtitle>
      ) : (
        <Subtitle>
          {`העדכון של הזמינות שלכם ${getAvailabilityText(availabilityType)} הסתיימה בהצלחה!`}
        </Subtitle>
      )}
      {isOnboarding ? (
        <>
          <InfoText>
            עכשיו שולחים את הקישור הבא ללקוחות שלכם, והם יקבעו את ה{pluralTerm}:
          </InfoText>
          <LinkWrapper>{link}</LinkWrapper>
          <KLButton
            onClick={handleCopyLink}
            title={copied ? "קישור הועתק!" : "העתק קישור"}
            secondary
          />
        </>
      ) : (
        <InfoText>נדאג לעדכן אתכם גם בפעם הבאה :)</InfoText>
      )}

      <KLButton onClick={handleGoHome} title="לדף העסק שלי" />
    </Container>
  );
};

export default OwnerSuccessScreen;
