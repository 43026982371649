import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { BusinessName, ImageWrapper, OwnerName } from "./styles";
import KLButton from "../../components/KLButton";
import {
  checkCustomerSlots,
  fetchBusiness,
  fetchCustomerDetails,
} from "../../store/duckers/customers/thunk";
import {
  Container,
  IconContainer,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../styles";
import KLPleaseWait from "../../components/KLPleaseWait";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { useStep } from "../../context/StepContext";
import { categoryConfigMap } from "../../constants/customers/categoryConfigMap";
import colors from "../../constants/colors";
import { EventAvailable } from "@mui/icons-material";
import useBusinessImage from "../../hooks/useBusinessImage";
import { CustomerRouteStep, FlowType } from "../../constants/routes";
import { verifyToken } from "../../store/duckers/tokens/thunk";

const CustomerMainScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { markStepAsCompleted } = useStep();
  const { resetSteps } = useStep();
  const { businessId: urlBusinessId } = useParams<{ businessId: string }>();

  const { business, fetchingBusiness, error } = useSelector(
    (state: RootState) => state.customers,
  );

  const {
    verifyingToken,
    userData: tokenPayload,
    isTokenVerified,
    error: tokenError,
  } = useSelector((state: RootState) => state.tokens);

  const {
    fetchingCustomerDetails,
    fetchCustomerDetailsSucceeded,
    customer,
    fetchingCustomerSlots,
    fetchCustomerSlotsSucceeded,
    customerSlots,
  } = useSelector((state: RootState) => state.customers);

  const { storedValue: storedBusinessId, setValue: setBusinessIdStorage } =
    useLocalStorage<StorageKey | null>(StorageKeys.BusinessId, null);

  const { storedValue: storedToken } = useLocalStorage<StorageKey | null>(
    StorageKeys.VerifiedCustomerOtpToken,
    null,
  );

  const [businessId, setBusinessId] = useState<string | null>(
    urlBusinessId || storedBusinessId,
  );

  const [handleLetsGoClicked, setHandleLetsGoClicked] =
    useState<boolean>(false);

  const loading = useMemo(
    (): boolean =>
      verifyingToken || fetchingCustomerDetails || fetchingCustomerSlots,
    [verifyingToken, fetchingCustomerDetails, fetchingCustomerSlots],
  );

  useEffect(() => {
    if (urlBusinessId) {
      setBusinessIdStorage(urlBusinessId as StorageKey);
      setBusinessId(urlBusinessId);
    } else if (storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, [urlBusinessId, storedBusinessId, setBusinessIdStorage]);

  useEffect(() => {
    resetSteps(FlowType.Customer);
  }, []);

  useEffect(() => {
    if (tokenError && businessId) {
      localStorage.removeItem(StorageKeys.VerifiedCustomerOtpToken);
      navigate(`/${businessId}/phone`);
    }
  }, [tokenError, businessId, navigate]);

  const handleLetsGo = () => {
    setHandleLetsGoClicked(true);
    if (businessId) {
      if (storedToken) {
        dispatch(verifyToken(storedToken));
      } else {
        navigate(`/${businessId}/phone`);
      }
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!handleLetsGoClicked) {
      return;
    } else if (isTokenVerified && businessId && tokenPayload?.phoneNumber) {
      dispatch(
        fetchCustomerDetails({ businessId, phone: tokenPayload.phoneNumber }),
      );
    } else {
      navigate(`/${businessId}/phone`);
    }
  }, [isTokenVerified]);

  useEffect(() => {
    if (!handleLetsGoClicked) {
      return;
    } else if (fetchCustomerDetailsSucceeded && businessId) {
      if (customer && tokenPayload?.phoneNumber) {
        dispatch(
          checkCustomerSlots({ businessId, phone: tokenPayload.phoneNumber }),
        );
      } else {
        markStepAsCompleted(FlowType.Customer, CustomerRouteStep.VerifyOtp);
        navigate(`/${businessId}/info`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerDetailsSucceeded]);

  useEffect(() => {
    if (!handleLetsGoClicked) {
      return;
    } else if (fetchCustomerSlotsSucceeded) {
      markStepAsCompleted(FlowType.Customer, CustomerRouteStep.VerifyOtp);
      if (customerSlots.length > 0) {
        navigate(`/${businessId}/existing-slots`);
      } else {
        navigate(`/${businessId}/services`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerSlotsSucceeded]);

  useEffect(() => {
    if (!businessId) {
      return;
    }
    dispatch(fetchBusiness(businessId));
  }, [dispatch, businessId]);

  const categoryConfig = business?.category
    ? categoryConfigMap[business.category as keyof typeof categoryConfigMap]
    : undefined;

  const term = categoryConfig?.term || "תור";

  const imageSrc = useBusinessImage({ businessId, categoryConfig });

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  return (
    <Container>
      {fetchingBusiness ? (
        <KLPleaseWait text="בודקים עם מי יש לנו עסק" />
      ) : error ? (
        <>
          <Title>אופס!</Title>
          <InfoText>זה קצת עסק ביש. אנא נסו שוב.</InfoText>
        </>
      ) : business ? (
        <>
          {imageSrc && (
            <ImageWrapper>
              <img src={imageSrc} alt={business.category || "default"} />
            </ImageWrapper>
          )}
          <BusinessName>{business.businessName}</BusinessName>
          <OwnerName>{business.ownerName}</OwnerName>
          <TextIconContainer>
            <IconContainer>
              <EventAvailable
                fontSize="medium"
                style={{ color: colors.GREY_7 }}
              />
            </IconContainer>
            <Subtitle $noMarginBottom>קבעו {term} באישור מיידי!</Subtitle>
          </TextIconContainer>
          <InfoText>
            עשו זאת בקלות - בחרו את סוג השירות שאתם רוצים, ואת היום והשעה
            שמתאימים לכם, וזהו!
          </InfoText>
          <KLButton
            title={"בואו נתחיל"}
            onClick={handleLetsGo}
            loading={loading}
          />
        </>
      ) : null}
    </Container>
  );
};

export default CustomerMainScreen;
