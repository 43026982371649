import styled from "styled-components";
import colors from "../../constants/colors";
import { FieldWrapper } from "../../screens/styles";

export const ServicesFieldWrapper = styled(FieldWrapper)({
  marginBottom: "0.5rem",
});

export const ServicesFieldWrapperHorizontal = styled(ServicesFieldWrapper)({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
});

export const ServicesSingleField = styled("div")({
  flex: 1,
});

export const CheckboxWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  label: {
    fontSize: "14px",
    color: colors.BLACK,
  },
});

export const CheckboxLabel = styled("label")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  label: {
    fontSize: "14px",
    color: colors.BLACK,
  },
});
