import React, { useEffect, useMemo, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useStep } from "../../context/StepContext";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../../store/duckers/tokens/thunk";
import { AppDispatch, RootState } from "../../store";
import KLPleaseWait from "../../components/KLPleaseWait";
import {
  AllRouteSteps,
  FlowType,
  StepDependencies,
} from "../../constants/routes";

interface SequentialRouteProps {
  routeStep: AllRouteSteps;
  element: React.ReactElement;
  sequence: FlowType;
}

const SequentialRoute: React.FC<SequentialRouteProps> = ({
  routeStep,
  element,
  sequence,
}) => {
  const { markStepAsCompleted, isStepCompleted, isRedirecting } = useStep();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const redirectToken = searchParams.get("redirect_token");

  const dispatch = useDispatch<AppDispatch>();

  const { isTokenVerified, verifyingToken } = useSelector(
    (state: RootState) => state.tokens,
  );

  const [hasDispatched, setHasDispatched] = useState<boolean>(false);

  useEffect(() => {
    const token = searchParams.get("redirect_token");

    if (token && !hasDispatched) {
      dispatch(verifyToken(token));
      setHasDispatched(true);

      searchParams.delete("redirect_token");
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, [dispatch, hasDispatched, searchParams]);

  useEffect(() => {
    if (isTokenVerified) {
      markStepAsCompleted(sequence, routeStep);
    }
  }, [isTokenVerified, markStepAsCompleted, routeStep, sequence]);

  useEffect(() => {
    const dependencies = StepDependencies[routeStep];
    const hasDependenciesMet = dependencies.every((step) =>
      isStepCompleted(sequence, step),
    );

    if (hasDependenciesMet) {
      markStepAsCompleted(sequence, routeStep);
    }
  }, [routeStep, markStepAsCompleted, sequence, isStepCompleted]);

  const dependencies = StepDependencies[routeStep];
  const hasDependenciesMet = dependencies.every((step) =>
    isStepCompleted(sequence, step),
  );

  if (isRedirecting) {
    return element;
  }

  if (redirectToken) {
    if (!hasDispatched || verifyingToken) {
      return <KLPleaseWait text="עוד רגע זה קורה" />;
    }

    if (isTokenVerified) {
      return element;
    }

    if (hasDispatched && !isTokenVerified) {
      return <Navigate to="/not-authorized" replace />;
    }

    return <KLPleaseWait text="Loading..." />;
  }

  if (!hasDependenciesMet) {
    return <Navigate to="/not-authorized" replace />;
  }

  return element;
};

export default SequentialRoute;
