// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import MainScreen from "./screens/MainScreen";
import CustomerMainScreen from "./screens/CustomerMainScreen";
import PhoneInputScreen from "./screens/PhoneInputScreen";
import CustomerVerifyOtpScreen from "./screens/OtpInputScreen";
import CustomerInfoScreen from "./screens/CustomerInfoScreen";
import CustomerServicesScreen from "./screens/CustomerServicesScreen";
import CustomerExistingSlotsScreen from "./screens/CustomerExistingSlotsScreen";
import CustomerSlotsScreen from "./screens/CustomerSlotsScreen";
import CustomerSuccessScreen from "./screens/CustomerSuccessScreen";
import DashboardScreen from "./screens/DashboardScreen";
import OwnerInfoScreen from "./screens/Onboarding/OwnerInfoScreen";
import ServicesSetupScreen from "./screens/ServicesSetupScreen";
import AuthorizeScreen from "./screens/Onboarding/AuthorizeScreen";
import SlotsSetupScreen from "./screens/SlotsSetupScreen";
import OwnerSuccessScreen from "./screens/OwnerSuccessScreen";
import AvailabilityScreen from "./screens/AvailabilityScreen";
import PrivacyPolicyScreen from "./screens/Legal/PrivacyPolicyScreen";
import TermsOfServiceScreen from "./screens/Legal/TermsOfServiceScreen";
import NotAuthorizedScreen from "./screens/NotAuthorized";
import ShortLinkRedirectScreen from "./screens/ShortLinkRedirectionScreen";
import InsightsScreen from "./screens/InsightsScreen";
import LayoutWrapper from "./Layout/LayoutWrapper";
import SequentialRoute from "./routes/SequentialRoute";
import { StepProvider } from "./context/StepContext";
import {
  AvailabilityRouteStep,
  BusinessRouteStep,
  CustomerRouteStep,
  FlowType,
} from "./constants/routes";
import { UserType } from "./constants/types";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <StepProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route
              path="/terms-of-service"
              element={<TermsOfServiceScreen />}
            />
            <Route path="/not-authorized" element={<NotAuthorizedScreen />} />
            <Route path="/m/:shortCode" element={<ShortLinkRedirectScreen />} />

            {/* Layout Wrapper for Protected/Structured Routes */}
            <Route element={<LayoutWrapper />}>
              {/* Main Routes */}
              <Route path="/" element={<MainScreen />} />
              <Route path="/:businessId" element={<CustomerMainScreen />} />

              {/* Customer Flow */}
              <Route
                path={`/:businessId/phone`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.PhoneInput}
                    element={<PhoneInputScreen userType={UserType.Customer} />}
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/verify-otp`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.VerifyOtp}
                    element={
                      <CustomerVerifyOtpScreen userType={UserType.Customer} />
                    }
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/info`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.Info}
                    element={<CustomerInfoScreen />}
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/services`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.Services}
                    element={<CustomerServicesScreen />}
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/existing-slots`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.ExistingSlots}
                    element={<CustomerExistingSlotsScreen />}
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/slots`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.Slots}
                    element={<CustomerSlotsScreen />}
                    sequence={FlowType.Customer}
                  />
                }
              />
              <Route
                path={`/:businessId/success`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.Success}
                    element={<CustomerSuccessScreen />}
                    sequence={FlowType.Customer}
                  />
                }
              />

              {/* Business Flow */}
              <Route
                path={`/${FlowType.Business}/phone`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.PhoneInput}
                    element={<PhoneInputScreen userType={UserType.Business} />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/verify-otp`}
                element={
                  <SequentialRoute
                    routeStep={CustomerRouteStep.VerifyOtp}
                    element={
                      <CustomerVerifyOtpScreen userType={UserType.Business} />
                    }
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/dashboard`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.Dashboard}
                    element={<DashboardScreen />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path="/insights/:businessId/:year/:month"
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.Insights}
                    element={<InsightsScreen />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/info`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.Info}
                    element={<OwnerInfoScreen />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/services`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.ServicesSetup}
                    element={<ServicesSetupScreen />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/authorize`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.Authorize}
                    element={<AuthorizeScreen />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/slots`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.SlotsSetup}
                    element={<SlotsSetupScreen isOnboarding />}
                    sequence={FlowType.Business}
                  />
                }
              />
              <Route
                path={`/${FlowType.Business}/success`}
                element={
                  <SequentialRoute
                    routeStep={BusinessRouteStep.Success}
                    element={<OwnerSuccessScreen isOnboarding />}
                    sequence={FlowType.Business}
                  />
                }
              />

              {/* Availability Flow */}
              <Route
                path={`/${FlowType.Availability}/check`}
                element={
                  <SequentialRoute
                    routeStep={AvailabilityRouteStep.Check}
                    element={<AvailabilityScreen />}
                    sequence={FlowType.Availability}
                  />
                }
              />
              <Route
                path={`/${FlowType.Availability}/slots`}
                element={
                  <SequentialRoute
                    routeStep={AvailabilityRouteStep.Slots}
                    element={<SlotsSetupScreen />}
                    sequence={FlowType.Availability}
                  />
                }
              />
              <Route
                path={`/${FlowType.Availability}/success`}
                element={
                  <SequentialRoute
                    routeStep={AvailabilityRouteStep.Success}
                    element={<OwnerSuccessScreen />}
                    sequence={FlowType.Availability}
                  />
                }
              />
            </Route>
          </Routes>
        </Router>
      </StepProvider>
    </Provider>
  );
};

export default App;
