import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { StorageKeys } from "../../../constants/localStorage";
import {
  AvailabilityType,
  CustomerSlot,
  ServiceWithId,
  Slot,
  UserType,
} from "../../../constants/types";
import { slotsService } from "../../../services/slotsService";
import { updateBookedSlot } from "../businesses/slice";
import { addAvailableSlot } from "./slice";

export const createSlots = createAsyncThunk<
  void,
  {
    businessId: string;
    availabilityType: AvailabilityType;
    slots: any[];
    breakMinutesBetweenSlots: string;
  },
  { rejectValue: string }
>(
  "slots/createSlots",
  async (
    { businessId, availabilityType, slots, breakMinutesBetweenSlots },
    { rejectWithValue },
  ) => {
    try {
      await slotsService.createSlots(
        businessId,
        availabilityType,
        slots,
        breakMinutesBetweenSlots,
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchSlots = createAsyncThunk<
  { availableSlots: Slot[]; unavailableSlots: Slot[] },
  {
    businessId: string;
    duration: number;
    userType: UserType;
    serviceId?: number;
  },
  { rejectValue: string }
>(
  "slots/fetchSlots",
  async (
    { businessId, duration, userType, serviceId },
    { rejectWithValue },
  ) => {
    try {
      return await slotsService.fetchSlots(
        businessId,
        duration,
        userType,
        serviceId,
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const bookSlot = createAsyncThunk<
  { customerPhone: string; latestBooking: CustomerSlot },
  {
    businessId: string;
    customerName: string;
    customerPhone: string;
    service: ServiceWithId;
    startTime: string;
    endTime: string;
    userType: UserType;
  },
  { rejectValue: string }
>(
  "slots/bookSlot",
  async (
    {
      businessId,
      customerName,
      customerPhone,
      service,
      startTime,
      endTime,
      userType,
    },
    { rejectWithValue },
  ) => {
    try {
      const tokenType =
        userType === UserType.Business
          ? StorageKeys.VerifiedBusinessOtpToken
          : StorageKeys.VerifiedCustomerOtpToken;

      const eventId = await slotsService.bookSlot(
        businessId,
        customerName,
        customerPhone,
        service.id,
        startTime,
        endTime,
        tokenType,
        userType,
      );

      const latestBooking: CustomerSlot = {
        id: eventId,
        start: startTime,
        end: endTime,
        service: service.description,
        price: service.price,
        cancellationPolicy: service.cancellationPolicy ?? 0,
      };

      return { customerPhone, latestBooking };
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const changeSlot = createAsyncThunk<
  {
    updatedSlot: { id: string; start: string; end: string };
    customerPhone?: string;
  },
  {
    businessId: string;
    slotId: string;
    startTime: string;
    endTime: string;
    customerPhone?: string;
  },
  { rejectValue: string; state: RootState }
>(
  "slots/changeSlot",
  async (
    { businessId, slotId, startTime, endTime, customerPhone },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const state = getState();

      const oldSlot = state.businesses.bookedSlots.find(
        (slot) => slot.id === slotId,
      );

      if (oldSlot) {
        const { start, end } = oldSlot;
        dispatch(addAvailableSlot({ start, end }));
      }

      const updatedSlot: { id: string; start: string; end: string } =
        await slotsService.changeSlot(businessId, slotId, startTime, endTime);

      dispatch(
        updateBookedSlot({
          id: slotId,
          start: updatedSlot.start,
          end: updatedSlot.end,
        }),
      );

      return { updatedSlot, customerPhone };
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to change slot";
      return rejectWithValue(errorMessage);
    }
  },
);

export const cancelSlot = createAsyncThunk<
  string,
  { businessId: string; slotId: string; skipNotification?: boolean },
  {
    rejectValue: string;
  }
>(
  "slots/requestSlotCancellation",
  async ({ businessId, slotId, skipNotification }, { rejectWithValue }) => {
    try {
      await slotsService.cancelSlot(businessId, slotId, skipNotification);
      return slotId;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
