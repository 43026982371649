import React, { useEffect } from "react";
import { Container } from "./styles";
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { InfoText, Subtitle, TextIconContainer, Title } from "../styles";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { clearLocalStorage } from "../../utils";
import { EventAvailable } from "@mui/icons-material";

const CustomerSuccessScreen: React.FC = () => {
  useEffect(() => {
    const keysToClear: StorageKey[] = [
      StorageKeys.BusinessId,
      StorageKeys.SelectedService,
      StorageKeys.CurrentStep,
      StorageKeys.MaxStepReached,
      StorageKeys.IsRedirecting,
      StorageKeys.CustomerName,
      StorageKeys.CustomerPhone,
    ];
    clearLocalStorage(keysToClear);
  }, []);
  return (
    <Container>
      <KLCustomerBookingProgress currentStep={4} />
      <TextIconContainer>
        <EventAvailable fontSize="large" />
        <Title>איזה כיף, קבענו לי :)</Title>
      </TextIconContainer>
      <Subtitle>מחכים ומצפים לכם ביום ובשעה שקבעתם!</Subtitle>
      <InfoText>שלחנו לכם הודעת סמס עם כל הפרטים, ליתר ביטחון.</InfoText>
    </Container>
  );
};

export default CustomerSuccessScreen;
