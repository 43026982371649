import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import KLButton from "../../components/KLButton";
import { Container, InfoText, Subtitle, Title } from "../styles"; // Adjust import paths as necessary
import KLCustomerBookingProgress from "../../components/KLCustomerBookingProgress";
import { useStep } from "../../context/StepContext";
import KLPleaseWait from "../../components/KLPleaseWait";
import { ServiceWithId, UserType } from "../../constants/types";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKeys } from "../../constants/localStorage";
import { fetchBusiness } from "../../store/duckers/customers/thunk";
import {
  setCustomerName,
  setCustomerPhone,
  setCustomerService,
} from "../../store/duckers/customers/slice";
import { CustomerRouteStep, FlowType } from "../../constants/routes";
import KLAvailableSlotSelect from "../../components/KLAvailableSlotSelect"; // Import the renamed component
import { bookSlot, fetchSlots } from "../../store/duckers/slots/thunk";

const CustomerSlotsScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted } = useStep();
  const dispatch = useDispatch<AppDispatch>();
  const { businessId: urlBusinessId } = useParams<{ businessId: string }>();

  const { bookSlotSucceeded, fetchingSlots, availableSlots } = useSelector(
    (state: RootState) => state.slots,
  );

  const {
    customerName,
    customerPhone,
    customerService,
    fetchingBusiness,
    business,
  } = useSelector((state: RootState) => state.customers);

  const {
    storedValue: storedBusinessId,
    setValue: setBusinessIdStorage,
    remove: removeBusinessIdStorage,
  } = useLocalStorage<string | null>(StorageKeys.BusinessId, null);

  const {
    storedValue: storedSelectedService,
    setValue: setSelectedServiceStorage,
    remove: removeSelectedServiceStorage,
  } = useLocalStorage<ServiceWithId | null>(StorageKeys.SelectedService, null);

  const [businessId, setBusinessId] = useState<string | null>(
    urlBusinessId || storedBusinessId,
  );
  const [selectedService, setSelectedService] = useState<ServiceWithId | null>(
    customerService || storedSelectedService,
  );

  const { storedValue: storedName, setValue: setStoredName } =
    useLocalStorage<string>(StorageKeys.CustomerName, "");

  const { storedValue: storedPhone, setValue: setStoredPhone } =
    useLocalStorage<string>(StorageKeys.CustomerPhone, "");

  useEffect(() => {
    if (customerName !== storedName) {
      setStoredName(customerName);
    }
    if (customerPhone !== storedPhone) {
      setStoredPhone(customerPhone);
    }
  }, [customerName, customerPhone, setStoredName, setStoredPhone]);

  useEffect(() => {
    if (storedName && customerName !== storedName) {
      dispatch(setCustomerName(storedName));
    }
    if (storedPhone && customerPhone !== storedPhone) {
      dispatch(setCustomerPhone(storedPhone));
    }
  }, [storedName, storedPhone, dispatch, customerName, customerPhone]);

  useEffect(() => {
    if (!urlBusinessId && storedBusinessId) {
      setBusinessId(storedBusinessId);
    }
  }, [urlBusinessId, storedBusinessId]);

  useEffect(() => {
    if (businessId) {
      setBusinessIdStorage(businessId);
    } else {
      removeBusinessIdStorage();
    }
  }, [businessId, setBusinessIdStorage, removeBusinessIdStorage]);

  useEffect(() => {
    if (!customerService && storedSelectedService) {
      setSelectedService(storedSelectedService);
    }
  }, [customerService, storedSelectedService]);

  useEffect(() => {
    if (selectedService) {
      setSelectedServiceStorage(selectedService);
      dispatch(setCustomerService(selectedService));
    } else {
      removeSelectedServiceStorage();
    }
  }, [
    selectedService,
    setSelectedServiceStorage,
    removeSelectedServiceStorage,
    dispatch,
  ]);

  useEffect(() => {
    if (businessId && !business) {
      dispatch(fetchBusiness(businessId));
    }
  }, [businessId, business, dispatch]);

  useEffect(() => {
    if (bookSlotSucceeded) {
      markStepAsCompleted(FlowType.Customer, CustomerRouteStep.Slots);
      navigate(`/${businessId}/success`);
    }
  }, [bookSlotSucceeded, businessId, navigate, markStepAsCompleted]);

  useEffect(() => {
    if (businessId && selectedService?.duration) {
      dispatch(
        fetchSlots({
          businessId,
          duration: selectedService.duration,
          serviceId: selectedService.id,
          userType: UserType.Customer,
        }),
      );
    }
  }, [businessId, selectedService?.duration, dispatch]);

  const handleConfirm = (selectedTime: {
    month: string;
    day: string;
    dayLabel: string;
    time: string;
    displayTime: string;
  }) => {
    const { time } = selectedTime;
    if (
      businessId &&
      selectedService &&
      customerName &&
      customerPhone &&
      business
    ) {
      const [startTime, endTime] = time.split(" - ");
      dispatch(
        bookSlot({
          businessId,
          customerName,
          customerPhone,
          service: selectedService,
          startTime,
          endTime,
          userType: UserType.Customer,
        }),
      );
    }
  };

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  if (!selectedService) {
    return (
      <Container>
        <KLPleaseWait text="מאתחלים את ההגדרות שלך..." />
      </Container>
    );
  }

  return (
    <Container>
      <KLCustomerBookingProgress currentStep={3} />
      {fetchingSlots || fetchingBusiness ? (
        <KLPleaseWait text={"בודקים זמנים פנויים"} />
      ) : (
        <>
          {!availableSlots.length ? (
            <>
              <Title>אין דרך קלה להגיד את זה אבל...</Title>
              <Subtitle>לא הצלחנו למצוא זמן פנוי :(</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
              <KLButton
                title={"אולי בכל זאת?"}
                onClick={() =>
                  dispatch(
                    fetchSlots({
                      businessId,
                      duration: selectedService.duration as number,
                      serviceId: selectedService.id,
                      userType: UserType.Customer,
                    }),
                  )
                }
                disabled={fetchingSlots}
              />
            </>
          ) : (
            <KLAvailableSlotSelect
              isCustomer
              serviceDescription={selectedService.description}
              businessName={business?.businessName}
              onSelect={handleConfirm}
              title="בואו נקבע לכם"
              subtitle="על איזה חודש אנחנו מדברים?"
              confirmationButtonTitle="הולכים על זה"
            />
          )}
        </>
      )}
    </Container>
  );
};

export default CustomerSlotsScreen;
