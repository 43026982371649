import apiClient from "../api/apiClient";
import { Service, ServiceWithId, UserType } from "../constants/types";
import { StorageKeys } from "../constants/localStorage";

class ServicesService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async fetchServices(
    businessId: string,
    userType: UserType,
  ): Promise<ServiceWithId[]> {
    try {
      const response = await apiClient.get<ServiceWithId[]>(
        `${this.baseApiUrl}/api/${businessId}/services`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type":
              userType === UserType.Customer
                ? StorageKeys.VerifiedCustomerOtpToken
                : StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      const services = response.data;

      if (!Array.isArray(services)) {
        throw new Error("Invalid response structure");
      }

      return services;
    } catch (error) {
      throw new Error("Failed to fetch services");
    }
  }

  async updateServices(
    businessId: string,
    servicesToUpdate: ServiceWithId[],
  ): Promise<ServiceWithId[]> {
    try {
      const response = await apiClient.put<ServiceWithId[]>(
        `${this.baseApiUrl}/api/${businessId}/services`,
        {
          services: servicesToUpdate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      const updatedServices = response.data;

      if (!Array.isArray(updatedServices)) {
        throw new Error("Invalid response structure");
      }

      return updatedServices;
    } catch (error) {
      console.error("servicesService - updateServices - Error:", error);
      throw new Error("Failed to update services");
    }
  }

  async createServices(
    businessId: string,
    newServices: Service[],
  ): Promise<ServiceWithId[]> {
    try {
      const response = await apiClient.post<ServiceWithId[]>(
        `${this.baseApiUrl}/api/${businessId}/services`,
        {
          services: newServices,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      const createdServices = response.data;

      if (!Array.isArray(createdServices)) {
        throw new Error("Invalid response structure");
      }

      return createdServices;
    } catch (error) {
      console.error("servicesService - createServices - Error:", error);
      throw new Error("Failed to create services");
    }
  }
}

export const servicesService = new ServicesService();
