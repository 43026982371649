import styled from "styled-components";
import colors from "../../constants/colors";

export const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

export const Input = styled("input")({
  padding: "0.75rem",
  border: `1px solid ${colors.GREY_3}`,
  borderRadius: "8px",
  fontSize: "16px",
  textAlign: "right",
  width: "100%",
  boxSizing: "border-box",
  "&[disabled]::placeholder": {
    textDecoration: "line-through",
  },
});

export const ErrorText = styled("div")({
  fontSize: "13px",
  color: colors.ORANGE,
});

export const Counter = styled("span")<{
  $isMaxReached: boolean;
}>(({ $isMaxReached }) => ({
  color: $isMaxReached ? colors.RED : colors.BLACK,
  fontSize: "13px",
  position: "absolute",
  top: "1rem",
  left: "0.75rem",
  pointerEvents: "none",
}));
