import React, { useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import KLAvailabilityForm from "../KLAvailabilityForm";
import { RootState, AppDispatch } from "../../store";
import {
  AvailabilityDetail,
  AvailabilitySlot,
  AvailabilityType,
  CombinedDay,
} from "../../constants/types";
import useAvailability from "../../hooks/useAvailability";
import { computeCombinedDays, dayMapping, daysOfWeek } from "../../utils";
import { updateAvailability } from "../../store/duckers/businesses/thunk";
import KLCollapsibleSection from "../KLCollapsibleSection";
import { WarningIcon } from "../../screens/styles";

interface KLDashboardAvailabilityProps {
  businessId: string;
  availabilityType?: AvailabilityType;
  breakMinutesBetweenSlots?: string;
  term: string;
  pluralTerm: string;
}

const KLDashboardAvailability: React.FC<KLDashboardAvailabilityProps> = ({
  businessId,
  availabilityType,
  breakMinutesBetweenSlots,
  term,
  pluralTerm,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const availabilityData: AvailabilityDetail[] = useSelector(
    (state: RootState) => state.businesses.availability,
  );

  const updatingAvailability: boolean = useSelector(
    (state: RootState) => state.businesses.updatingAvailability,
  );

  const [isAvailabilitySectionExpanded, setIsAvailabilitySectionExpanded] =
    useState(false);

  const error: string | null = useSelector(
    (state: RootState) => state.businesses.errorAvailability,
  );

  const availabilitySlots: AvailabilitySlot[] = useMemo(() => {
    return availabilityData.map((detail) => {
      let dayHebrew: string;

      if (detail.dayOfWeek === 6) {
        dayHebrew = dayMapping["Saturday"];
      } else {
        const dayEntry = daysOfWeek.find(
          (day) => day.index === detail.dayOfWeek,
        );
        dayHebrew = dayEntry ? dayEntry.label : "Unknown";
      }

      return {
        day: dayHebrew,
        date: detail.date,
        startTime: detail.startTime ? detail.startTime.slice(0, 5) : "",
        endTime: detail.endTime ? detail.endTime.slice(0, 5) : "",
      };
    });
  }, [availabilityData]);

  const initialAvailableSlots = useMemo(
    () => ({
      availabilityType,
      breakMinutesBetweenSlots,
      slots: availabilitySlots,
    }),
    [availabilitySlots],
  );

  const {
    availabilityType: formAvailabilityType,
    breakMinutesBetweenSlots: formBreakMinutesBetweenSlots,
    setBreakMinutesBetweenSlots,
    slotsByDay,
    futureDateDayMap,
    currentDateDayMap,
    isFormValid,
    getSlotOptions,
    handleDayClick,
    isDayExpanded,
    handleAddSlot,
    handleDeleteSlot,
    handleUpdateSlot,
    buildSlotsPayload,
  } = useAvailability({ initialAvailableSlots });

  const combinedDays: CombinedDay[] = useMemo(() => {
    return computeCombinedDays(
      futureDateDayMap,
      currentDateDayMap,
      availabilityType,
    );
  }, [futureDateDayMap, currentDateDayMap, availabilityType]);

  const hasMissingDays = useMemo(() => {
    return combinedDays.some((day) => day.highlightMissing);
  }, [combinedDays]);

  const handleSave = useCallback(async () => {
    if (!isFormValid) {
      return;
    }
    const updatedAvailabilitySlots: AvailabilitySlot[] = buildSlotsPayload();

    const updatedAvailabilityDetails: AvailabilityDetail[] =
      updatedAvailabilitySlots.map((slot) => {
        let dayOfWeek: number;

        if (slot.day === dayMapping["Saturday"]) {
          dayOfWeek = 6;
        } else {
          const dayEntry = daysOfWeek.find((day) => day.label === slot.day);
          dayOfWeek = dayEntry ? dayEntry.index : -1;
        }

        return {
          dayOfWeek,
          startTime: `${slot.startTime}:00`,
          endTime: `${slot.endTime}:00`,
          weeklyRecurring:
            availabilityType === AvailabilityType.WeeklyRecurring,
          date: slot.date || "",
        };
      });

    dispatch(
      updateAvailability({
        businessId,
        updatedAvailabilitySlots: updatedAvailabilityDetails,
      }),
    );
  }, [isFormValid, buildSlotsPayload]);

  const handleAvailabilitySectionToggle = useCallback(() => {
    setIsAvailabilitySectionExpanded((prev) => !prev);
  }, []);

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <KLCollapsibleSection
      title={
        <>
          {"מתי פנויים לקבל לקוחות?"}
          {hasMissingDays && <WarningIcon>!</WarningIcon>}
        </>
      }
      isExpanded={isAvailabilitySectionExpanded}
      onToggle={handleAvailabilitySectionToggle}
      secondary
    >
      <KLAvailabilityForm
        updatingSlots={updatingAvailability}
        availabilityType={formAvailabilityType}
        breakMinutesBetweenSlots={formBreakMinutesBetweenSlots}
        setBreakMinutesBetweenSlots={setBreakMinutesBetweenSlots}
        slotsByDay={slotsByDay}
        futureDateDayMap={futureDateDayMap}
        currentDateDayMap={currentDateDayMap}
        getSlotOptions={getSlotOptions}
        handleDayClick={handleDayClick}
        isDayExpanded={isDayExpanded}
        handleAddSlot={handleAddSlot}
        handleDeleteSlot={handleDeleteSlot}
        handleUpdateSlot={handleUpdateSlot}
        isFormValid={isFormValid}
        submitTitle="לשמור שינויים"
        submitType="button"
        onSubmit={handleSave}
        hideBreakMinutes
        term={term}
        pluralTerm={pluralTerm}
      />
    </KLCollapsibleSection>
  );
};

export default KLDashboardAvailability;
