import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KLButton from "../../components/KLButton";
import KLBusinessOnboardingProgress from "../../components/KLBusinessOnboardingProgress";
import {
  Container,
  Title,
  InfoText,
  FormWrapper,
  TextIconContainer,
  IconContainer,
  Disclaimer,
} from "../styles";
import { Service } from "../../constants/types";
import { AppDispatch, RootState } from "../../store";
import { setBusinessServices } from "../../store/duckers/businesses/slice";
import { useStep } from "../../context/StepContext";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { FlowType, BusinessRouteStep } from "../../constants/routes";
import { cancellationOptions, durationOptions } from "../../utils";
import KLServicesList from "../../components/KLServiceList";
import useServices from "../../hooks/useServices";

const ServicesSetupScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { markStepAsCompleted } = useStep();

  const storedServices = useSelector(
    (state: RootState) => state.businesses.services,
  );

  const handleSaveServices = (servicesToSave: Service[]) => {
    dispatch(setBusinessServices(servicesToSave));
    markStepAsCompleted(FlowType.Business, BusinessRouteStep.ServicesSetup);
    navigate(`/${FlowType.Business}/authorize`);
  };

  const {
    services,
    isPriceValid,
    expandedServices,
    handleAddService,
    handleDeleteService,
    handleServiceChange,
    handlePriceValidation,
    toggleServiceExpansion,
    isFormValid,
  } = useServices({
    initialServices: storedServices,
  });

  const handleSubmit = () => {
    if (isFormValid) {
      handleSaveServices(services);
    } else {
      alert("Please fill in all fields correctly");
    }
  };

  const durationOptionsFormatted = useMemo(
    () =>
      durationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  const cancellationOptionsFormatted = useMemo(
    () =>
      cancellationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={2} />
      <TextIconContainer>
        <IconContainer>
          <StorefrontIcon />
        </IconContainer>
        <Title>מה יש לכם להציע?</Title>
      </TextIconContainer>
      <InfoText>כאן מוסיפים את השירותים שאתם מציעים ללקוחות שלכם</InfoText>
      <FormWrapper>
        <KLServicesList
          services={services}
          isPriceValid={isPriceValid}
          expandedServices={expandedServices}
          onAddService={handleAddService}
          onDeleteService={handleDeleteService}
          onServiceChange={handleServiceChange}
          onPriceValidation={handlePriceValidation}
          toggleServiceExpansion={toggleServiceExpansion}
          durationOptions={durationOptionsFormatted}
          cancellationOptions={cancellationOptionsFormatted}
        />
        <Disclaimer>מחירי השירותים הינם בשקלים חדשים וכוללים מע״מ.</Disclaimer>
        <KLButton
          title={"הבא"}
          onClick={handleSubmit}
          disabled={!isFormValid}
          noMarginTop
        />
      </FormWrapper>
    </Container>
  );
};

export default ServicesSetupScreen;
