import React from "react";
import { Service } from "../../constants/types";
import { getCancellationPolicyLabel, getDurationLabel } from "../../utils";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLServiceForm from "../KLServiceForm";

interface KLServiceItemProps {
  service: Service;
  index: number;
  isExpanded: boolean;
  onToggle: (index: number) => void;
  onDelete: (index: number) => void;
  onServiceChange: (
    index: number,
    field: keyof Service,
    value: Service[keyof Service],
  ) => void;
  onPriceValidation?: (index: number, isValid: boolean) => void;
  durationOptions: { label: string; value: string }[];
  cancellationOptions: { label: string; value: string }[];
}

const KLServiceItem: React.FC<KLServiceItemProps> = ({
  service,
  index,
  isExpanded,
  onToggle,
  onDelete,
  onServiceChange,
  onPriceValidation,
  durationOptions,
  cancellationOptions,
}) => {
  const title =
    service.description.trim() === ""
      ? "[שם השירות יופיע פה]"
      : service.description;
  const subtitle = ((service.price && !service.showWithoutPrice) ||
    service.duration !== undefined ||
    service.cancellationPolicy !== undefined) && (
    <>
      {service.price && !service.showWithoutPrice && `${service.price} ש״ח`}
      {service.price &&
        !service.showWithoutPrice &&
        service.duration !== undefined &&
        " | "}
      {service.duration !== undefined && getDurationLabel(service.duration)}
      {((service.price && !service.showWithoutPrice) ||
        service.duration !== undefined) &&
        service.cancellationPolicy !== undefined &&
        " | "}
      {service.cancellationPolicy !== undefined &&
        ` ביטול ${getCancellationPolicyLabel(service.cancellationPolicy)}`}
    </>
  );

  return (
    <KLCollapsibleSection
      title={title}
      subtitle={subtitle}
      isExpanded={isExpanded}
      onToggle={() => onToggle(index)}
      onDelete={() => onDelete(index)}
    >
      <KLServiceForm
        service={service}
        index={index}
        onServiceChange={onServiceChange}
        onPriceValidation={onPriceValidation}
        durationOptions={durationOptions}
        cancellationOptions={cancellationOptions}
      />
    </KLCollapsibleSection>
  );
};

export default KLServiceItem;
