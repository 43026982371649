import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Service,
  ServiceType,
  ServiceWithId,
  UserType,
} from "../../../constants/types";
import { servicesService } from "../../../services/servicesService";

export const fetchServices = createAsyncThunk<
  ServiceWithId[],
  [string, UserType],
  { rejectValue: string }
>(
  "services/fetchServices",
  async ([businessId, userType], { rejectWithValue }) => {
    try {
      const services = await servicesService.fetchServices(
        businessId,
        userType,
      );
      return services;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateServices = createAsyncThunk<
  ServiceWithId[],
  [string, UserType, ServiceType[]],
  { rejectValue: string }
>(
  "services/updateServices",
  async ([businessId, userType, servicesToUpdate], { rejectWithValue }) => {
    try {
      const existingServices: ServiceWithId[] = servicesToUpdate.filter(
        (service): service is ServiceWithId => "id" in service,
      );
      const newServices: Service[] = servicesToUpdate.filter(
        (service): service is Service => !("id" in service),
      );

      let updatedExistingServices: ServiceWithId[] = [];
      if (existingServices.length > 0) {
        updatedExistingServices = await servicesService.updateServices(
          businessId,
          existingServices,
        );
      }

      let createdNewServices: ServiceWithId[] = [];
      if (newServices.length > 0) {
        createdNewServices = await servicesService.createServices(
          businessId,
          newServices,
        );
      }

      const combinedServices = [
        ...updatedExistingServices,
        ...createdNewServices,
      ];

      return combinedServices;
    } catch (error) {
      const errorMessage = (error as Error).message;
      console.error("Thunk - updateServices - Error:", errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);
