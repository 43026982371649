import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMonthlyInsights, fetchAvailableInsightsPeriods } from "./thunk";
import {
  BusinessInsights,
  AvailableInsightsPeriod,
} from "../../../constants/types";

interface InsightsState extends BusinessInsights {
  loading: boolean;
  error: string | null;
  availablePeriods: AvailableInsightsPeriod[];
  loadingPeriods: boolean;
  periodsError: string | null;
  currentPeriod: {
    businessId: string | null;
    year: number | null;
    month: number | null;
  };
}

const initialState: InsightsState = {
  bookingVolume: {
    totalBookings: 0,
    monthOverMonthGrowth: 0,
    yearOverYearGrowth: 0,
    dailyTrends: [],
    weeklyTrends: [],
    seasonalComparison: [],
  },
  servicePerformance: {
    topServices: [],
    bottomServices: [],
    serviceRevenue: [],
    durationImpact: [],
    cancellationRates: [],
    cancellationGrowth: {
      monthOverMonthGrowth: 0,
      yearOverYearGrowth: null,
    },
  },
  customerBehavior: {
    topCustomers: [],
    customerSegmentation: {
      repeatCustomers: {
        count: 0,
        percentage: 0,
        averageBookingsPerCustomer: 0,
      },
      newCustomers: {
        count: 0,
        percentage: 0,
      },
      customerValueSegments: [],
    },
    bookingLeadTime: {
      averageLeadTime: 0,
      leadTimeDistribution: [],
    },
    customerGrowth: {
      repeatCustomers: {
        monthOverMonthGrowth: 0,
        yearOverYearGrowth: null,
      },
      newCustomers: {
        monthOverMonthGrowth: 0,
        yearOverYearGrowth: null,
      },
    },
    bookingMetrics: {
      averageLeadTime: {
        monthOverMonthGrowth: 0,
        yearOverYearGrowth: null,
      },
      averageBookingsPerWeek: {
        current: 0,
        monthOverMonthGrowth: 0,
        yearOverYearGrowth: null,
      },
    },
  },
  loading: false,
  error: null,
  availablePeriods: [],
  loadingPeriods: false,
  periodsError: null,
  currentPeriod: {
    businessId: null,
    year: null,
    month: null,
  },
};

const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMonthlyInsights.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMonthlyInsights.fulfilled,
        (
          state,
          action: PayloadAction<
            BusinessInsights,
            string,
            { arg: [string, number, number] }
          >,
        ) => {
          state.loading = false;
          state.bookingVolume = action.payload.bookingVolume;
          state.servicePerformance = action.payload.servicePerformance;
          state.customerBehavior = action.payload.customerBehavior;
          const [businessId, year, month] = action.meta.arg;
          state.currentPeriod = {
            businessId,
            year,
            month,
          };
        },
      )
      .addCase(fetchMonthlyInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch monthly insights";
      })
      .addCase(fetchAvailableInsightsPeriods.pending, (state) => {
        state.loadingPeriods = true;
        state.periodsError = null;
      })
      .addCase(
        fetchAvailableInsightsPeriods.fulfilled,
        (state, action: PayloadAction<AvailableInsightsPeriod[]>) => {
          state.loadingPeriods = false;
          state.availablePeriods = action.payload;
        },
      )
      .addCase(fetchAvailableInsightsPeriods.rejected, (state, action) => {
        state.loadingPeriods = false;
        state.periodsError =
          action.payload || "Failed to fetch available insights periods";
      });
  },
});

export default insightsSlice.reducer;
