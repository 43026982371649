export enum UserType {
  Customer = "customer",
  Business = "business",
}

export interface TokenPayload {
  [key: string]: any;
}

export interface VerifyTokenResponse {
  valid: boolean;
  payload?: TokenPayload;
  message?: string;
}

export interface Slot {
  start: string;
  end: string;
}

export interface KLSlot extends Slot {
  id: string;
  name: string;
  phone: string;
  service: string;
  price: string;
  cancellationPolicy: number;
}

export interface Customer {
  id: string;
  name: string;
  phone: string;
}

export interface CustomerWithLatestBooking extends Customer {
  address?: string;
  created_at?: string;
  updated_at?: string;
  latestBookingStartTime: string | null;
  latestBookingEndTime: string | null;
  serviceName: string | null;
}

export interface CustomerSlot extends Slot {
  id: string;
  service: string;
  price: string;
  cancellationPolicy: number;
}

export interface Service {
  description: string;
  price: string;
  duration?: number;
  showWithoutPrice: boolean;
  cancellationPolicy?: number;
}

export interface ServiceWithId extends Service {
  id: number;
}

export type ServiceType = Service | ServiceWithId;

interface BusinessIdResponseSuccess {
  id: string;
}

interface BusinessIdResponseNotFound {
  message: string;
}

export type BusinessIdResponse =
  | BusinessIdResponseSuccess
  | BusinessIdResponseNotFound;

export interface Business {
  id: string;
  ownerName: string;
  businessName: string;
  category: string;
  phone: string;
  email: string;
  availability: string;
  breakMinutesBetweenSlots: string;
}

export interface AvailabilitySlot {
  day: string;
  date?: string | null;
  startTime: string;
  endTime: string;
}

export enum AvailabilityType {
  Weekly = "weekly",
  BiWeekly = "bi_weekly",
  WeeklyRecurring = "weekly_recurring",
}

export interface AvailabilityDetail {
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  weeklyRecurring: boolean;
  date: string | null;
}

export interface AuthenticationScreenProps {
  userType: UserType;
}

export interface CombinedDay {
  label: string;
  date: string;
  highlightMissing: boolean;
}

export interface DailyTrend {
  dayOfWeek: number;
  bookingCount: number;
  averageRevenue: number | null;
}

export interface WeeklyTrend {
  weekNumber: number;
  bookingCount: number;
  averageRevenue: number | null;
}

export interface SeasonalComparison {
  season: string;
  bookingCount: number;
  averageRevenue: number | null;
  year: number;
}

export interface ServicePerformanceMetric {
  serviceId: number;
  serviceName: string;
  bookingCount: number;
  totalRevenue: number | null;
}

export interface ServiceRevenue {
  serviceId: number;
  serviceName: string;
  revenue: number | null;
  percentageOfTotal: number | null;
}

export interface DurationImpact {
  durationRange: string;
  bookingCount: number;
  averageRevenue: number | null;
  cancellationRate: number;
}

export interface ServiceCancellationRate {
  serviceId: number;
  serviceName: string;
  totalBookings: number;
  cancelledBookings: number;
  cancellationRate: number;
}

export interface GrowthMetric {
  monthOverMonthGrowth: number;
  yearOverYearGrowth: number | null;
}

export interface TopCustomer {
  customerId: string;
  customerName: string;
  bookingCount: number;
  totalSpent: number | null;
}

export interface CustomerSegmentMetrics {
  count: number;
  percentage: number;
}

export interface RepeatCustomerMetrics extends CustomerSegmentMetrics {
  averageBookingsPerCustomer: number;
}

export interface CustomerValueSegment {
  segment: string;
  count: number;
  percentage: number;
  averageRevenue: number | null;
}

export interface LeadTimeDistribution {
  range: string;
  count: number;
  percentage: number;
}

export interface CustomerGrowthMetrics {
  monthOverMonthGrowth: number;
  yearOverYearGrowth: number | null;
}

export interface BookingMetrics {
  averageLeadTime: GrowthMetric;
  averageBookingsPerWeek: {
    current: number;
    monthOverMonthGrowth: number;
    yearOverYearGrowth: number | null;
  };
}

export interface BookingVolume {
  totalBookings: number;
  monthOverMonthGrowth: number;
  yearOverYearGrowth: number;
  dailyTrends: DailyTrend[];
  weeklyTrends: WeeklyTrend[];
  seasonalComparison: SeasonalComparison[];
}

export interface ServicePerformance {
  topServices: ServicePerformanceMetric[];
  bottomServices: ServicePerformanceMetric[];
  serviceRevenue: ServiceRevenue[];
  durationImpact: DurationImpact[];
  cancellationRates: ServiceCancellationRate[];
  cancellationGrowth: GrowthMetric;
}

export interface CustomerBehavior {
  topCustomers: TopCustomer[];
  customerSegmentation: {
    repeatCustomers: RepeatCustomerMetrics;
    newCustomers: CustomerSegmentMetrics;
    customerValueSegments: CustomerValueSegment[];
  };
  bookingLeadTime: {
    averageLeadTime: number;
    leadTimeDistribution: LeadTimeDistribution[];
  };
  customerGrowth: {
    repeatCustomers: CustomerGrowthMetrics;
    newCustomers: CustomerGrowthMetrics;
  };
  bookingMetrics: BookingMetrics;
}

export interface BusinessInsights {
  bookingVolume: BookingVolume;
  servicePerformance: ServicePerformance;
  customerBehavior: CustomerBehavior;
}

export interface AvailableInsightsPeriod {
  year: number;
  months: number[];
}
