import styled from "styled-components";
import { Title } from "../styles";

export const MainTitle = styled(Title)({
  fontSize: "28px",
});

export const MainButtons = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
});
