import React, { useMemo } from "react";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLListManager from "../KLListManager";
import KLSlotForm, { SlotFormOptions } from "../KLSlotForm";
import { Slot } from "../../constants/types";
import { NoSlotsText } from "./styles";
import { WarningIcon } from "../../screens/styles";

export interface KLDayAvailabilityProps {
  dayLabel: string;
  date?: string;
  slots: Slot[];
  onAddSlot: () => void;
  onDeleteSlot: (index: number) => void;
  onUpdateSlot: (index: number, slot: Slot) => void;
  isExpanded: boolean;
  onToggle: () => void;
  highlight: boolean;
  getSlotOptions: (
    dayLabel: string,
    slotIndex: number,
  ) => {
    startOptions: SlotFormOptions[];
    endOptions: SlotFormOptions[];
  };
}

const KLDayAvailability: React.FC<KLDayAvailabilityProps> = ({
  dayLabel,
  date,
  slots,
  onAddSlot,
  onDeleteSlot,
  onUpdateSlot,
  isExpanded,
  onToggle,
  highlight,
  getSlotOptions,
}) => {
  const noSlotsText = highlight
    ? "לא הגדרתם עדיין שעות פנויות ביום זה"
    : "אין שעות פנויות ביום זה";

  const filteredSlots = slots.filter((slot) => slot.start && slot.end);
  const slotsPreview =
    filteredSlots.length > 0 ? (
      filteredSlots.map((slot, index) => (
        <div key={index}>{`${slot.start} - ${slot.end}`}</div>
      ))
    ) : (
      <NoSlotsText $highlight={highlight}>{noSlotsText}</NoSlotsText>
    );

  const childrenContent = useMemo(
    () => (
      <KLListManager
        items={slots}
        pluralTerm="שעות פנויות"
        onAdd={onAddSlot}
        noItemsText={noSlotsText}
        renderItem={(slot, index) => (
          <KLSlotForm
            key={index}
            slot={slot}
            onChange={(updatedSlot) => onUpdateSlot(index, updatedSlot)}
            startOptions={getSlotOptions(dayLabel, index).startOptions}
            endOptions={getSlotOptions(dayLabel, index).endOptions}
            onDelete={() => onDeleteSlot(index)}
          />
        )}
      />
    ),
    [
      slots,
      onAddSlot,
      onDeleteSlot,
      onUpdateSlot,
      dayLabel,
      getSlotOptions,
      noSlotsText,
    ],
  );

  return (
    <KLCollapsibleSection
      title={
        <>
          {date
            ? `${dayLabel} | ${new Date(date).toLocaleDateString("he-IL")}`
            : dayLabel}
          {highlight && <WarningIcon>!</WarningIcon>}
        </>
      }
      subtitle={slotsPreview}
      isExpanded={isExpanded}
      onToggle={onToggle}
    >
      {childrenContent}
    </KLCollapsibleSection>
  );
};

export default KLDayAvailability;
