import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useNavigate } from "react-router-dom";

import KLButton from "../../../components/KLButton";
import KLTextInput from "../../../components/KLTextInput";
import { fetchCategories } from "../../../store/duckers/categories/thunk";

import {
  setBusinessCategory,
  setBusinessEmail,
  setBusinessName,
  setBusinessPhone,
  setOwnerName,
} from "../../../store/duckers/businesses/slice";
import KLBusinessOnboardingProgress from "../../../components/KLBusinessOnboardingProgress";
import {
  Container,
  FieldWrapper,
  FormWrapper,
  IconContainer,
  InfoText,
  TextIconContainer,
  Title,
} from "../../styles";
import { useStep } from "../../../context/StepContext";
import KLPleaseWait from "../../../components/KLPleaseWait";
import { EmojiPeople } from "@mui/icons-material";
import KLSelect from "../../../components/KLSelect";
import { FlowType, BusinessRouteStep } from "../../../constants/routes";
import { StorageKey, StorageKeys } from "../../../constants/localStorage";
import useLocalStorage from "../../../hooks/useLocalStorage";

const OwnerInfoScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted } = useStep();
  const dispatch = useDispatch<AppDispatch>();

  const { storedValue: storedPhone } = useLocalStorage<StorageKey | null>(
    StorageKeys.BusinessPhone,
    null,
  );

  const {
    businessName: storedBusinessName,
    ownerName: storedOwnerName,
    email: storedEmail,
    category: storedCategory,
  } = useSelector((state: RootState) => state.businesses);

  const { fetchingCategories, error, categories } = useSelector(
    (state: RootState) => state.categories,
  );

  const [businessName, setBusinessNameState] = useState<string>(
    storedBusinessName || "",
  );
  const [ownerName, setOwnerNameState] = useState<string>(
    storedOwnerName || "",
  );
  const [email, setEmail] = useState<string>(storedEmail || "");

  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    storedCategory || null,
  );

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isCategoryValid, setIsCategoryValid] = useState<boolean>(true);

  const isFormValid = useMemo(() => {
    return (
      businessName.trim() !== "" &&
      ownerName.trim() !== "" &&
      email.trim() !== "" &&
      isEmailValid &&
      selectedCategory !== null &&
      isCategoryValid
    );
  }, [
    businessName,
    ownerName,
    email,
    isEmailValid,
    selectedCategory,
    isCategoryValid,
  ]);

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid && storedPhone) {
      dispatch(setBusinessName(businessName));
      dispatch(setOwnerName(ownerName));
      dispatch(setBusinessPhone(storedPhone));
      dispatch(setBusinessEmail(email));
      dispatch(setBusinessCategory(selectedCategory));
      markStepAsCompleted(FlowType.Business, BusinessRouteStep.Info);
      navigate(`/${FlowType.Business}/services`);
    } else {
      alert("Please fill in all fields correctly");
    }
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedCategory(value || null);

    if (value) {
      setIsCategoryValid(true);
    } else {
      setIsCategoryValid(false);
    }
  };

  return (
    <Container>
      <KLBusinessOnboardingProgress currentStep={1} />
      {fetchingCategories ? (
        <KLPleaseWait text="בודקים כמה פרטים במערכת" />
      ) : error ? (
        <>
          <Title>אופס!</Title>
          <InfoText>משהו לא עבד איפשהו... רוצים לנסות שוב?</InfoText>
        </>
      ) : (
        <>
          <TextIconContainer>
            <IconContainer>
              <EmojiPeople />
            </IconContainer>
            <Title>עם מי יש לנו הכבוד?</Title>
          </TextIconContainer>
          <InfoText>נשמח קצת להכיר אתכם ואת העסק שלכם</InfoText>
          <FormWrapper onSubmit={handleConfirm}>
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם ומשפחה"
                value={ownerName}
                onChange={(e) => setOwnerNameState(e.target.value)}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="text"
                placeholder="שם העסק"
                value={businessName}
                onChange={(e) => setBusinessNameState(e.target.value)}
                required
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLSelect
                value={selectedCategory || ""}
                onChange={handleCategoryChange}
                placeholder="סוג העסק"
                required
                options={categories.map((category) => ({
                  value: category,
                  label: category,
                }))}
              />
            </FieldWrapper>
            <FieldWrapper>
              <KLTextInput
                type="email"
                placeholder="אימייל"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onValidationChange={setIsEmailValid}
                required
              />
            </FieldWrapper>
            <KLButton title={"ממשיכים"} disabled={!isFormValid} />
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default OwnerInfoScreen;
