import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStep } from "../../context/StepContext";
import { ServiceWithId, UserType } from "../../constants/types";
import ServiceSelection from "../../components/KLServiceSelection";
import { CustomerRouteStep, FlowType } from "../../constants/routes";

const CustomerServicesScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted } = useStep();
  const { businessId } = useParams<{ businessId: string }>();

  if (!businessId) {
    return <Navigate to="/not-authorized" replace />;
  }

  const handleConfirm = (service: ServiceWithId) => {
    markStepAsCompleted(FlowType.Customer, CustomerRouteStep.Services);
    navigate(`/${businessId}/slots`);
  };

  return (
    <ServiceSelection
      businessId={businessId}
      userType={UserType.Customer}
      onConfirm={handleConfirm}
    />
  );
};

export default CustomerServicesScreen;
