import React from "react";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { KLSlot } from "../../constants/types";
import KLChangeUpcomingSlot from "../KLChangeUpcomingSlot";
import { CloseButton, ModalBox, ModalHeader } from "../../screens/styles";

interface KLChangeUpcomingSlotModalProps {
  open: boolean;
  handleClose: () => void;
  slot: KLSlot;
  term?: string;
  businessId: string;
}

const KLChangeUpcomingSlotModal: React.FC<KLChangeUpcomingSlotModalProps> = ({
  open,
  handleClose,
  slot,
  term,
  businessId,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="change-slot-modal-title"
      aria-describedby="change-slot-modal-description"
    >
      <ModalBox>
        <ModalHeader>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <KLChangeUpcomingSlot
          businessId={businessId}
          slot={slot}
          term={term}
          onClose={handleClose}
        />
      </ModalBox>
    </Modal>
  );
};

export default KLChangeUpcomingSlotModal;
