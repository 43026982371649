import styled from "styled-components";
import colors from "../../constants/colors";

export const MonthsContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.75rem",
  justifyContent: "flex-start",
  width: "100%",
});

export const MonthButton = styled("button")({
  backgroundColor: colors.PURPLE_LIGHTEST,
  color: colors.BLACK,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: "8px",
  padding: "0.75rem 1rem",
  fontSize: "18px",
  fontWeight: 600,
  cursor: "pointer",
  transition: "background-color 0.2s ease",
  minWidth: "100%",
  textAlign: "justify",

  "&:active": {
    backgroundColor: colors.PURPLE_LIGHTER,
  },
});

export const NoDataMessage = styled("div")({
  color: colors.GREY_7,
  fontSize: "15px",
  textAlign: "center",
  padding: "1rem",
  width: "100%",
});
