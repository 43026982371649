import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { AvailabilitySlot, AvailabilityType, Slot } from "../constants/types";
import { daysOfWeek, dayMapping, generateTimeOptions } from "../utils";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { RootState } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { resetUpdateAvailabilitySucceeded } from "../store/duckers/businesses/slice";
import { format } from "date-fns";
import { DateTime } from "luxon";

interface UseAvailabilityProps {
  initialAvailableSlots?: {
    availabilityType?: AvailabilityType;
    breakMinutesBetweenSlots?: string;
    slots?: AvailabilitySlot[];
  };
}

interface UseAvailabilityReturn {
  availabilityType: AvailabilityType | null;
  setAvailabilityType: (type: AvailabilityType | null) => void;
  breakMinutesBetweenSlots: string | null;
  setBreakMinutesBetweenSlots: (value: string | null) => void;
  slotsByDay: { [key: string]: Slot[] };
  setSlotsByDay: Dispatch<SetStateAction<{ [key: string]: Slot[] }>>;
  futureDateDayMap: { [dayLabel: string]: string };
  currentDateDayMap: { [dayLabel: string]: string };
  setDateDayMap: Dispatch<SetStateAction<{ [dayLabel: string]: string }>>;
  expandedDay: string | null;
  setExpandedDay: Dispatch<SetStateAction<string | null>>;
  isFormValid: boolean;
  getSlotOptions: (
    dayLabel: string,
    date: string,
    slotIndex: number,
  ) => {
    startOptions: { label: string; value: string }[];
    endOptions: { label: string; value: string }[];
  };
  handleDayClick: (dayLabel: string, date: string) => void;
  isDayExpanded: (dayLabel: string, date: string) => boolean;
  handleAddSlot: (dayLabel: string, date: string) => void;
  handleDeleteSlot: (dayLabel: string, date: string, index: number) => void;
  handleUpdateSlot: (
    dayLabel: string,
    date: string,
    index: number,
    updatedSlot: Slot,
  ) => void;
  buildSlotsPayload: () => AvailabilitySlot[];
}

const useAvailability = ({
  initialAvailableSlots = {},
}: UseAvailabilityProps): UseAvailabilityReturn => {
  const [availabilityType, setAvailabilityType] =
    useState<AvailabilityType | null>(
      initialAvailableSlots.availabilityType ?? null,
    );
  const [breakMinutesBetweenSlots, setBreakMinutesBetweenSlots] = useState<
    string | null
  >(initialAvailableSlots.breakMinutesBetweenSlots ?? null);

  const [currentDateDayMap, setCurrentDateDayMap] = useState<{
    [dayLabel: string]: string;
  }>({});

  const [futureDateDayMap, setFutureDateDayMap] = useState<{
    [dayLabel: string]: string;
  }>({});

  const [slotsByDay, setSlotsByDay] = useState<{ [key: string]: Slot[] }>(
    () => ({}),
  );
  const [expandedDay, setExpandedDay] = useState<string | null>(null);

  const { updateAvailabilitySucceeded } = useSelector(
    (state: RootState) => state.businesses,
  );
  const dispatch = useDispatch();

  const initialAvailableSlotsRef = useRef<{
    availabilityType?: AvailabilityType;
    breakMinutesBetweenSlots?: string;
    slots?: AvailabilitySlot[];
  } | null>(initialAvailableSlots && cloneDeep(initialAvailableSlots));

  const weekdayTimes = useMemo(
    () => generateTimeOptions("07:00", "22:00", 15),
    [],
  );
  const fridayTimes = useMemo(
    () => generateTimeOptions("08:00", "17:00", 15),
    [],
  );

  const getCompositeKey = useCallback(
    (dayLabel: string, date: string) => {
      return availabilityType === AvailabilityType.WeeklyRecurring
        ? dayLabel
        : `${dayLabel}|${date}`;
    },
    [availabilityType],
  );

  useEffect(() => {
    if (initialAvailableSlots.slots && initialAvailableSlots.slots.length > 0) {
      const incoming: { [key: string]: Slot[] } = {};
      initialAvailableSlots.slots.forEach((slot) => {
        const dayLabel = dayMapping[slot.day] || slot.day;
        const key = getCompositeKey(dayLabel, slot.date || "");
        if (!incoming[key]) incoming[key] = [];
        incoming[key].push({
          start: slot.startTime,
          end: slot.endTime,
        });
      });
      setSlotsByDay(incoming);
    }
  }, [initialAvailableSlots, getCompositeKey]);

  useEffect(() => {
    if (
      initialAvailableSlots.availabilityType &&
      initialAvailableSlots.availabilityType !==
        AvailabilityType.WeeklyRecurring &&
      initialAvailableSlots.slots &&
      initialAvailableSlots.slots.length > 0
    ) {
      const now = DateTime.now().setZone("Asia/Jerusalem");
      const windowStart =
        now.hour >= 20
          ? now.plus({ days: 1 }).startOf("day")
          : now.startOf("day");
      const startISO = windowStart.toISODate()!;

      const currentMap: { [dayLabel: string]: string } = {};
      Object.keys(slotsByDay).forEach((key) => {
        const [dayLabel, date] = key.split("|");
        if (date && date >= startISO) {
          const slots = slotsByDay[key];
          const hasValid = slots.some((slot) => slot.start && slot.end);
          if (hasValid) {
            currentMap[dayLabel] = date;
          }
        }
      });
      setCurrentDateDayMap(currentMap);

      const buildRequiredMap = (
        start: string,
      ): { [dayLabel: string]: string } => {
        const required: { [dayLabel: string]: string } = {};
        let count = 0;
        let offset = 0;
        while (count < 6) {
          const d = new Date(start);
          d.setDate(d.getDate() + offset);
          offset++;
          if (d.getDay() === 6) continue;
          const dayObj = daysOfWeek.find((dObj) => dObj.index === d.getDay());
          if (dayObj) {
            const label = dayMapping[dayObj.label] || dayObj.label;
            required[label] = format(d, "yyyy-MM-dd");
            count++;
          }
        }
        return required;
      };
      const requiredMap = buildRequiredMap(startISO);
      const futureMap: { [dayLabel: string]: string } = {};
      Object.entries(requiredMap).forEach(([label, date]) => {
        if (!currentMap[label]) futureMap[label] = date;
      });
      setFutureDateDayMap(futureMap);
    } else if (
      initialAvailableSlots.availabilityType ===
      AvailabilityType.WeeklyRecurring
    ) {
      setCurrentDateDayMap({});
      const fullMap = daysOfWeek.reduce(
        (acc, day) => {
          const label = dayMapping[day.label] || day.label;
          acc[label] = "";
          return acc;
        },
        {} as { [key: string]: string },
      );
      setFutureDateDayMap(fullMap);
    } else if (
      (!initialAvailableSlots.slots ||
        initialAvailableSlots.slots.length === 0) &&
      (availabilityType === AvailabilityType.Weekly ||
        availabilityType === AvailabilityType.BiWeekly)
    ) {
      const now = DateTime.now().setZone("Asia/Jerusalem");
      const windowStart =
        now.hour >= 20
          ? now.plus({ days: 1 }).startOf("day")
          : now.startOf("day");
      const startISO = windowStart.toISODate()!;
      const requiredMap = (() => {
        const required: { [dayLabel: string]: string } = {};
        let count = 0;
        let offset = 0;
        while (count < 6) {
          const d = new Date(startISO);
          d.setDate(d.getDate() + offset);
          offset++;
          if (d.getDay() === 6) continue;
          const dayObj = daysOfWeek.find((dObj) => dObj.index === d.getDay());
          if (dayObj) {
            const label = dayMapping[dayObj.label] || dayObj.label;
            required[label] = format(d, "yyyy-MM-dd");
            count++;
          }
        }
        return required;
      })();
      setCurrentDateDayMap({});
      setFutureDateDayMap(requiredMap);
    } else {
      setFutureDateDayMap({});
    }
  }, [initialAvailableSlots, availabilityType, slotsByDay]);

  const getSlotOptions = (
    dayLabel: string,
    date: string,
    slotIndex: number,
  ) => {
    const isFriday = dayLabel === "שישי";
    const availableTimes = isFriday ? fridayTimes : weekdayTimes;
    const key = getCompositeKey(dayLabel, date);
    const slotsForDay = slotsByDay[key] || [];
    const previousEndTime = slotsForDay
      .slice(0, slotIndex)
      .map((slot) => slot.end)
      .reduce((latest, time) => (time > latest ? time : latest), "00:00");
    const startOptions = availableTimes.filter(
      (time) => time.value >= previousEndTime,
    );
    const selectedStartTime = slotsForDay[slotIndex]?.start || "";
    const endOptions = selectedStartTime
      ? startOptions.filter((time) => time.value > selectedStartTime)
      : [];
    return {
      startOptions: startOptions.map((option) => ({
        label: option.label,
        value: option.value,
      })),
      endOptions: endOptions.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    };
  };

  const handleDayClick = (dayLabel: string, date: string) => {
    const key = getCompositeKey(dayLabel, date);
    setExpandedDay((prev) => (prev === key ? "" : key));
  };

  const isDayExpanded = (dayLabel: string, date: string) => {
    const key = getCompositeKey(dayLabel, date);
    return expandedDay === key;
  };

  const handleAddSlot = (dayLabel: string, date: string) => {
    const key = getCompositeKey(dayLabel, date);
    setSlotsByDay((prev) => ({
      ...prev,
      [key]: [...(prev[key] || []), { id: "", start: "", end: "" }],
    }));
  };

  const handleDeleteSlot = (dayLabel: string, date: string, index: number) => {
    const key = getCompositeKey(dayLabel, date);
    setSlotsByDay((prev) => ({
      ...prev,
      [key]: prev[key].filter((_, i) => i !== index),
    }));
  };

  const handleUpdateSlot = (
    dayLabel: string,
    date: string,
    index: number,
    updatedSlot: Slot,
  ) => {
    const key = getCompositeKey(dayLabel, date);
    setSlotsByDay((prev) => ({
      ...prev,
      [key]: prev[key].map((slot, i) => (i === index ? updatedSlot : slot)),
    }));
  };

  const buildSlotsPayload = useCallback((): AvailabilitySlot[] => {
    const converted: AvailabilitySlot[] = [];
    const todayISO = DateTime.now().setZone("Asia/Jerusalem").toISODate()!;
    Object.keys(slotsByDay).forEach((key) => {
      let dayLabel: string, date: string;
      if (availabilityType === AvailabilityType.WeeklyRecurring) {
        dayLabel = key;
        date = "";
      } else {
        [dayLabel, date] = key.split("|");
        if (!date || date < todayISO) return;
      }
      const slots = slotsByDay[key];
      slots.forEach((slot) => {
        if (slot.start === "" && slot.end === "") return;
        converted.push({
          day: dayLabel,
          date,
          startTime: slot.start,
          endTime: slot.end,
        });
      });
    });
    return converted;
  }, [slotsByDay, availabilityType]);

  const hasChanges = useMemo(() => {
    if (!initialAvailableSlotsRef.current) return false;
    const currentPayload = buildSlotsPayload();
    const initialPayload = initialAvailableSlotsRef.current.slots || [];
    return (
      !isEqual(currentPayload, initialPayload) ||
      availabilityType !== initialAvailableSlotsRef.current.availabilityType ||
      breakMinutesBetweenSlots !==
        initialAvailableSlotsRef.current.breakMinutesBetweenSlots
    );
  }, [buildSlotsPayload, availabilityType, breakMinutesBetweenSlots]);

  const isFormValid = useMemo(() => {
    const allLabels = new Set<string>([
      ...Object.keys(currentDateDayMap),
      ...Object.keys(futureDateDayMap),
    ]);
    const displayedDays = Array.from(allLabels).map((label) => {
      const date = currentDateDayMap[label] || futureDateDayMap[label] || "";
      const key =
        availabilityType === AvailabilityType.WeeklyRecurring
          ? label
          : `${label}|${date}`;
      return { label, date, compositeKey: key };
    });
    const allSlotsAreValid = displayedDays.every(({ compositeKey }) => {
      const slots = slotsByDay[compositeKey] || [];
      if (slots.length === 0) return true;
      return slots.every(
        (slot) =>
          (slot.start && slot.end) || (slot.start === "" && slot.end === ""),
      );
    });
    const baseValid =
      availabilityType !== null &&
      breakMinutesBetweenSlots !== null &&
      breakMinutesBetweenSlots !== "" &&
      allSlotsAreValid;
    if (initialAvailableSlotsRef.current) {
      return baseValid && hasChanges;
    }
    return baseValid;
  }, [
    slotsByDay,
    availabilityType,
    breakMinutesBetweenSlots,
    hasChanges,
    currentDateDayMap,
    futureDateDayMap,
  ]);

  useEffect(() => {
    if (updateAvailabilitySucceeded) {
      const updatedPayload = buildSlotsPayload();
      initialAvailableSlotsRef.current = {
        availabilityType: availabilityType as AvailabilityType,
        breakMinutesBetweenSlots: breakMinutesBetweenSlots as string,
        slots: updatedPayload,
      };
      if (availabilityType === AvailabilityType.WeeklyRecurring) {
        setCurrentDateDayMap({});
        const fullMap = daysOfWeek.reduce(
          (acc, day) => {
            const label = dayMapping[day.label] || day.label;
            acc[label] = "";
            return acc;
          },
          {} as { [key: string]: string },
        );
        setFutureDateDayMap(fullMap);
      } else if (
        availabilityType === AvailabilityType.Weekly ||
        availabilityType === AvailabilityType.BiWeekly
      ) {
        const now = DateTime.now().setZone("Asia/Jerusalem");
        const windowStart =
          now.hour >= 20
            ? now.plus({ days: 1 }).startOf("day")
            : now.startOf("day");
        const startISO = windowStart.toISODate()!;
        const buildRequiredMap = (
          start: string,
        ): { [dayLabel: string]: string } => {
          const required: { [dayLabel: string]: string } = {};
          let count = 0;
          let offset = 0;
          while (count < 6) {
            const d = new Date(start);
            d.setDate(d.getDate() + offset);
            offset++;
            if (d.getDay() === 6) continue;
            const dayObj = daysOfWeek.find((dObj) => dObj.index === d.getDay());
            if (dayObj) {
              const label = dayMapping[dayObj.label] || dayObj.label;
              required[label] = format(d, "yyyy-MM-dd");
              count++;
            }
          }
          return required;
        };
        const requiredMap = buildRequiredMap(startISO);
        const newCurrentMap: { [dayLabel: string]: string } = {};
        updatedPayload.forEach((slot) => {
          if (slot.startTime && slot.endTime) {
            newCurrentMap[slot.day] = slot.date || "";
          }
        });
        setCurrentDateDayMap(newCurrentMap);
        const newFutureMap: { [dayLabel: string]: string } = {};
        Object.entries(requiredMap).forEach(([label, date]) => {
          if (!newCurrentMap[label]) {
            newFutureMap[label] = date;
          }
        });
        setFutureDateDayMap(newFutureMap);
      } else {
        setFutureDateDayMap({});
      }
      dispatch(resetUpdateAvailabilitySucceeded());
    }
  }, [
    updateAvailabilitySucceeded,
    availabilityType,
    breakMinutesBetweenSlots,
    buildSlotsPayload,
    dispatch,
    currentDateDayMap,
  ]);

  return {
    availabilityType,
    setAvailabilityType,
    breakMinutesBetweenSlots,
    setBreakMinutesBetweenSlots,
    slotsByDay,
    setSlotsByDay,
    futureDateDayMap,
    currentDateDayMap,
    setDateDayMap: setFutureDateDayMap,
    expandedDay,
    setExpandedDay,
    isFormValid,
    getSlotOptions,
    handleDayClick,
    isDayExpanded,
    handleAddSlot,
    handleDeleteSlot,
    handleUpdateSlot,
    buildSlotsPayload,
  };
};

export default useAvailability;
