import apiClient from "../api/apiClient";

class OtpService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async sendOtp(
    phoneNumber: string,
    userType: string,
  ): Promise<{ message: string; otpId: number }> {
    try {
      const response = await apiClient.post<{ message: string; otpId: number }>(
        `${this.baseApiUrl}/api/auth/generate-otp`,
        { phoneNumber, userType },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.data || typeof response.data.otpId !== "number") {
        throw new Error("Invalid OTP response structure");
      }

      return response.data;
    } catch (e: any) {
      throw new Error(e.message || "Failed to send OTP");
    }
  }

  async verifyOtp(
    phoneNumber: string,
    userType: string,
    otpCode: string,
  ): Promise<string | undefined> {
    try {
      const response = await apiClient.post(
        `${this.baseApiUrl}/api/auth/verify-otp`,
        { phoneNumber, userType, otpCode },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status < 200 || response.status >= 300) {
        const serverMessage =
          response.data?.message ||
          response.data?.error ||
          "Failed to verify OTP";
        throw new Error(serverMessage);
      }
      const { token } = response.data;
      return token;
    } catch (err: any) {
      if (err.response && err.response.data) {
        const serverMessage =
          err.response.data.message || err.response.data.error || err.message;
        throw new Error(serverMessage);
      } else {
        throw new Error(err.message || "Failed to verify OTP");
      }
    }
  }
}

export const otpService = new OtpService();
