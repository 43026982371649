import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KLSlot } from "../../constants/types";
import { AppDispatch, RootState } from "../../store";
import KLCollapsibleSection from "../KLCollapsibleSection";
import KLListManager from "../KLListManager";
import KLUpcomingSlotItem from "../KLUpcomingSlotItem";
import { cancelSlot } from "../../store/duckers/slots/thunk";
import { sortSlotsByStartTime } from "../../utils";
import { BusinessCategoryGender } from "../../constants/customers/categoryConfigMap";

interface KLDashboardUpcomingSlotsProps {
  businessId: string;
  term: string;
  pluralTerm: string;
  gender: BusinessCategoryGender;
}

const KLDashboardUpcomingSlots: React.FC<KLDashboardUpcomingSlotsProps> = ({
  businessId,
  term,
  pluralTerm,
  gender,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const bookedSlots = useSelector(
    (state: RootState) => state.businesses.bookedSlots,
  );

  const { cancelSlotSucceeded, error } = useSelector(
    (state: RootState) => state.slots,
  );

  const [cancellingSlotId, setCancellingSlotId] = useState<string | null>(null);

  const [isMainSectionExpanded, setIsMainSectionExpanded] = useState(true);
  const [expandedSections, setExpandedSections] = useState<{
    today: boolean;
    next7Days: boolean;
    beyond7Days: boolean;
  }>({
    today: false,
    next7Days: false,
    beyond7Days: false,
  });

  const categorizedSlots = useMemo(() => {
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    const endOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
    );

    const next7Days = new Date(today);
    next7Days.setDate(today.getDate() + 7);

    const categories = {
      today: [] as KLSlot[],
      next7Days: [] as KLSlot[],
      beyond7Days: [] as KLSlot[],
    };

    bookedSlots.forEach((slot) => {
      const slotStart = new Date(slot.start);

      if (slotStart >= startOfToday && slotStart <= endOfToday) {
        categories.today.push(slot);
      } else if (slotStart > endOfToday && slotStart <= next7Days) {
        categories.next7Days.push(slot);
      } else if (slotStart > next7Days) {
        categories.beyond7Days.push(slot);
      }
    });

    categories.today = sortSlotsByStartTime(categories.today);
    categories.next7Days = sortSlotsByStartTime(categories.next7Days);
    categories.beyond7Days = sortSlotsByStartTime(categories.beyond7Days);

    return categories;
  }, [bookedSlots]);

  const handleMainSectionToggle = () => {
    setIsMainSectionExpanded((prev) => !prev);
  };

  const handleInnerSectionToggle = (section: keyof typeof expandedSections) => {
    setExpandedSections((prev) => {
      const updatedSections = Object.keys(prev).reduce(
        (acc, key) => {
          acc[key as keyof typeof expandedSections] = false;
          return acc;
        },
        {} as typeof prev,
      );
      updatedSections[section] = !prev[section];
      return updatedSections;
    });
  };

  useEffect(() => {
    if (cancellingSlotId && (cancelSlotSucceeded || error)) {
      setCancellingSlotId(null);
    }
  }, [cancelSlotSucceeded, cancellingSlotId, error]);

  const handleCancelRequest = (slotId: string) => {
    if (!businessId) {
      return;
    }
    setCancellingSlotId(slotId);
    dispatch(cancelSlot({ businessId, slotId, skipNotification: true }));
  };

  const renderSlotItem = (
    slot: KLSlot,
    index: number,
    array: KLSlot[],
    term?: string,
  ) => {
    const isLast = index === array.length - 1;
    return (
      <KLUpcomingSlotItem
        key={slot.id}
        slot={slot}
        term={term}
        isCancelling={cancellingSlotId === slot.id}
        onCancel={() => handleCancelRequest(slot.id)}
        isLast={isLast && array.length > 0}
      />
    );
  };

  return (
    <KLCollapsibleSection
      title={`ה${pluralTerm} ${gender === BusinessCategoryGender.Male ? "העתידיים" : "העתידיות"} שלי`}
      isExpanded={isMainSectionExpanded}
      onToggle={handleMainSectionToggle}
      count={
        categorizedSlots.today.length +
          categorizedSlots.next7Days.length +
          categorizedSlots.beyond7Days.length || undefined
      }
      secondary
    >
      <KLCollapsibleSection
        title={`${pluralTerm} להיום`}
        isExpanded={isMainSectionExpanded ? expandedSections.today : false}
        onToggle={() => handleInnerSectionToggle("today")}
        count={categorizedSlots.today.length || undefined}
      >
        <KLListManager
          items={categorizedSlots.today}
          term={term}
          pluralTerm={pluralTerm}
          noItemsText={`אין כרגע ${pluralTerm} להיום.`}
          renderItem={renderSlotItem}
        />
      </KLCollapsibleSection>

      <KLCollapsibleSection
        title={`${pluralTerm} בשבוע הקרוב`}
        isExpanded={isMainSectionExpanded ? expandedSections.next7Days : false}
        onToggle={() => handleInnerSectionToggle("next7Days")}
        count={categorizedSlots.next7Days.length || undefined}
      >
        <KLListManager
          items={categorizedSlots.next7Days}
          term={term}
          pluralTerm={pluralTerm}
          noItemsText={`אין כרגע ${pluralTerm} בשבוע הקרוב.`}
          renderItem={renderSlotItem}
        />
      </KLCollapsibleSection>

      <KLCollapsibleSection
        title={`${pluralTerm} בהמשך`}
        isExpanded={
          isMainSectionExpanded ? expandedSections.beyond7Days : false
        }
        onToggle={() => handleInnerSectionToggle("beyond7Days")}
        count={categorizedSlots.beyond7Days.length || undefined}
      >
        <KLListManager
          items={categorizedSlots.beyond7Days}
          term={term}
          pluralTerm={pluralTerm}
          noItemsText={`אין כרגע ${pluralTerm} בהמשך.`}
          renderItem={renderSlotItem}
        />
      </KLCollapsibleSection>
    </KLCollapsibleSection>
  );
};

export default KLDashboardUpcomingSlots;
