import React, { ReactNode } from "react";
import { NoItemsText } from "./styles";
import KLButton from "../KLButton";

export interface KLListManagerProps<T> {
  items: T[];
  term?: string;
  pluralTerm: string;
  onAdd?: () => void;
  noItemsText: string;
  renderItem: (item: T, index: number, array: T[], term?: string) => ReactNode;
}

const KLListManager = <T,>({
  items,
  term,
  pluralTerm,
  onAdd,
  noItemsText,
  renderItem,
}: KLListManagerProps<T>) => {
  return (
    <div>
      {items.length > 0 ? (
        items.map((item, index, array) => renderItem(item, index, array, term))
      ) : (
        <NoItemsText>{noItemsText}</NoItemsText>
      )}
      {onAdd && (
        <KLButton
          title={`הוסיפו עוד ${pluralTerm}`}
          onClick={onAdd}
          secondary
        />
      )}
    </div>
  );
};

export default KLListManager;
