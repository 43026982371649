import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkCustomerSlots,
  fetchBusiness,
  addNewCustomer,
  fetchCustomerDetails,
  fetchAllCustomers,
} from "./thunk";
import {
  Business,
  Customer,
  CustomerSlot,
  CustomerWithLatestBooking,
  ServiceWithId,
  UserType,
} from "../../../constants/types";
import { bookSlot, cancelSlot, changeSlot } from "../slots/thunk";

interface CustomersState {
  customerName: string;
  customerPhone: string;
  customerService: ServiceWithId | null;
  business: Business | null;
  fetchingBusiness: boolean;
  fetchingCustomerSlots: boolean;
  fetchCustomerSlotsSucceeded: boolean;
  customerSlots: CustomerSlot[];
  addingCustomer: boolean;
  addCustomerSucceeded: boolean;
  fetchingCustomerDetails: boolean;
  fetchCustomerDetailsSucceeded: boolean;
  customer: Customer | null;
  allCustomers: CustomerWithLatestBooking[];
  fetchingAllCustomers: boolean;
  fetchAllCustomersSucceeded: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  customerName: "",
  customerPhone: "",
  customerService: null,
  business: null,
  fetchingBusiness: false,
  fetchingCustomerSlots: false,
  fetchCustomerSlotsSucceeded: false,
  customerSlots: [],
  addingCustomer: false,
  addCustomerSucceeded: false,
  fetchingCustomerDetails: false,
  fetchCustomerDetailsSucceeded: false,
  customer: null,
  allCustomers: [],
  fetchingAllCustomers: false,
  fetchAllCustomersSucceeded: false,
  error: null,
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomerName: (state, action: PayloadAction<string>) => {
      state.customerName = action.payload;
    },
    setCustomerPhone: (state, action: PayloadAction<string>) => {
      state.customerPhone = action.payload;
    },
    setCustomerService: (state, action: PayloadAction<ServiceWithId>) => {
      state.customerService = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusiness.pending, (state) => {
        state.fetchingBusiness = true;
        state.error = null;
      })
      .addCase(
        fetchBusiness.fulfilled,
        (state, action: PayloadAction<Business>) => {
          state.business = action.payload;
          state.fetchingBusiness = false;
        },
      )
      .addCase(fetchBusiness.rejected, (state, action) => {
        state.error = action.payload || "Failed to fetch business";
        state.fetchingBusiness = false;
      })
      .addCase(checkCustomerSlots.pending, (state) => {
        state.fetchingCustomerSlots = true;
        state.error = null;
      })
      .addCase(
        checkCustomerSlots.fulfilled,
        (state, action: PayloadAction<{ customerSlots: CustomerSlot[] }>) => {
          state.fetchingCustomerSlots = false;
          state.fetchCustomerSlotsSucceeded = true;
          state.customerSlots = action.payload.customerSlots;
        },
      )
      .addCase(checkCustomerSlots.rejected, (state, action) => {
        state.fetchingCustomerSlots = false;
        state.error = action.payload || "Failed to fetch customer slots";
      })
      .addCase(cancelSlot.fulfilled, (state, action: PayloadAction<string>) => {
        state.customerSlots = state.customerSlots.filter(
          (slot) => slot.id !== action.payload,
        );
      })
      .addCase(addNewCustomer.pending, (state) => {
        state.addingCustomer = true;
        state.addCustomerSucceeded = false;
        state.error = null;
      })
      .addCase(addNewCustomer.fulfilled, (state, action) => {
        state.addingCustomer = false;
        state.addCustomerSucceeded = true;
        if (action.payload.userType === UserType.Business) {
          const { phone, name } = action.meta.arg;
          state.allCustomers.unshift({
            id: action.payload.customerId,
            name,
            phone,
            latestBookingStartTime: "",
            latestBookingEndTime: "",
            serviceName: "",
          });
        }
      })
      .addCase(addNewCustomer.rejected, (state, action) => {
        state.addingCustomer = false;
        state.addCustomerSucceeded = false;
        state.error = action.payload || "Failed to add/update customer";
      })
      .addCase(fetchCustomerDetails.pending, (state) => {
        state.fetchingCustomerDetails = true;
        state.fetchCustomerDetailsSucceeded = false;
        state.customer = null;
        state.error = null;
      })
      .addCase(
        fetchCustomerDetails.fulfilled,
        (state, action: PayloadAction<{ customer: Customer | null }>) => {
          state.fetchingCustomerDetails = false;
          state.fetchCustomerDetailsSucceeded = true;
          state.customer = action.payload.customer;
          if (action.payload.customer) {
            state.customerName = action.payload.customer.name;
            state.customerPhone = action.payload.customer.phone;
          } else {
            state.customerName = "";
            state.customerPhone = "";
          }
        },
      )
      .addCase(fetchCustomerDetails.rejected, (state, action) => {
        state.fetchingCustomerDetails = false;
        state.fetchCustomerDetailsSucceeded = false;
        state.customer = null;
        state.error = action.payload || "Failed to fetch customer details";
      })
      .addCase(fetchAllCustomers.pending, (state) => {
        state.fetchingAllCustomers = true;
        state.fetchAllCustomersSucceeded = false;
        state.error = null;
      })
      .addCase(fetchAllCustomers.fulfilled, (state, action) => {
        state.fetchingAllCustomers = false;
        state.fetchAllCustomersSucceeded = true;
        state.allCustomers = action.payload.customers;
      })
      .addCase(fetchAllCustomers.rejected, (state, action) => {
        state.fetchingAllCustomers = false;
        state.fetchAllCustomersSucceeded = false;
        state.error = action.payload || "Failed to fetch all customers";
      })
      .addCase(
        bookSlot.fulfilled,
        (
          state,
          action: PayloadAction<
            { customerPhone?: string; latestBooking: CustomerSlot },
            string,
            { arg: { userType: UserType; customerPhone?: string } }
          >,
        ) => {
          if (
            action.meta.arg.userType !== UserType.Business ||
            !action.meta.arg.customerPhone
          ) {
            return;
          }
          const { customerPhone, latestBooking } = action.payload;
          const index = state.allCustomers.findIndex(
            (customer) => customer.phone === customerPhone,
          );
          if (index !== -1) {
            state.allCustomers[index].latestBookingStartTime =
              latestBooking.start;
            state.allCustomers[index].latestBookingEndTime = latestBooking.end;
            state.allCustomers[index].serviceName = latestBooking.service;
          }
        },
      )
      .addCase(
        changeSlot.fulfilled,
        (
          state,
          action: PayloadAction<
            {
              updatedSlot: { id: string; start: string; end: string };
              customerPhone?: string;
            },
            string,
            { arg: { customerPhone?: string } }
          >,
        ) => {
          if (!action.meta.arg.customerPhone) return;
          const customerPhone = action.meta.arg.customerPhone;
          const { updatedSlot } = action.payload;
          const index = state.allCustomers.findIndex(
            (customer) => customer.phone === customerPhone,
          );
          if (index !== -1) {
            state.allCustomers[index].latestBookingStartTime =
              updatedSlot.start;
            state.allCustomers[index].latestBookingEndTime = updatedSlot.end;
          }
        },
      );
  },
});

export const { setCustomerName, setCustomerPhone, setCustomerService } =
  customersSlice.actions;

export default customersSlice.reducer;
