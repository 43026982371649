import styled from "styled-components";
import colors from "../../constants/colors";

export const SlotContent = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const TimeRow = styled.div({
  display: "flex",
  marginTop: "0.75rem",
  gap: "0.75rem",
});

export const DropdownWrapper = styled.div({
  flex: 1,
  marginRight: "0.75rem",
  "&:last-child": {
    marginRight: "0",
  },
});

export const DeleteButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

export const DeleteButton = styled("button")({
  background: "none",
  border: "none",
  color: colors.RED,
  cursor: "pointer",
  fontSize: "1.5rem",
  marginRight: "1rem",
  padding: "0",
  display: "flex",
  alignItems: "center",
  height: "40px",
});
