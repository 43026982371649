import { format } from "date-fns";
import { StorageKey } from "./constants/localStorage";
import { DateTime } from "luxon";
import { AvailabilityType, CombinedDay, KLSlot } from "./constants/types";

export const dayMapping: { [key: string]: string } = {
  Sunday: "ראשון",
  Monday: "שני",
  Tuesday: "שלישי",
  Wednesday: "רביעי",
  Thursday: "חמישי",
  Friday: "שישי",
  Saturday: "שבת",
};

export const daysOfWeek = [
  { value: "ראשון", label: "ראשון", index: 0 },
  { value: "שני", label: "שני", index: 1 },
  { value: "שלישי", label: "שלישי", index: 2 },
  { value: "רביעי", label: "רביעי", index: 3 },
  { value: "חמישי", label: "חמישי", index: 4 },
  { value: "שישי", label: "שישי", index: 5 },
];

export const cancellationOptions = [
  { value: 0, label: "אפשרי תמיד" },
  { value: 1, label: "עד שעה לפני" },
  { value: 2, label: "עד שעתיים לפני" },
  { value: 6, label: "עד 6 שעות לפני" },
  { value: 12, label: "עד 12 שעות לפני" },
  { value: 24, label: "עד 24 שעות לפני" },
];

export const getCancellationPolicyLabel = (value: number) => {
  const option = cancellationOptions.find((option) => option.value === value);
  return option ? option.label : "";
};

export const breakOptions = [
  { label: "אין צורך בהפסקה", value: "0" },
  { label: "10 דקות", value: "10" },
  { label: "20 דקות", value: "20" },
  { label: "30 דקות", value: "30" },
];

export const getBreakLabel = (value: string) => {
  const option = breakOptions.find((option) => option.value === value);
  return option ? option.label : "";
};

export const durationOptions = [
  { value: 30, label: "30 דקות" },
  { value: 45, label: "45 דקות" },
  { value: 60, label: "שעה" },
  { value: 90, label: "שעה וחצי" },
  { value: 120, label: "שעתיים" },
];

export const getDurationLabel = (value: number) => {
  const option = durationOptions.find((option) => option.value === value);
  return option ? option.label : `${value} דקות`;
};

export const generateTimeOptions = (
  start: string,
  end: string,
  interval: number,
) => {
  const times = [];
  let currentTime = new Date(`1970-01-01T${start}:00`);
  const endTime = new Date(`1970-01-01T${end}:00`);

  while (currentTime <= endTime) {
    const formattedTime = currentTime.toTimeString().slice(0, 5);
    times.push({ value: formattedTime, label: formattedTime });
    currentTime = new Date(currentTime.getTime() + interval * 60 * 1000);
  }
  return times;
};

export const generateDateDayOptions = (weeksAhead: number) => {
  const options: { value: string; label: string; date: string; day: string }[] =
    [];
  const today = new Date();

  const dayOfWeek = today.getDay();
  const daysUntilSunday = (7 - dayOfWeek) % 7;
  const startDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysUntilSunday,
  );

  const totalDays = weeksAhead * 7;
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate() + totalDays - 1,
  );

  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    if (date.getDay() === 6) {
      continue;
    }

    const dayName =
      daysOfWeek.find((d) => d.index === date.getDay())?.value || "";
    const formattedDate = format(date, "dd/MM/yy");
    const value = `${dayName} - ${formattedDate}`;

    options.push({
      value,
      label: value,
      date: format(date, "yyyy-MM-dd"),
      day: dayName,
    });
  }
  return options;
};

export function clearLocalStorage(keys: StorageKey[]): void {
  try {
    keys.forEach((key) => {
      window.localStorage.removeItem(key);
    });
  } catch (error) {
    console.warn(
      `Error clearing localStorage keys [${keys.join(", ")}]:`,
      error,
    );
  }
}

export const phoneRegex = /^05\d{8}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validatePhone = (phone: string): boolean => {
  return phoneRegex.test(phone);
};

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export const formatWhen = (start: string, end: string) => {
  const startTime = DateTime.fromISO(start).setZone("Asia/Jerusalem");
  const endTime = DateTime.fromISO(end).setZone("Asia/Jerusalem");

  const dayOfWeekHebrew = startTime.setLocale("he").toFormat("cccc");
  const formattedDate = startTime.toFormat("dd/MM");
  const startEndTime = `${startTime.toFormat("HH:mm")} - ${endTime.toFormat("HH:mm")}`;

  return {
    date: `${dayOfWeekHebrew}, ${formattedDate}`,
    time: startEndTime,
  };
};

export const sortSlotsByStartTime = (slots: KLSlot[]): KLSlot[] => {
  return slots.sort(
    (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
  );
};

export const extractHoursMinutesFromIsoString = (isoString: string): string => {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const computeCombinedDays = (
  futureDateDayMap: { [day: string]: string },
  currentDateDayMap: { [day: string]: string } = {},
  availabilityType?: AvailabilityType | null,
): CombinedDay[] => {
  const allLabels = new Set<string>([
    ...Object.keys(currentDateDayMap),
    ...Object.keys(futureDateDayMap),
  ]);

  const combined: CombinedDay[] = [];
  allLabels.forEach((label) => {
    const date =
      availabilityType === AvailabilityType.WeeklyRecurring
        ? ""
        : currentDateDayMap[label] || futureDateDayMap[label] || "";
    let highlightMissing = false;
    if (availabilityType === AvailabilityType.WeeklyRecurring) {
      highlightMissing = false;
    } else {
      highlightMissing = !currentDateDayMap[label];
    }
    combined.push({ label, date, highlightMissing });
  });

  if (availabilityType === AvailabilityType.WeeklyRecurring) {
    const order = daysOfWeek.map((day) => dayMapping[day.label] || day.label);
    return combined.sort(
      (a, b) => order.indexOf(a.label) - order.indexOf(b.label),
    );
  } else {
    return combined.sort((a, b) => a.date.localeCompare(b.date));
  }
};
