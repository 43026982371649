import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  Container,
  ErrorMessage,
  FieldWrapper,
  FormWrapper,
  IconContainer,
  InfoText,
  LinkButton,
  SmallText,
  TextIconContainer,
  Title,
} from "../styles";
import KLButton from "../../components/KLButton";
import KLTextInput from "../../components/KLTextInput";
import PinIcon from "@mui/icons-material/Pin";
import {
  sendOtpRequest,
  verifyOtpRequest,
} from "../../store/duckers/otp/thunk";
import useLocalStorage from "../../hooks/useLocalStorage";
import { StorageKey, StorageKeys } from "../../constants/localStorage";
import { AuthenticationScreenProps, UserType } from "../../constants/types";
import { useStep } from "../../context/StepContext";
import { translateErrorMessage } from "../../constants/errors";
import {
  checkCustomerSlots,
  fetchCustomerDetails,
} from "../../store/duckers/customers/thunk";
import { setCustomerPhone } from "../../store/duckers/customers/slice";
import {
  BusinessRouteStep,
  CustomerRouteStep,
  FlowType,
} from "../../constants/routes";
import { fetchBusinessIdByPhone } from "../../store/duckers/businesses/thunk";

const NUM_OF_SECONDS_TO_RESEND_OTP = 180;

const OtpInputScreen: React.FC<AuthenticationScreenProps> = ({ userType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { markStepAsCompleted } = useStep();
  const { businessId } = useParams<{ businessId: string }>();

  const localStoragePhoneKey = useMemo(
    (): StorageKey =>
      userType === UserType.Customer
        ? StorageKeys.CustomerPhone
        : StorageKeys.BusinessPhone,
    [userType],
  );

  const localStorageVerifiedTokenKey = useMemo(
    (): StorageKey =>
      userType === UserType.Customer
        ? StorageKeys.VerifiedCustomerOtpToken
        : StorageKeys.VerifiedBusinessOtpToken,
    [userType],
  );

  const { storedValue: storedPhone } = useLocalStorage<StorageKey | null>(
    localStoragePhoneKey,
    null,
  );

  const { setValue: setTokenValue } = useLocalStorage<StorageKey | null>(
    localStorageVerifiedTokenKey,
    null,
  );

  const [otp, setOtp] = useState<string>("");
  const [remainingTime, setRemainingTime] = useState<number>(
    NUM_OF_SECONDS_TO_RESEND_OTP,
  );

  const isFormValid = useMemo(() => otp.trim().length === 6, [otp]);

  const {
    sendingOtpRequest,
    verifyingOtpRequest,
    verifyOtpRequestSucceeded,
    error,
    token,
  } = useSelector((state: RootState) => state.otp);

  const {
    fetchingCustomerDetails,
    fetchCustomerDetailsSucceeded,
    customer,
    fetchingCustomerSlots,
    fetchCustomerSlotsSucceeded,
    customerSlots,
  } = useSelector((state: RootState) => state.customers);

  const {
    businessId: fetchedBusinessId,
    fetchingBusinessId,
    fetchBusinessIdSucceeded,
  } = useSelector((state: RootState) => state.businesses);

  const translatedError = error ? translateErrorMessage(error) : null;

  useEffect(() => {
    if (sendingOtpRequest || verifyingOtpRequest) return;

    const timerId = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [sendingOtpRequest, verifyingOtpRequest]);

  const formattedTime = useMemo(() => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    const paddedMinutes = minutes.toString().padStart(2, "0");
    const paddedSeconds = seconds.toString().padStart(2, "0");
    return `${paddedMinutes}:${paddedSeconds}`;
  }, [remainingTime]);

  const handleConfirm = () => {
    if (!isFormValid || !storedPhone) return;
    dispatch(
      verifyOtpRequest({
        phoneNumber: storedPhone,
        userType,
        otp,
      }),
    );
  };

  const handleResendOtp = () => {
    if (
      !storedPhone ||
      sendingOtpRequest ||
      verifyingOtpRequest ||
      fetchingCustomerDetails ||
      fetchingCustomerSlots
    ) {
      return;
    }
    dispatch(sendOtpRequest({ phoneNumber: storedPhone, userType }));
    setRemainingTime(NUM_OF_SECONDS_TO_RESEND_OTP);
  };

  useEffect(() => {
    if (verifyOtpRequestSucceeded && token) {
      setTokenValue(token as StorageKey);
    }
  }, [verifyOtpRequestSucceeded, token, setTokenValue]);

  useEffect(() => {
    if (verifyOtpRequestSucceeded && storedPhone) {
      if (userType === UserType.Customer && businessId) {
        dispatch(setCustomerPhone(storedPhone));
        dispatch(fetchCustomerDetails({ businessId, phone: storedPhone }));
      } else if (userType === UserType.Business) {
        dispatch(fetchBusinessIdByPhone(storedPhone));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyOtpRequestSucceeded]);

  useEffect(() => {
    if (fetchCustomerDetailsSucceeded) {
      if (customer && businessId && storedPhone) {
        dispatch(checkCustomerSlots({ businessId, phone: storedPhone }));
      } else {
        markStepAsCompleted(FlowType.Customer, CustomerRouteStep.VerifyOtp);
        navigate(`/${businessId}/info`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerDetailsSucceeded]);

  useEffect(() => {
    if (fetchCustomerSlotsSucceeded) {
      markStepAsCompleted(FlowType.Customer, CustomerRouteStep.VerifyOtp);
      if (customerSlots.length > 0) {
        navigate(`/${businessId}/existing-slots`);
      } else {
        navigate(`/${businessId}/services`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomerSlotsSucceeded]);

  useEffect(() => {
    if (fetchBusinessIdSucceeded) {
      markStepAsCompleted(FlowType.Business, BusinessRouteStep.VerifyOtp);
      if (fetchedBusinessId) {
        navigate(`/business/dashboard`);
      } else {
        navigate(`/business/info`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBusinessIdSucceeded]);

  return (
    <Container>
      <TextIconContainer>
        <IconContainer>
          <PinIcon fontSize="large" />
        </IconContainer>
        <Title>מהו קוד האימות שקיבלתם?</Title>
      </TextIconContainer>
      <InfoText>
        שלחנו לכם קוד בהודעת SMS.
        <br />
        הקוד בתוקף למשך 5 דקות.
      </InfoText>

      <FormWrapper
        onSubmit={(e) => {
          e.preventDefault();
          handleConfirm();
        }}
      >
        <FieldWrapper>
          <KLTextInput
            type="text"
            placeholder="קוד אימות (6 ספרות)"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={
              verifyingOtpRequest ||
              sendingOtpRequest ||
              fetchingCustomerDetails ||
              fetchingCustomerSlots
            }
          />
        </FieldWrapper>

        {translatedError && <ErrorMessage>{translatedError}</ErrorMessage>}

        <FieldWrapper>
          <KLButton
            title="לחצו לאישור"
            disabled={
              !isFormValid ||
              verifyingOtpRequest ||
              sendingOtpRequest ||
              fetchingCustomerDetails ||
              fetchingCustomerSlots
            }
            loading={
              verifyingOtpRequest ||
              sendingOtpRequest ||
              fetchingCustomerDetails ||
              fetchCustomerSlotsSucceeded ||
              fetchingBusinessId
            }
          />
        </FieldWrapper>
      </FormWrapper>

      <SmallText>צריכים קוד חדש?</SmallText>
      {remainingTime > 0 ? (
        <SmallText>נשמח לשלוח לכם בעוד: {formattedTime}</SmallText>
      ) : (
        <LinkButton onClick={handleResendOtp}>
          לחצו כאן ומיד נשלח אליכם
        </LinkButton>
      )}
    </Container>
  );
};

export default OtpInputScreen;
