import React from "react";
import Chip from "@mui/material/Chip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useSlotSelection from "../../hooks/useSlotSelection";
import {
  chipSx,
  ChipWrapper,
  Container,
  IconContainer,
  InfoText,
  Subtitle,
  TextIconContainer,
  Title,
} from "../../screens/styles";
import KLButton from "../KLButton";
import {
  ConfirmationSection,
  DaySelection,
  MonthSelection,
  SelectionWrapper,
  SlotSummary,
  TimeSlots,
} from "./styles";
import KLPleaseWait from "../KLPleaseWait";

interface KLAvailableSlotSelectProps {
  isCustomer?: boolean;
  isBusinessNew?: boolean;
  serviceDescription?: string;
  businessName?: string;
  customerName?: string;
  onSelect: (selectedTime: {
    month: string;
    day: string;
    dayLabel: string;
    time: string;
    displayTime: string;
  }) => void;
  onError?: (error: string) => void;
  title?: string;
  subtitle?: string;
  confirmationButtonTitle?: string;
}

const KLAvailableSlotSelect: React.FC<KLAvailableSlotSelectProps> = ({
  isCustomer,
  isBusinessNew,
  serviceDescription,
  businessName,
  customerName,
  onSelect,
  onError,
  title = "בחר מועד חדש",
  subtitle = "באיזה חודש?",
  confirmationButtonTitle = "אשר שינוי",
}) => {
  const {
    availableSlots,
    fetchingSlots,
    bookingSlot,
    error,
    selectedMonth,
    selectedDay,
    selectedDayLabel,
    selectedTime,
    selectedDisplayTime,
    months,
    days,
    times,
    handleMonthSelect,
    handleDaySelect,
    handleTimeSelect,
    confirmSelection,
    scrollRefs,
  } = useSlotSelection();

  React.useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  const handleConfirmClick = () => {
    const selection = confirmSelection();
    if (selection) {
      onSelect(selection);
    }
  };

  return (
    <Container>
      <TextIconContainer>
        <IconContainer>
          <CalendarMonthIcon />
        </IconContainer>
        <Title>{title}</Title>
      </TextIconContainer>
      {fetchingSlots ? (
        <KLPleaseWait text={"בודקים זמני זמינים"} />
      ) : (
        <>
          {!availableSlots.length ? (
            <>
              <Title>אין זמנים פנויים</Title>
              <Subtitle>נסה שוב במועד מאוחר יותר</Subtitle>
              <InfoText>אפשר לנסות שוב במועד מאוחר יותר</InfoText>
              <KLButton
                title={"אולי בכל זאת?"}
                onClick={() => {}}
                disabled={fetchingSlots}
              />
            </>
          ) : (
            <>
              <SelectionWrapper>
                <Subtitle>{subtitle}</Subtitle>
                <MonthSelection>
                  {months.map((month, index) => (
                    <Chip
                      sx={chipSx(selectedMonth === month)}
                      key={index}
                      label={month}
                      color={selectedMonth === month ? "primary" : "default"}
                      onClick={() => handleMonthSelect(month)}
                      style={{ margin: "0.25rem" }}
                    />
                  ))}
                </MonthSelection>
              </SelectionWrapper>
              {selectedMonth && (
                <SelectionWrapper ref={scrollRefs.daySectionRef}>
                  <Subtitle>באיזה יום ב{selectedMonth}?</Subtitle>
                  <DaySelection>
                    {days.map((dayObj) => (
                      <ChipWrapper key={dayObj.date}>
                        <Chip
                          sx={chipSx(selectedDay === dayObj.date)}
                          label={dayObj.label}
                          color={
                            selectedDay === dayObj.date ? "primary" : "default"
                          }
                          onClick={() => handleDaySelect(dayObj)}
                        />
                      </ChipWrapper>
                    ))}
                  </DaySelection>
                </SelectionWrapper>
              )}
              {selectedDay && selectedDayLabel && (
                <SelectionWrapper ref={scrollRefs.timeSectionRef}>
                  <Subtitle>באיזו שעה ב{selectedDayLabel}?</Subtitle>
                  <TimeSlots>
                    {times.map(({ startTime, endTime, displayTime }, index) => (
                      <ChipWrapper key={index}>
                        <Chip
                          sx={chipSx(
                            selectedTime === `${startTime} - ${endTime}`,
                          )}
                          label={displayTime}
                          color={
                            selectedTime === `${startTime} - ${endTime}`
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            handleTimeSelect(startTime, endTime, displayTime)
                          }
                        />
                      </ChipWrapper>
                    ))}
                  </TimeSlots>
                </SelectionWrapper>
              )}

              {selectedTime && (
                <ConfirmationSection ref={scrollRefs.confirmationSectionRef}>
                  <SlotSummary>
                    {isCustomer ? (
                      <>
                        <strong>מצוין!</strong> אז אנחנו קובעים לכם{" "}
                        <strong>{serviceDescription}</strong> אצל{" "}
                        <strong>{businessName}</strong> ב
                      </>
                    ) : isBusinessNew && customerName ? (
                      <>
                        <strong>מצוין!</strong> אז אנחנו קובעים ל
                        <strong>{customerName}</strong>{" "}
                        <strong>{serviceDescription}</strong>{" "}
                        <strong>{businessName}</strong> ב
                      </>
                    ) : (
                      <>
                        <strong>מצוין!</strong> אז אנחנו מזיזים ל
                      </>
                    )}
                    <strong>{selectedDayLabel}</strong> בשעה{" "}
                    <strong>{selectedDisplayTime}</strong>
                  </SlotSummary>
                  <KLButton
                    title={confirmationButtonTitle}
                    onClick={handleConfirmClick}
                    disabled={!selectedTime || bookingSlot}
                    loading={bookingSlot}
                  />
                </ConfirmationSection>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default KLAvailableSlotSelect;
