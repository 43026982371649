const errorMessagesMap: { [key: string]: string } = {
  "OTP is expired.": "קוד האימות שהכנסת כבר לא בתוקף",
  "Invalid OTP code.": "קוד האימות שהוזן אינו נכון, אנא נסו שוב",
  "Max verification attempts exceeded.":
    "חרגתם ממספר הניסיונות המותרים, אנא בקשו קוד חדש",
  "No OTP found for this phone number and user type.":
    "לא נמצא קוד אימות למספר טלפון זה",
  "Missing phoneNumber, userType, or otpCode.":
    "חסר מספר טלפון, סוג משתמש או קוד אימות",
};

export function translateErrorMessage(originalMessage: string): string {
  return errorMessagesMap[originalMessage] || originalMessage;
}
