import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import { RootState } from "../store";
import { Slot } from "../constants/types";

interface SelectedTime {
  month: string;
  day: string;
  dayLabel: string;
  time: string;
  displayTime: string;
}

interface UseSlotSelectionReturn {
  availableSlots: Slot[];
  fetchingSlots: boolean;
  bookingSlot: boolean;
  error: string | null;
  selectedMonth: string | null;
  selectedDay: string | null;
  selectedDayLabel: string | null;
  selectedTime: string | null;
  selectedDisplayTime: string | null;
  months: string[];
  days: { date: string; label: string }[];
  times: { startTime: string; endTime: string; displayTime: string }[];
  handleMonthSelect: (month: string) => void;
  handleDaySelect: (dayObj: { date: string; label: string }) => void;
  handleTimeSelect: (
    startTime: string,
    endTime: string,
    displayTime: string,
  ) => void;
  confirmSelection: () => SelectedTime | null;
  resetSelection: () => void;
  scrollRefs: {
    daySectionRef: React.RefObject<HTMLDivElement>;
    timeSectionRef: React.RefObject<HTMLDivElement>;
    confirmationSectionRef: React.RefObject<HTMLDivElement>;
  };
}

const useSlotSelection = (): UseSlotSelectionReturn => {
  const { fetchingSlots, availableSlots, bookingSlot, error } = useSelector(
    (state: RootState) => state.slots,
  );

  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [selectedDayLabel, setSelectedDayLabel] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDisplayTime, setSelectedDisplayTime] = useState<string | null>(
    null,
  );

  const daySectionRef = useRef<HTMLDivElement | null>(null);
  const timeSectionRef = useRef<HTMLDivElement | null>(null);
  const confirmationSectionRef = useRef<HTMLDivElement | null>(null);

  const getAvailableMonths = useCallback((): string[] => {
    const yearMonthSet = new Set<string>();

    availableSlots.forEach((slot) => {
      const dateTime = DateTime.fromISO(slot.start, { zone: "Asia/Jerusalem" });
      if (dateTime.isValid) {
        const yearMonth = dateTime.toFormat("yyyy-MM");
        yearMonthSet.add(yearMonth);
      } else {
        console.error(`Invalid slot.start date: ${slot.start}`);
      }
    });

    const sortedYearMonths = Array.from(yearMonthSet).sort((a, b) => {
      const dateA = DateTime.fromFormat(a, "yyyy-MM");
      const dateB = DateTime.fromFormat(b, "yyyy-MM");
      return dateA.toMillis() - dateB.toMillis();
    });

    return sortedYearMonths
      .map((yearMonth) => {
        const parts = yearMonth.split("-");
        if (parts.length !== 2) {
          console.error(`Invalid yearMonth format: ${yearMonth}`);
          return null;
        }
        const [year, month] = parts;
        const monthNumber = parseInt(month, 10);
        const yearNumber = parseInt(year, 10);

        if (isNaN(yearNumber) || isNaN(monthNumber)) {
          console.error(
            `Invalid year or month number: year=${year}, month=${month}`,
          );
          return null;
        }

        return DateTime.fromObject({ year: yearNumber, month: monthNumber })
          .setLocale("he")
          .toFormat("LLLL");
      })
      .filter((monthName): monthName is string => monthName !== null);
  }, [availableSlots]);

  const getAvailableDays = useCallback(
    (monthName: string): { date: string; label: string }[] => {
      const filteredSlots = availableSlots.filter((slot) => {
        const dateTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        }).setLocale("he");
        const slotMonthName = dateTime.toFormat("LLLL");
        return slotMonthName === monthName;
      });

      const sortedSlots = filteredSlots.sort((a, b) => {
        const aDate = DateTime.fromISO(a.start, { zone: "Asia/Jerusalem" });
        const bDate = DateTime.fromISO(b.start, { zone: "Asia/Jerusalem" });
        return aDate.toMillis() - bDate.toMillis();
      });

      const dayMap = new Map<string, { date: string; label: string }>();

      sortedSlots.forEach((slot) => {
        const dateTime = DateTime.fromISO(slot.start, {
          zone: "Asia/Jerusalem",
        }).setLocale("he");
        const dateKey = dateTime.toISODate();
        const label = dateTime.toFormat("cccc dd/MM");
        if (dateKey && !dayMap.has(dateKey)) {
          dayMap.set(dateKey, { date: dateKey, label });
        }
      });

      return Array.from(dayMap.values());
    },
    [availableSlots],
  );

  const getAvailableTimes = useCallback(
    (dateKey: string) => {
      return availableSlots
        .filter((slot) => {
          const dateTime = DateTime.fromISO(slot.start, {
            zone: "Asia/Jerusalem",
          });
          return dateTime.toISODate() === dateKey;
        })
        .map((slot) => {
          const startTime = DateTime.fromISO(slot.start, {
            zone: "Asia/Jerusalem",
          });
          const endTime = DateTime.fromISO(slot.end, {
            zone: "Asia/Jerusalem",
          });
          return {
            startTime: slot.start,
            endTime: slot.end,
            displayTime: `${startTime.toFormat("HH:mm")} - ${endTime.toFormat(
              "HH:mm",
            )}`,
          };
        });
    },
    [availableSlots],
  );

  const months = getAvailableMonths();
  const days = selectedMonth ? getAvailableDays(selectedMonth) : [];
  const times = selectedDay ? getAvailableTimes(selectedDay) : [];

  const handleMonthSelect = (month: string) => {
    setSelectedMonth(month);
    setSelectedDay(null);
    setSelectedDayLabel(null);
    setSelectedTime(null);
    setSelectedDisplayTime(null);
  };

  const handleDaySelect = (dayObj: { date: string; label: string }) => {
    setSelectedDay(dayObj.date);
    setSelectedDayLabel(dayObj.label);
    setSelectedTime(null);
    setSelectedDisplayTime(null);
  };

  const handleTimeSelect = (
    startTime: string,
    endTime: string,
    displayTime: string,
  ) => {
    setSelectedTime(`${startTime} - ${endTime}`);
    setSelectedDisplayTime(displayTime);
  };

  const confirmSelection = (): SelectedTime | null => {
    if (
      selectedMonth &&
      selectedDay &&
      selectedTime &&
      selectedDisplayTime &&
      selectedDayLabel
    ) {
      return {
        month: selectedMonth,
        day: selectedDay,
        dayLabel: selectedDayLabel,
        time: selectedTime,
        displayTime: selectedDisplayTime,
      };
    }
    return null;
  };

  const resetSelection = () => {
    setSelectedMonth(null);
    setSelectedDay(null);
    setSelectedDayLabel(null);
    setSelectedTime(null);
    setSelectedDisplayTime(null);
  };

  // Scroll into view refs
  useEffect(() => {
    if (selectedMonth !== null && daySectionRef.current) {
      daySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDay !== null && timeSectionRef.current) {
      timeSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (selectedTime !== null && confirmationSectionRef.current) {
      confirmationSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedTime]);

  return {
    availableSlots,
    fetchingSlots,
    bookingSlot,
    error,
    selectedMonth,
    selectedDay,
    selectedDayLabel,
    selectedTime,
    selectedDisplayTime,
    months,
    days,
    times,
    handleMonthSelect,
    handleDaySelect,
    handleTimeSelect,
    confirmSelection,
    resetSelection,
    scrollRefs: {
      daySectionRef,
      timeSectionRef,
      confirmationSectionRef,
    },
  };
};

export default useSlotSelection;
