export enum FlowType {
  Customer = "customer",
  Business = "business",
  Availability = "availability",
}

export enum CustomerRouteStep {
  PhoneInput = "customerPhoneInput",
  VerifyOtp = "customerVerifyOtp",
  Info = "customerInfo",
  Services = "customerServices",
  ExistingSlots = "customerExistingSlots",
  Slots = "customerSlots",
  Success = "customerSuccess",
}

export enum BusinessRouteStep {
  PhoneInput = "businessPhoneInput",
  VerifyOtp = "businessVerifyOtp",
  Dashboard = "businessDashboard",
  Insights = "businessInsights",
  Info = "businessInfo",
  ServicesSetup = "businessServicesSetup",
  Authorize = "businessAuthorize",
  SlotsSetup = "businessSlotsSetup",
  Success = "businessSuccess",
}

export enum AvailabilityRouteStep {
  Check = "availabilityCheck",
  Slots = "availabilitySlots",
  Success = "availabilitySuccess",
}

export type AllRouteSteps =
  | CustomerRouteStep
  | BusinessRouteStep
  | AvailabilityRouteStep;

interface StepDependencyMap {
  [key: string]: AllRouteSteps[];
}

export const StepDependencies: StepDependencyMap = {
  [CustomerRouteStep.PhoneInput]: [],
  [CustomerRouteStep.VerifyOtp]: [CustomerRouteStep.PhoneInput],
  [CustomerRouteStep.Info]: [CustomerRouteStep.VerifyOtp],
  [CustomerRouteStep.Services]: [CustomerRouteStep.VerifyOtp],
  [CustomerRouteStep.ExistingSlots]: [CustomerRouteStep.VerifyOtp],
  [CustomerRouteStep.Slots]: [CustomerRouteStep.Services],
  [CustomerRouteStep.Success]: [CustomerRouteStep.Slots],

  [BusinessRouteStep.Insights]: [],
  [BusinessRouteStep.Dashboard]: [BusinessRouteStep.VerifyOtp],
  [BusinessRouteStep.Info]: [BusinessRouteStep.VerifyOtp],
  [BusinessRouteStep.ServicesSetup]: [BusinessRouteStep.Info],
  [BusinessRouteStep.Authorize]: [BusinessRouteStep.ServicesSetup],
  [BusinessRouteStep.SlotsSetup]: [BusinessRouteStep.Authorize],
  [BusinessRouteStep.Success]: [BusinessRouteStep.SlotsSetup],

  [AvailabilityRouteStep.Check]: [],
  [AvailabilityRouteStep.Slots]: [AvailabilityRouteStep.Check],
  [AvailabilityRouteStep.Success]: [AvailabilityRouteStep.Slots],
};
