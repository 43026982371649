import React from "react";
import { Slot } from "../../constants/types";
import KLSelect from "../KLSelect";
import KLTrashCanIcon from "../icons/KLTrashCanIcon";
import {
  SlotContent,
  TimeRow,
  DropdownWrapper,
  DeleteButtonWrapper,
  DeleteButton,
} from "./styles";

export interface SlotFormOptions {
  label: string;
  value: string;
}

export interface KLSlotFormProps {
  slot: Slot;
  onChange: (updatedSlot: Slot) => void;
  onDelete: () => void; // New prop for deletion
  startOptions: SlotFormOptions[];
  endOptions: SlotFormOptions[];
}

const KLSlotForm: React.FC<KLSlotFormProps> = ({
  slot,
  onChange,
  onDelete,
  startOptions,
  endOptions,
}) => {
  const handleStartTimeChange = (value: string) => {
    onChange({ ...slot, start: value });
  };

  const handleEndTimeChange = (value: string) => {
    onChange({ ...slot, end: value });
  };

  return (
    <SlotContent>
      <TimeRow>
        <DropdownWrapper>
          <KLSelect
            value={slot.start}
            onChange={(e) => handleStartTimeChange(e.target.value)}
            placeholder="התחלה"
            required
            options={startOptions}
            disabled={false}
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <KLSelect
            value={slot.end}
            onChange={(e) => handleEndTimeChange(e.target.value)}
            placeholder="סיום"
            required
            options={endOptions}
            disabled={false}
          />
        </DropdownWrapper>
        <DeleteButtonWrapper>
          <DeleteButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            aria-label="מחק שעה"
          >
            <KLTrashCanIcon />
          </DeleteButton>
        </DeleteButtonWrapper>
      </TimeRow>
    </SlotContent>
  );
};

export default KLSlotForm;
