import {
  Customer,
  CustomerSlot,
  CustomerWithLatestBooking,
  UserType,
} from "../constants/types";
import apiClient from "../api/apiClient";
import { StorageKeys } from "../constants/localStorage";

class CustomerService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async checkCustomerSlots(
    businessId: string,
    phone: string,
  ): Promise<CustomerSlot[]> {
    try {
      const response = await apiClient.get<CustomerSlot[]>(
        `${this.baseApiUrl}/api/${businessId}/customer/slots`,
        {
          params: { phone },
          headers: {
            "x-token-type": StorageKeys.VerifiedCustomerOtpToken,
          },
        },
      );

      return response.data;
    } catch (e) {
      throw new Error("Failed to fetch customer slots");
    }
  }

  async addNewCustomer(
    businessId: string,
    phone: string,
    name: string,
    userType: UserType,
  ): Promise<string> {
    try {
      const response = await apiClient.post<{
        message: string;
        customerId: string;
      }>(
        `${this.baseApiUrl}/api/${businessId}/customer/details`,
        { phone, name },
        {
          headers: {
            "x-token-type":
              userType === UserType.Customer
                ? StorageKeys.VerifiedCustomerOtpToken
                : StorageKeys.VerifiedBusinessOtpToken,
            "Content-Type": "application/json",
          },
        },
      );

      return response.data.customerId;
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.error || "Failed to add/update customer";
      throw new Error(errorMessage);
    }
  }

  async getCustomerDetails(
    businessId: string,
    phone: string,
  ): Promise<Customer> {
    try {
      const response = await apiClient.get<{ customer: Customer }>(
        `${this.baseApiUrl}/api/${businessId}/customer/details`,
        {
          params: { phone },
          headers: {
            "x-token-type": StorageKeys.VerifiedCustomerOtpToken,
          },
        },
      );

      return response.data.customer;
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        throw new Error("Customer not found");
      }
      const errorMessage =
        e.response?.data?.message || "Failed to retrieve customer details";
      throw new Error(errorMessage);
    }
  }

  async getAllCustomers(
    businessId: string,
  ): Promise<CustomerWithLatestBooking[]> {
    try {
      const response = await apiClient.get<{
        customers: CustomerWithLatestBooking[];
      }>(`${this.baseApiUrl}/api/${businessId}/customers`, {
        headers: {
          "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
        },
      });
      return response.data.customers;
    } catch (e: any) {
      const errorMessage =
        e.response?.data?.error || "Failed to fetch customers";
      throw new Error(errorMessage);
    }
  }
}

export const customerService = new CustomerService();
