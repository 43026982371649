import { createSlice } from '@reduxjs/toolkit';
import { verifyToken } from './thunk';
import {TokenPayload} from "../../../constants/types";

interface TokensState {
  verifyingToken: boolean;
  isTokenVerified: boolean;
  userData?: TokenPayload | null;
  error: string | null;
}

const initialState: TokensState = {
  verifyingToken: false,
  isTokenVerified: false,
  userData: undefined,
  error: null,
};

const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    clearTokenError: (state) => {
      state.error = null;
    },
    resetTokenState: (state) => {
      state.verifyingToken = false;
      state.isTokenVerified = false;
      state.userData = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyToken.pending, (state) => {
        state.verifyingToken = true;
        state.error = null;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.verifyingToken = false;
        state.isTokenVerified = true;
        state.userData = action.payload.payload;
        state.error = null;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.verifyingToken = false;
        state.isTokenVerified = false;
        state.userData = null;
        state.error = action.payload || "Token verification failed";
      });
  },
});

export const { clearTokenError, resetTokenState } = tokensSlice.actions;
export default tokensSlice.reducer;
