import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { ServiceType, UserType } from "../../constants/types";
import { AppDispatch, RootState } from "../../store";
import useServices from "../../hooks/useServices";
import { durationOptions, cancellationOptions } from "../../utils";
import { updateServices } from "../../store/duckers/services/thunk";
import KLServicesList from "../../components/KLServiceList";
import KLButton from "../../components/KLButton";
import KLCollapsibleSection from "../../components/KLCollapsibleSection";
import { Disclaimer, FormWrapper } from "../../screens/styles";

interface KLDashboardServicesProps {
  businessId: string;
}

const KLDashboardServices: React.FC<KLDashboardServicesProps> = ({
  businessId,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const storedServices = useSelector(
    (state: RootState) => state.services.services,
  );

  const loading = useSelector((state: RootState) => state.services.loading);

  const error = useSelector((state: RootState) => state.services.error);

  const [isServicesSectionExpanded, setIsServicesSectionExpanded] =
    useState(false);

  const initialServicesRef = useRef<ServiceType[]>([]);

  const [hasChanges, setHasChanges] = useState(false);

  const {
    services,
    isPriceValid,
    expandedServices,
    handleAddService,
    handleDeleteService,
    handleServiceChange,
    handlePriceValidation,
    toggleServiceExpansion,
    isFormValid,
  } = useServices({
    initialServices: storedServices,
  });

  useEffect(() => {
    if (storedServices && initialServicesRef.current.length === 0) {
      initialServicesRef.current = cloneDeep(storedServices);
    }
  }, [storedServices]);

  useEffect(() => {
    if (initialServicesRef.current.length === 0) {
      setHasChanges(false);
    } else {
      const areEqual = isEqual(services, initialServicesRef.current);
      setHasChanges(!areEqual);
    }
  }, [services]);

  const handleSaveServices = async (servicesToSave: ServiceType[]) => {
    try {
      await dispatch(
        updateServices([businessId, UserType.Business, servicesToSave]),
      );
      initialServicesRef.current = cloneDeep(servicesToSave);
      setHasChanges(false);
    } catch (error) {
      console.error(
        "KLDashboardServices - handleSaveServices - Failed to save services:",
        error,
      );
    }
  };

  const handleSaveChanges = () => {
    if (isFormValid) {
      handleSaveServices(services);
    }
  };

  const handleServicesSectionToggle = () => {
    setIsServicesSectionExpanded((prev) => !prev);
  };

  const durationOptionsFormatted = useMemo(
    () =>
      durationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  const cancellationOptionsFormatted = useMemo(
    () =>
      cancellationOptions.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      })),
    [],
  );

  return (
    <FormWrapper onSubmit={(e) => e.preventDefault()}>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!loading && !error && (
        <KLCollapsibleSection
          title="אילו שירותים מציעים ללקוחות?"
          isExpanded={isServicesSectionExpanded}
          onToggle={handleServicesSectionToggle}
          count={services.length}
          secondary
        >
          <KLServicesList
            services={services}
            isPriceValid={isPriceValid}
            expandedServices={expandedServices}
            onAddService={handleAddService}
            onDeleteService={handleDeleteService}
            onServiceChange={handleServiceChange}
            onPriceValidation={handlePriceValidation}
            toggleServiceExpansion={toggleServiceExpansion}
            durationOptions={durationOptionsFormatted}
            cancellationOptions={cancellationOptionsFormatted}
          />
          <Disclaimer>
            מחירי השירותים הינם בשקלים חדשים וכוללים מע״מ.
          </Disclaimer>
          <KLButton
            type="button"
            title={"לשמור שינויים"}
            onClick={handleSaveChanges}
            disabled={!hasChanges || !isFormValid || loading}
            loading={loading}
            noMarginTop
          />
        </KLCollapsibleSection>
      )}
    </FormWrapper>
  );
};

export default KLDashboardServices;
