import React, { useState } from "react";
import { KLSlot } from "../../constants/types";
import KLButton from "../../components/KLButton";
import {
  SlotItem,
  SlotContentWrapper,
  SlotInfo,
  CustomerNamePhone,
  ServicePriceDescription,
  SlotWhen,
  SlotButtons,
} from "./styles";
import { formatWhen } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KLChangeUpcomingSlotModal from "../KLChangeUpcomingSlotModal";

interface KLUpcomingSlotItemProps {
  slot: KLSlot;
  term?: string;
  isCancelling: boolean;
  onCancel: () => void;
  isLast?: boolean;
}

const KLUpcomingSlotItem: React.FC<KLUpcomingSlotItemProps> = ({
  slot,
  term,
  isCancelling,
  onCancel,
  isLast,
}) => {
  const when = formatWhen(slot.start, slot.end);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const businessId = useSelector(
    (state: RootState) => state.businesses.businessId,
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <SlotItem $isLast={isLast}>
      <SlotContentWrapper>
        <SlotInfo>
          <CustomerNamePhone>
            {slot.name} | {slot.phone}
          </CustomerNamePhone>
          <ServicePriceDescription>
            {slot.service} | {slot.price && `${slot.price} ש״ח `}
          </ServicePriceDescription>
          <SlotWhen>
            <span>{when.date}</span> | <span>{when.time}</span>
          </SlotWhen>
        </SlotInfo>
        <SlotButtons>
          <KLButton
            title="שינוי"
            onClick={handleOpenModal}
            noWidth
            noMarginTop
            disabled={isCancelling}
          />
          <KLButton
            title="ביטול"
            onClick={onCancel}
            warning
            noWidth
            noMarginTop
            disabled={isCancelling}
            loading={isCancelling}
          />
        </SlotButtons>
      </SlotContentWrapper>
      {isModalOpen && businessId && (
        <KLChangeUpcomingSlotModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          slot={slot}
          term={term}
          businessId={businessId}
        />
      )}
    </SlotItem>
  );
};

export default KLUpcomingSlotItem;
