import React, { useRef, useState, useEffect } from "react";
import {
  CollapsibleWrapper,
  CollapsibleHeader,
  CollapsibleContent,
  CollapsibleTitle,
  TitleWrapper,
  CollapsibleSubtitle,
  CollapsibleCount,
  IconWrapper,
  DeleteButton,
} from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface KLCollapsibleSectionProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
  onDelete?: () => void;
  secondary?: boolean;
  count?: number;
  children: React.ReactNode;
}

const KLCollapsibleSection: React.FC<KLCollapsibleSectionProps> = ({
  title,
  subtitle,
  isExpanded,
  onToggle,
  onDelete,
  secondary,
  count,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | "none">(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (isExpanded && contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      if (contentHeight !== maxHeight) {
        setMaxHeight(contentHeight);
      }
      if (!isTransitioning) {
        setIsTransitioning(true);
      }
    } else {
      if (maxHeight !== 0) {
        setMaxHeight(0);
      }
      if (!isTransitioning) {
        setIsTransitioning(true);
      }
    }
  }, [isExpanded]);

  const handleTransitionEnd = () => {
    if (isExpanded) {
      setMaxHeight("none");
    }
    setIsTransitioning(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (isExpanded && contentRef.current) {
        if (maxHeight !== "none") {
          const newHeight = contentRef.current.scrollHeight;
          if (newHeight !== maxHeight) {
            setMaxHeight(newHeight);
          }
        } else {
          const currentScrollHeight = contentRef.current.scrollHeight;
          setMaxHeight(currentScrollHeight);
          setTimeout(() => {
            setMaxHeight("none");
          }, 300);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isExpanded, maxHeight]);

  useEffect(() => {
    if (!isExpanded || !contentRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === contentRef.current) {
          if (maxHeight !== "none") {
            const newHeight = entry.target.scrollHeight;
            if (newHeight !== maxHeight) {
              setMaxHeight(newHeight);
            }
          }
        }
      }
    });

    resizeObserver.observe(contentRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isExpanded, maxHeight]);

  return (
    <CollapsibleWrapper>
      <CollapsibleHeader
        onClick={onToggle}
        $isCollapsed={!isExpanded}
        $secondary={secondary}
        role="button"
        tabIndex={0}
        aria-expanded={isExpanded}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onToggle();
          }
        }}
      >
        <CollapsibleTitle>
          <TitleWrapper>
            {title}
            {count ? <CollapsibleCount>({count})</CollapsibleCount> : <></>}
          </TitleWrapper>
          {subtitle && <CollapsibleSubtitle>{subtitle}</CollapsibleSubtitle>}
        </CollapsibleTitle>
        <IconWrapper>
          {onDelete && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              מחק
            </DeleteButton>
          )}
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconWrapper>
      </CollapsibleHeader>
      <CollapsibleContent
        ref={contentRef}
        $maxHeight={maxHeight}
        $isExpanded={isExpanded}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}
      </CollapsibleContent>
    </CollapsibleWrapper>
  );
};

export default React.memo(KLCollapsibleSection);
