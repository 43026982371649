import apiClient from "../api/apiClient";
import { StorageKeys } from "../constants/localStorage";
import { BusinessInsights, AvailableInsightsPeriod } from "../constants/types";

class InsightService {
  private baseApiUrl = process.env.REACT_APP_BACKEND_API;

  async fetchMonthlyInsights(
    businessId: string,
    year: number,
    month: number,
  ): Promise<BusinessInsights> {
    try {
      const response = await apiClient.get<BusinessInsights>(
        `${this.baseApiUrl}/api/${businessId}/insights`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
          params: {
            year,
            month,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching monthly insights:", error);
      throw new Error("Failed to fetch monthly insights");
    }
  }

  async fetchAvailableInsightsPeriods(
    businessId: string,
  ): Promise<AvailableInsightsPeriod[]> {
    try {
      const response = await apiClient.get<AvailableInsightsPeriod[]>(
        `${this.baseApiUrl}/api/${businessId}/insights/available-periods`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-token-type": StorageKeys.VerifiedBusinessOtpToken,
          },
        },
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching available insights periods:", error);
      throw new Error("Failed to fetch available insights periods");
    }
  }
}

export const insightService = new InsightService();
