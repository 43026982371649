import { createAsyncThunk } from "@reduxjs/toolkit";
import { VerifyTokenResponse } from "../../../constants/types";
import { tokensService } from "../../../services/tokensService";

export const verifyToken = createAsyncThunk<
  VerifyTokenResponse,
  string,
  { rejectValue: string }
>(
  "tokens/verifyToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await tokensService.verifyToken(token);
      if (!response.valid) {
        return rejectWithValue(response.message || "Token verification failed");
      }

      return response;
    } catch (error) {
      const errorMessage = (error as Error).message;
      return rejectWithValue(errorMessage);
    }
  },
);
