const colors = {
  BACKGROUND: "#FAFBFC",
  BLACK: "#151D48",
  GREEN: "#08a467",
  GREY_1: "#F2F2F7",
  GREY_2: "#E5E5EA",
  GREY_3: "#D1D1D6",
  GREY_4: "#C7C7CC",
  GREY_5: "#AEAEB2",
  GREY_6: "#8E8E93",
  GREY_7: "#737791",
  ORANGE: "#FFA500",
  PURPLE: "#7469B6",
  PURPLE_LIGHT: "#AD88C6",
  PURPLE_LIGHTER: "#E1AFD1",
  PURPLE_LIGHTEST: "#FFE6E6",
  RED: "#FF3B30",
  WHITE: "#FFFFFF",
};

export type ColorsType = (typeof colors)[keyof typeof colors];
export default colors;
