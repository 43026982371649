import styled from "styled-components";

export const IconWrapper = styled("div")({
  position: "relative",
  display: "inline-block",
  width: "100px",
  ".MuiSvgIcon-root": {
    fontSize: "4rem",
  },
});
