import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import KLPleaseWait from "../../components/KLPleaseWait";
import { useStep } from "../../context/StepContext";
import { AvailabilityRouteStep, FlowType } from "../../constants/routes";

const AvailabilityScreen: React.FC = () => {
  const navigate = useNavigate();
  const { markStepAsCompleted, onRedirect } = useStep();

  const {
    verifyingToken,
    userData: tokenPayload,
    isTokenVerified,
  } = useSelector((state: RootState) => state.tokens);

  const [tokenVerificationStarted, setTokenVerificationStarted] =
    useState(false);
  const [tokenVerificationEnded, setTokenVerificationEnded] = useState(false);

  useEffect(() => {
    if (verifyingToken) {
      setTokenVerificationStarted(true);
    } else if (tokenVerificationStarted && !verifyingToken) {
      setTokenVerificationEnded(true);
    }
  }, [verifyingToken, tokenVerificationStarted]);

  useEffect(() => {
    if (!tokenVerificationEnded) {
      return;
    } else if (isTokenVerified) {
      if (tokenPayload?.businessId) {
        const backendBaseUrl = process.env.REACT_APP_BACKEND_API;
        markStepAsCompleted(FlowType.Availability, AvailabilityRouteStep.Check);
        onRedirect(true);
        window.location.href = `${backendBaseUrl}/api/${tokenPayload.businessId}/slots/availability`;
      } else {
        navigate("/not-authorized");
      }
    } else {
      navigate("/not-authorized");
    }
  }, [tokenVerificationEnded, isTokenVerified, tokenPayload, navigate]);

  return <KLPleaseWait text="עוד רגע זה קורה" />;
};

export default AvailabilityScreen;
