import { useState, useMemo, useEffect } from "react";
import { Service, ServiceType } from "../constants/types";

interface UseServicesProps {
  initialServices?: ServiceType[];
}

interface UseServicesReturn {
  services: ServiceType[];
  isPriceValid: boolean[];
  expandedServices: boolean[];
  handleAddService: () => void;
  handleDeleteService: (index: number) => void;
  handleServiceChange: <K extends keyof Service>(
    index: number,
    field: K,
    value: Service[K],
  ) => void;
  handlePriceValidation: (index: number, isValid: boolean) => void;
  toggleServiceExpansion: (index: number) => void;
  isFormValid: boolean;
}

const useServices = ({
  initialServices = [],
}: UseServicesProps): UseServicesReturn => {
  const [services, setServices] = useState<ServiceType[]>(
    initialServices.length > 0
      ? initialServices
      : [
          {
            description: "",
            price: "",
            duration: undefined,
            showWithoutPrice: false,
            cancellationPolicy: undefined,
          },
        ],
  );

  const [isPriceValid, setIsPriceValid] = useState<boolean[]>(
    initialServices.length > 0
      ? initialServices.map(
          (service) => service.showWithoutPrice || !!service.price,
        )
      : [false],
  );

  const [expandedServices, setExpandedServices] = useState<boolean[]>(
    initialServices.length > 0 ? initialServices.map(() => false) : [true],
  );

  useEffect(() => {
    if (initialServices.length > 0) {
      setServices(initialServices);
      setIsPriceValid(
        initialServices.map(
          (service) => service.showWithoutPrice || !!service.price,
        ),
      );
      setExpandedServices(initialServices.map(() => false));
    } else {
      setServices([]);
      setIsPriceValid([]);
      setExpandedServices([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialServices]);

  const handleAddService = () => {
    const newService: Service = {
      description: "",
      price: "",
      duration: undefined,
      showWithoutPrice: false,
      cancellationPolicy: undefined,
    };
    setServices((prevServices) => {
      const updatedServices = [...prevServices, newService];
      return updatedServices;
    });
    setIsPriceValid((prevPriceValid) => {
      const updatedPriceValid = [...prevPriceValid, false];
      return updatedPriceValid;
    });
    setExpandedServices((prevExpanded) => {
      const updatedExpanded = [...prevExpanded.map(() => false), true];
      return updatedExpanded;
    });
  };

  const handleDeleteService = (index: number) => {
    setServices((prevServices) =>
      prevServices.filter((_, serviceIndex) => serviceIndex !== index),
    );
    setIsPriceValid((prevPriceValid) =>
      prevPriceValid.filter((_, priceIndex) => priceIndex !== index),
    );
    setExpandedServices((prevExpanded) =>
      prevExpanded.filter((_, expandedIndex) => expandedIndex !== index),
    );
  };

  const handleServiceChange = <K extends keyof Service>(
    index: number,
    field: K,
    value: Service[K],
  ) => {
    setServices((prevServices) => {
      const updatedServices = [...prevServices];
      const service = updatedServices[index];
      updatedServices[index] = { ...service, [field]: value };
      return updatedServices;
    });
  };

  const handlePriceValidation = (index: number, isValid: boolean) => {
    setIsPriceValid((prevPriceValid) => {
      const updatedPriceValid = [...prevPriceValid];
      updatedPriceValid[index] = isValid;
      return updatedPriceValid;
    });
  };

  const toggleServiceExpansion = (index: number) => {
    setExpandedServices((prevExpanded) => {
      const updatedExpanded = prevExpanded.map((isExpanded, i) =>
        i === index ? !isExpanded : false,
      );
      return updatedExpanded;
    });
  };

  const isFormValid = useMemo(() => {
    const validationStatus =
      services.length > 0 &&
      services.every(
        (service, index) =>
          service.description.trim() !== "" &&
          service.duration !== undefined &&
          service.cancellationPolicy !== undefined &&
          (service.showWithoutPrice || isPriceValid[index]),
      );
    return validationStatus;
  }, [services, isPriceValid]);

  return {
    services,
    isPriceValid,
    expandedServices,
    handleAddService,
    handleDeleteService,
    handleServiceChange,
    handlePriceValidation,
    toggleServiceExpansion,
    isFormValid,
  };
};

export default useServices;
