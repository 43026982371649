import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AvailabilityDetail,
  AvailabilityType,
  KLSlot,
} from "../../../constants/types";
import { businessService } from "../../../services/businessService";

export const fetchAvailability = createAsyncThunk<
  AvailabilityDetail[],
  { businessId: string; availabilityType: AvailabilityType },
  { rejectValue: string }
>(
  "business/fetchAvailability",
  async ({ businessId, availabilityType }, { rejectWithValue }) => {
    try {
      const availability = await businessService.fetchAvailability(
        businessId,
        availabilityType,
      );
      return availability;
    } catch (error: any) {
      const errorMessage = error.message || "Failed to fetch availability";
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateAvailability = createAsyncThunk<
  AvailabilityDetail[],
  { businessId: string; updatedAvailabilitySlots: AvailabilityDetail[] },
  { rejectValue: string }
>(
  "business/updateAvailability",
  async ({ businessId, updatedAvailabilitySlots }, { rejectWithValue }) => {
    try {
      const updatedAvailability = await businessService.updateAvailability(
        businessId,
        updatedAvailabilitySlots,
      );
      return updatedAvailability;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to update availability";
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchBusinessIdByPhone = createAsyncThunk<
  string | null,
  string,
  { rejectValue: string }
>("business/fetchBusinessIdByPhone", async (phone, { rejectWithValue }) => {
  try {
    const businessId = await businessService.getBusinessIdByPhone(phone);
    return businessId;
  } catch (error: any) {
    return rejectWithValue(
      error.message || "Failed to fetch business ID by phone",
    );
  }
});

export const fetchBookedSlots = createAsyncThunk<
  KLSlot[],
  string,
  { rejectValue: string }
>("business/fetchTodayBookedSlots", async (businessId, { rejectWithValue }) => {
  try {
    const bookedSlots = await businessService.fetchBookedSlots(businessId);
    return bookedSlots;
  } catch (error: any) {
    const errorMessage = error.message || "Failed to fetch booked slots";
    return rejectWithValue(errorMessage);
  }
});
