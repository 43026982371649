import styled from "styled-components";
import colors from "../../constants/colors";

export const CollapsibleWrapper = styled("div")({
  marginBottom: "0.5rem",
  width: "100%",
});

export const CollapsibleHeader = styled("div")<{
  $isCollapsed?: boolean;
  $secondary?: boolean;
}>(({ $isCollapsed, $secondary }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: $secondary ? colors.PURPLE_LIGHTER : colors.PURPLE_LIGHTEST,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: $isCollapsed ? "8px" : "8px 8px 0 0",
  padding: "0.5rem 0.5rem 0.5rem 0",
  cursor: "pointer",
}));

export const TitleWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
});

export const CollapsibleTitle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  color: colors.BLACK,
  flexGrow: 1,
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.25rem",
});

export const CollapsibleCount = styled("div")({
  marginLeft: "8px",
  color: colors.GREY_7,
  fontSize: "0.9em",
});

export const CollapsibleSubtitle = styled("span")({
  fontSize: "12px",
  color: colors.GREY_7,
});

export const CollapsibleContent = styled("div")<{
  $maxHeight: number | "none";
  $isExpanded: boolean;
}>(({ $maxHeight, $isExpanded }) => ({
  maxHeight: $maxHeight === "none" ? "none" : `${$maxHeight}px`,
  opacity: $isExpanded ? 1 : 0,
  transition: "max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  padding: $isExpanded ? "1rem 0.5rem 1rem" : "0 1rem",
  backgroundColor: colors.WHITE,
  border: `1px solid ${colors.GREY_6}`,
  borderRadius: $isExpanded ? "0 0 8px 8px" : "0",
}));

export const IconWrapper = styled("span")({
  marginLeft: "0.5rem",
  display: "flex",
  alignItems: "center",
});

export const DeleteButton = styled("button")({
  background: colors.RED,
  border: "none",
  color: colors.WHITE,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  padding: "0.25rem 0.5rem",
  fontSize: "14px",
  fontWeight: "700",
  marginLeft: "0.25rem",
  height: "100%",
});
